import React, { FC } from 'react';

interface Props {
  float: 'right' | 'left' | 'none' | 'clear';
  children: React.ReactNode;
}

export const BoxListActionButtonsSection: FC<Props> = ({ children, float = 'right' }) => {
  return <div className={'bg-cover float-' + float}>{children}</div>;
};
