import { FC } from 'react';
import { ProductionEvent, Wine } from '../../../../types/Wine';
import { useCRUDListBoxContainer } from '../shared/useCRUDListBoxContainer';
import { useWineContext } from '../../../../context/WineContext';

export interface WineEventsListBoxProps {
  events: ProductionEvent[];
  removeEvent: (eventId: number) => void;
  disabledActions?: boolean;
  entityId?: number;
  loading: boolean;
}

type Props = WineEventsListBoxProps & { render: (list, elementToShow, actions) => JSX.Element };

export const WineEventsListBoxContainer: FC<Props> = ({ render, events, removeEvent }) => {
  const { setWineResult, setError } = useWineContext();
  const { list, elementToShow, actions } = useCRUDListBoxContainer<Wine, ProductionEvent>(
    events,
    removeEvent,
    setWineResult,
    setError
  );

  const selectedActions = {
    editElement: actions.editElement,
    removeElement: actions.removeElement,
    toggleShow: actions.toggleShow
  };

  return render(list, elementToShow, selectedActions);
};
