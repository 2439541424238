import React from 'react';
import { FormGroup } from 'reactstrap';
import Select from 'react-select';
import { Tank } from '../../../../tank/types/Tank';
import { SelectOption } from '../../../../../../../types/SelectOption';
import { ServiceError } from '../../../../../../../types/Service';
import useStorageTanksReceiver from '../../../hooks/useStorageTanksReceiver';

interface Props {
  value: Tank | SelectOption;
  onChange: (value: Tank) => void;
  name: string;
  label?: string;
  optional?: boolean;
  disabled?: boolean;
  error?: ServiceError;
  clazzName?: string;
  tanks: Tank[];
}

const SelectTankInStorage: React.FC<Props> = ({
  value: selected,
  onChange,
  name,
  label,
  optional,
  disabled,
  error,
  clazzName,
  tanks
}) => {
  const { selected: value, options } = useStorageTanksReceiver(tanks, selected);

  const customStyles = {
    control: (base) => ({
      ...base,
      borderColor: '#d92550',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center right calc(2.25rem / 4)',
      backgroundSize: 'calc(2.25rem / 2) calc(2.25rem / 2)'
    })
  };

  if (label) {
    return (
      <fieldset className={clazzName}>
        <FormGroup row>
          <label className="col-md-2 col-form-label" htmlFor={name}>
            {label}
            <span className="text-danger" style={{ display: optional === true ? 'none' : '' }}>
              {' '}
              *
            </span>
          </label>
          <div className="col-md-10">
            <Select
              name={name}
              options={options}
              onChange={onChange}
              value={value}
              isDisabled={disabled}
              placeholder={'Wybierz'}
              styles={error?.hasError?.(name) && customStyles}
            />
            <span
              className="invalid-feedback"
              style={{ display: error?.hasError?.(name) ? 'block' : 'none' }}
            >
              {error?.getErrorMessage?.(name)}
            </span>
          </div>
        </FormGroup>
      </fieldset>
    );
  }
  return (
    <>
      <Select
        name={name}
        options={options}
        onChange={onChange}
        value={value}
        isDisabled={disabled}
        placeholder={'Wybierz'}
        styles={error?.hasError?.(name) && customStyles}
      />
      <span
        className="invalid-feedback"
        style={{ display: error?.hasError?.(name) ? 'block' : 'none' }}
      >
        {error?.getErrorMessage?.(name)}
      </span>
    </>
  );
};

export default SelectTankInStorage;
