import React from 'react';
import { Pagination } from '../types/Pagination';

export const PaginationComponent: React.FC<{ pagination: Pagination<unknown> }> = ({
  pagination
}) => {
  if (pagination.getCurrentResults().length === 0) return null;
  return (
    <div className="ml-auto pr-3">
      <ul className="pagination pagination-sm">
        <li className="page-item">
          <button
            className="page-link"
            onClick={pagination.prev}
            style={{ display: pagination.currentPage === 1 ? 'none' : 'block' }}
          >
            «
          </button>
        </li>
        {pagination.maxPage === 2 ? (
          <>
            <li className={'page-item ' + (pagination.currentPage === 1 ? 'active' : '')}>
              <button className="page-link" onClick={() => pagination.jump(1)}>
                1
              </button>
            </li>
            <li className={'page-item ' + (pagination.currentPage === 2 ? 'active' : '')}>
              <button className="page-link" onClick={() => pagination.jump(2)}>
                2
              </button>
            </li>
          </>
        ) : null}
        {pagination.maxPage === 3 ? (
          <>
            <li className={'page-item ' + (pagination.currentPage === 1 ? 'active' : '')}>
              <button className="page-link" onClick={() => pagination.jump(1)}>
                1
              </button>
            </li>
            <li className={'page-item ' + (pagination.currentPage === 2 ? 'active' : '')}>
              <button className="page-link" onClick={() => pagination.jump(2)}>
                2
              </button>
            </li>
            <li className={'page-item ' + (pagination.currentPage === 3 ? 'active' : '')}>
              <button className="page-link" onClick={() => pagination.jump(3)}>
                3
              </button>
            </li>
          </>
        ) : null}
        {pagination.maxPage > 3 ? (
          <>
            {pagination.currentPage > 1 ? (
              <li className={'page-item '}>
                <button className="page-link" onClick={() => pagination.prev()}>
                  {pagination.currentPage - 1}
                </button>
              </li>
            ) : null}
            {pagination.currentPage === pagination.maxPage ? (
              <li className={'page-item '}>
                <button
                  className="page-link"
                  onClick={() => pagination.jump(pagination.currentPage - 2)}
                >
                  {pagination.currentPage - 2}
                </button>
              </li>
            ) : null}
            <li className={'page-item active'}>
              <button className="page-link" onClick={() => pagination.jump(pagination.currentPage)}>
                {pagination.currentPage}
              </button>
            </li>
            {pagination.currentPage !== pagination.maxPage ? (
              <li className={'page-item'}>
                <button className="page-link" onClick={() => pagination.next()}>
                  {pagination.currentPage + 1}
                </button>
              </li>
            ) : null}
            {pagination.currentPage === 1 ? (
              <li className={'page-item'}>
                <button
                  className="page-link"
                  onClick={() => pagination.jump(pagination.currentPage + 2)}
                >
                  {pagination.currentPage + 2}
                </button>
              </li>
            ) : null}
          </>
        ) : null}
        <li className="page-item">
          <button
            className="page-link"
            onClick={pagination.next}
            style={{ display: pagination.currentPage === pagination.maxPage ? 'none' : 'block' }}
          >
            »
          </button>
        </li>
      </ul>
    </div>
  );
};
