import React from 'react';
import { Card, CardBody, Table } from 'reactstrap';
import CommonRow from '../../../../components/common/table/CommonRow';
import { useTranslation } from 'react-i18next';
import { PaymentInfo } from '../../types';

interface IProps {
  paymentInfo: PaymentInfo;
  title: string;
}

export const CompanyPaymentInfoCard: React.FC<IProps> = ({ paymentInfo, title }) => {
  const { t } = useTranslation();

  return (
    <Card>
      <CardBody>
        <span className="text-muted float-left">{title}</span>
        <Table>
          <tbody>
            <CommonRow
              label={t('company.paymentInfo.priceToPay')}
              value={`${paymentInfo.priceToPay} zł`}
              valueColumnClassName={'text-right'}
            />
            <CommonRow
              label={t('company.paymentInfo.paymentTitle')}
              value={paymentInfo.paymentTitle}
              valueColumnClassName={'text-right'}
            />
          </tbody>
        </Table>
      </CardBody>
    </Card>
  );
};
