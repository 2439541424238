import React from 'react';
import { Card, CardBody, Table } from 'reactstrap';
import { Trans, useTranslation } from 'react-i18next';
import Pagination from '../../../../../../components/common/pagination/Pagination';
import ListActions from '../../../../../../components/common/ListActions';
import PageWrapper from '../../../../../../layouts/PageWrapper';
import { useParams } from 'react-router-dom';
import { EntityLiveStatus } from '../../../../../../components/common/enums/EntityLiveStatus';
import { WineStorage } from '../../types/WineStorage';
import { RouterParams } from '../../../../../../types/RouterParams';
import { usePushHistory } from '../../../../../../hooks/usePushHistory';
import { SectionWrapper } from '../../../../../../layouts/SectionWrapper';

const thead = [
  <th style={{ textAlign: 'center' }} key={1}>
    #
  </th>,
  <th style={{ textAlign: 'center' }} key={2}>
    <Trans i18nKey="wine.NAME" />
  </th>,
  <th style={{ textAlign: 'center' }} key={3}>
    <Trans i18nKey="wine.COLOR" />
  </th>,
  <th style={{ textAlign: 'center' }} key={4}>
    <Trans i18nKey="wine.TASTE" />
  </th>,
  <th style={{ textAlign: 'center' }} key={5}>
    <Trans i18nKey="wineStorage.TOTAL_LITERS" />
  </th>,
  <th style={{ textAlign: 'center' }} key={6}>
    <Trans i18nKey="wineStorage.TOTAL_BOTTLES" />
  </th>,
  <th style={{ textAlign: 'center' }} key={7}>
    <Trans i18nKey="wineStorage.TOTAL_TANKS" />
  </th>,
  <th style={{ textAlign: 'center' }} key={8}>
    <Trans i18nKey="common.ACTIONS" />
  </th>
];

const SimpleWineStorageList = ({
  wineStorages,
  page,
  pagination,
  limit,
  loading,
  paginationActions: { changePage, onPrev, onNext },
  entityActions: { remove, proceed, info, archive, revertArchive },
  wrapperDisabled,
  children,
  title
}) => {
  const { status } = useParams<RouterParams>();
  const { t } = useTranslation();
  const { pushHistory } = usePushHistory();

  function pushToBoard() {
    pushHistory(`/mv/board/storage`);
  }

  const createTHead = () => {
    return (
      <thead>
        <tr>{thead.filter((th, index) => index < limit || index === thead.length - 1)}</tr>
      </thead>
    );
  };

  const buildRow = (wineStorage: WineStorage) => {
    const fields = [
      <td style={{ textAlign: 'center' }} key={1}>
        {wineStorage?.id}
      </td>,
      <td style={{ textAlign: 'center' }} key={2}>
        {wineStorage?.name}
      </td>,
      <td style={{ textAlign: 'center' }} key={3}>
        {wineStorage?.wineColor ? t(`wine.COLOR.${wineStorage?.wineColor}`) : '-'}
      </td>,
      <td style={{ textAlign: 'center' }} key={4}>
        {wineStorage?.wineTaste ? t(`wine.TASTE.${wineStorage?.wineTaste}`) : '-'}
      </td>,
      <td style={{ textAlign: 'center' }} key={5}>
        {wineStorage?.totalLiters}
      </td>,
      <td style={{ textAlign: 'center' }} key={6}>
        {wineStorage?.totalBottles}
      </td>,
      <td style={{ textAlign: 'center' }} key={7}>
        {wineStorage?.totalTanks}
      </td>,
      <td style={{ textAlign: 'center' }} key={8}>
        <ListActions
          entity={wineStorage}
          actions={{
            remove: remove,
            proceed: proceed,
            info: info,
            archive: archive,
            revertArchive: revertArchive
          }}
        />
      </td>
    ];
    return (
      <tr key={wineStorage.id}>
        {fields.filter((th, index) => index < limit || index === thead.length - 1)}
      </tr>
    );
  };

  return (
    <PageWrapper
      title={'wineStorage.TITLE'}
      disabled={wrapperDisabled}
      onClick={pushToBoard}
      returnText={'home.STORAGE'}
    >
      <SectionWrapper loading={loading} hideSpinner>
        <Card>
          <CardBody>
            <h5 className="float-right">
              {title ||
                (status === EntityLiveStatus.ARCHIVED.toLowerCase()
                  ? t('wineProduction.list.archived.TITLE')
                  : t('wineProduction.list.created.TITLE'))}
            </h5>
            <Table hover>
              {createTHead()}
              <tbody>
                {(wineStorages || []).map((wineProduction) => buildRow(wineProduction))}
                {wineStorages && wineStorages.length === 0 && (
                  <tr>
                    <td style={{ textAlign: 'center' }} colSpan={8}>
                      {t('common.NO_DATA')}
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
            {children}
            {pagination.totalPages > 1 && (
              <Pagination
                page={page}
                pagination={pagination}
                actions={{
                  changePage: changePage,
                  prev: onPrev,
                  next: onNext
                }}
              />
            )}
          </CardBody>
        </Card>
      </SectionWrapper>
    </PageWrapper>
  );
};

export default SimpleWineStorageList;
