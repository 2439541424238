import React, { useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import { RouterParams } from '../../../../../../../types/RouterParams';
import { useTranslation } from 'react-i18next';
import { useHarvestContext } from '../../../context/HarvestContext';
import { AuthContext } from '../../../../../../platform/AuthContext';
import usePlanPicker from '../../../../../../subscription/hooks/usePlanPicker';
import { SubscriptionType } from '../../../../../../../types/Subscription';

export const WinesInHarvestListButtonsBar = () => {
  const { harvestId } = useParams<RouterParams>();
  const { harvest } = useHarvestContext();
  const { t } = useTranslation();
  const {
    subscriptionInfo: { accessInfo }
  } = useContext(AuthContext);

  const { isValidSubscription: isValidSubscriptionForNumberOfWines } = usePlanPicker({
    subscriptionType: SubscriptionType.NORMAL,
    fromBlockingContent: true,
    blockingMessage: 'Przejdź na wyższy plan, aby móc tworzyć bez limitu',
    itemIndexToHighlight: 3
  });

  function createWine(e: React.MouseEvent) {
    if (!accessInfo.winesAllowed && !isValidSubscriptionForNumberOfWines()) {
      e.preventDefault();
    }
  }

  return (
    <div className="mb-3">
      <div>
        {harvest.allDisposedToWine ? (
          <button
            disabled={true}
            className="btn btn-sm btn-info"
            type="button"
            title={'Cofnij zamknięcie, aby dodać moszcz'}
          >
            <em className="mr-1 fas fa-plus" /> moszcz
          </button>
        ) : (
          <Link
            to={{
              pathname: `/mv/wine/info/0/${harvestId}`,
              state: { from: `/mv/harvest/info/${harvestId}` }
            }}
            className="btn btn-sm btn-info"
            type="button"
            onClick={createWine}
          >
            <em className="mr-1 fas fa-plus" /> moszcz
          </Link>
        )}
        <Link
          to={'/mv/wine/all'}
          className="btn btn-sm btn-secondary"
          type="button"
          title={t('button.show.list')}
        >
          <em className="fas fa-outdent" />
        </Link>
      </div>
    </div>
  );
};
