import React from 'react';
import { SubscriptionStatus, SubscriptionType } from '../../../../types/Subscription';
import { useTranslation } from 'react-i18next';
import { PaymentInfo } from './PaymentInfo';
import { useAuthContext } from '../../../platform/AuthContext';

export const InProgresPlanInfo: React.FC<{
  rejectPlan: () => void;
}> = ({ rejectPlan }) => {
  const { t } = useTranslation();
  const {
    subscriptionInfo: { paymentInfo, activeSubscription, inProgressSubscription }
  } = useAuthContext();

  return (
    <div className="px-2">
      <div className={'h4 pb-1'}>
        Wybrałeś nowy plan{' '}
        <span className="text-gradient-blue-purple">
          {t(`plans.subscription.type.${inProgressSubscription.type}`)} !
        </span>
        {activeSubscription.type !== SubscriptionType.BASIC &&
        activeSubscription.status === SubscriptionStatus.ACTIVATED ? (
          <div className="text-gradient-blue-orange text-sm">
            Opłać plan przed końcem ważności aktualnego planu, aby zachować ciągłość.
          </div>
        ) : null}
      </div>
      <div>
        <p>
          Wysłaliśmy do Ciebie wiadomość e-mail z informacjami na temat nowego planu oraz
          szczegółami dotyczącymi płatności.{' '}
        </p>
        <p>
          Możesz także skorzystać z opcji przelewu bankowego, korzystając z danych podanych poniżej.
        </p>
      </div>

      <div className={'pb-3'}>
        <PaymentInfo
          price={paymentInfo?.priceToPay?.toString() || '0'}
          title={paymentInfo.paymentTitle}
        />
      </div>
      <div className="">
        Jeżeli nie chcesz kontynuować tego planu, na tym etapie możesz jeszcze z niego zrezygnować
        klikając{' '}
        <span className="btn-link text-danger" onClick={rejectPlan} style={{ cursor: 'pointer' }}>
          zrezygnuj z planu
        </span>
      </div>
    </div>
  );
};
