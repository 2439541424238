import React from 'react';
import ProgressBar from '../../../../components/common/charts/ProgressBar';
import { Card, CardBody, Table } from 'reactstrap';
import CommonRow from '../../../../components/common/table/CommonRow';
import { useTranslation } from 'react-i18next';
import { WinerySummaryData } from '../../../../types/dto';
import { SectionWrapper } from '../../../../layouts/SectionWrapper';

export const WinerySummaryCard: React.FC<{ summary: WinerySummaryData; loading: boolean }> = ({
  summary,
  loading
}) => {
  const { t } = useTranslation();
  return (
    <SectionWrapper loading={loading} hideSpinner>
      <Card>
        <CardBody>
          <ProgressBar
            percent={summary?.totalPercentOfUsage}
            color={'info'}
            title={t('winery.board.summary.table.tanks.percent')}
          />
          <small className="text-muted float-right">{t('winery.board.summary.table.title')}</small>
          <Table>
            <tbody>
              <CommonRow
                label={t('winery.board.summary.table.tanks.total')}
                value={summary ? `${summary.totalTanksCapacity} l` : ''}
                valueColumnClassName={'text-right'}
              />
              <CommonRow
                label={t('winery.board.summary.table.tanks.current')}
                value={summary ? `${summary.totalLitersInTanks} l` : ''}
                valueColumnClassName={'text-right'}
              />
              {summary?.totalWinesLitersInTanks ? (
                <CommonRow
                  label={t('winery.board.summary.table.tanks.wines')}
                  value={summary ? `${summary.totalWinesLitersInTanks} l` : ''}
                  valueColumnClassName={'text-right'}
                />
              ) : null}
              {summary?.totalWhiteLitersInTanks ? (
                <CommonRow
                  label={t('winery.board.summary.table.tanks.production.white')}
                  value={summary ? `${summary.totalWhiteLitersInTanks} l` : ''}
                  valueColumnClassName={'text-right'}
                />
              ) : null}
              {summary?.totalRedLitersInTanks ? (
                <CommonRow
                  label={t('winery.board.summary.table.tanks.production.red')}
                  value={summary ? `${summary.totalRedLitersInTanks} l` : ''}
                  valueColumnClassName={'text-right'}
                />
              ) : null}
              {summary?.totalRoseLitersInTanks ? (
                <CommonRow
                  label={t('winery.board.summary.table.tanks.production.rose')}
                  value={summary ? `${summary.totalRoseLitersInTanks} l` : ''}
                  valueColumnClassName={'text-right'}
                />
              ) : null}
              {summary?.totalOrangeLitersInTanks ? (
                <CommonRow
                  label={t('winery.board.summary.table.tanks.production.orange')}
                  value={summary ? `${summary.totalOrangeLitersInTanks} l` : ''}
                  valueColumnClassName={'text-right'}
                />
              ) : null}
              {summary?.totalOtherLitersInTanks ? (
                <CommonRow
                  label={t('winery.board.summary.table.tanks.production.other')}
                  value={summary ? `${summary.totalOtherLitersInTanks} l` : ''}
                  valueColumnClassName={'text-right'}
                />
              ) : null}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </SectionWrapper>
  );
};
