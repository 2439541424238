import React, { useContext } from 'react';
import { Card, CardBody, Table } from 'reactstrap';
import CommonRow from '../../../../components/common/table/CommonRow';
import { useTranslation } from 'react-i18next';
import { StorageSummaryData } from '../../../../types/dto';
import { AuthContext } from '../../../platform/AuthContext';
import { LockArea } from '../../../../components/common/lock/LockArea';
import { SubscriptionType } from '../../../../types/Subscription';
import { SectionWrapper } from '../../../../layouts/SectionWrapper';

export const StorageSummaryCard: React.FC<{ summary: StorageSummaryData; loading: boolean }> = ({
  summary,
  loading
}) => {
  const { t } = useTranslation();
  const {
    subscriptionInfo: { accessInfo }
  } = useContext(AuthContext);
  return (
    <>
      <SectionWrapper loading={loading} hideSpinner>
        <Card>
          <CardBody>
            <small className="text-muted float-right">
              {t('storage.board.wines.table.liters.title')}
            </small>
            <Table>
              <tbody>
                <CommonRow
                  label={t('storage.board.summary.table.totalLiters')}
                  value={summary ? `${summary.totalLiters} l` : ''}
                  valueColumnClassName={'text-right'}
                />
                {summary?.totalLitersInBottles && accessInfo.hasPremiumAccess ? (
                  <CommonRow
                    label={t('storage.board.summary.table.totalLitersInBottles')}
                    value={`${summary.totalLitersInBottles} l`}
                    valueColumnClassName={'text-right'}
                  />
                ) : null}
                {summary?.totalLitersInTanks && accessInfo.hasPremiumAccess ? (
                  <CommonRow
                    label={t('storage.board.summary.table.totalLitersInTanks')}
                    value={`${summary.totalLitersInTanks} l`}
                    valueColumnClassName={'text-right'}
                  />
                ) : null}
                {summary?.totalWhiteLiters && accessInfo.hasPremiumAccess ? (
                  <CommonRow
                    label={t('storage.board.summary.table.totalWhiteLiters')}
                    value={`${summary.totalWhiteLiters} l`}
                    valueColumnClassName={'text-right'}
                  />
                ) : null}
                {summary?.totalRedLiters && accessInfo.hasPremiumAccess ? (
                  <CommonRow
                    label={t('storage.board.summary.table.totalRedLiters')}
                    value={`${summary.totalRedLiters} l`}
                    valueColumnClassName={'text-right'}
                  />
                ) : null}
                {summary?.totalRoseLiters && accessInfo.hasPremiumAccess ? (
                  <CommonRow
                    label={t('storage.board.summary.table.totalRoseLiters')}
                    value={`${summary.totalRoseLiters} l`}
                    valueColumnClassName={'text-right'}
                  />
                ) : null}
                {summary?.totalOrangeLiters && accessInfo.hasPremiumAccess ? (
                  <CommonRow
                    label={t('storage.board.summary.table.totalOrangeLiters')}
                    value={`${summary.totalOrangeLiters} l`}
                    valueColumnClassName={'text-right'}
                  />
                ) : null}
                {summary?.totalOtherLiters && accessInfo.hasPremiumAccess ? (
                  <CommonRow
                    label={t('storage.board.summary.table.totalOtherLiters')}
                    value={`${summary.totalOtherLiters} l.`}
                    valueColumnClassName={'text-right'}
                  />
                ) : null}
                {summary?.taxBandsTotalSummaryQuantity && accessInfo.hasPremiumAccess ? (
                  <>
                    <CommonRow
                      label={t('storage.board.summary.table.taxBandsTotalSummaryQuantity')}
                      value={`${summary.taxBandsTotalSummaryQuantity} szt.`}
                      valueColumnClassName={'text-right'}
                    />
                    <CommonRow
                      label={t('storage.board.summary.table.taxBandsActualSummaryQuantity')}
                      value={`${summary.taxBandsActualSummaryQuantity} szt.`}
                      valueColumnClassName={'text-right'}
                    />
                  </>
                ) : null}
              </tbody>
            </Table>
            <LockArea
              subscriptionTypeNeeded={SubscriptionType.PREMIUM}
              blockingMessage={
                'Przejdź na wyższy plan, aby wyświetlić dane o banderolach do rozliczenia'
              }
              label={'Odblokuj więcej statystyk'}
              itemIndexToHighlight={2}
            />
          </CardBody>
        </Card>
      </SectionWrapper>
      <SectionWrapper loading={loading} hideSpinner>
        <Card>
          <CardBody>
            <small className="text-muted float-right">
              {t('storage.board.wines.table.bottles.title')}
            </small>
            <Table>
              <tbody>
                <CommonRow
                  label={t('storage.board.summary.table.totalBottles')}
                  value={summary ? `${summary.totalBottles} szt.` : ''}
                  valueColumnClassName={'text-right'}
                />
                {summary?.totalWhiteLitersInBottles && accessInfo.hasPremiumAccess ? (
                  <CommonRow
                    label={t('storage.board.summary.table.totalWhiteLitersInBottles')}
                    value={`${summary.totalWhiteBottles} szt. (${summary.totalWhiteLitersInBottles} l)`}
                    valueColumnClassName={'text-right'}
                  />
                ) : null}
                {summary?.totalRedLitersInBottles && accessInfo.hasPremiumAccess ? (
                  <CommonRow
                    label={t('storage.board.summary.table.totalRedLitersInBottles')}
                    value={`${summary.totalRedBottles} szt. (${summary.totalRedLitersInBottles} l)`}
                    valueColumnClassName={'text-right'}
                  />
                ) : null}
                {summary?.totalRoseLitersInBottles && accessInfo.hasPremiumAccess ? (
                  <CommonRow
                    label={t('storage.board.summary.table.totalRoseLitersInBottles')}
                    value={`${summary.totalRoseBottles} szt. (${summary.totalRoseLitersInBottles} l)`}
                    valueColumnClassName={'text-right'}
                  />
                ) : null}
                {summary?.totalOrangeLitersInBottles && accessInfo.hasPremiumAccess ? (
                  <CommonRow
                    label={t('storage.board.summary.table.totalOrangeLitersInBottles')}
                    value={`${summary.totalOrangeBottles} szt. (${summary.totalOrangeLitersInBottles} l)`}
                    valueColumnClassName={'text-right'}
                  />
                ) : null}
                {summary?.totalOtherLitersInBottles && accessInfo.hasPremiumAccess ? (
                  <CommonRow
                    label={t('storage.board.summary.table.totalOtherLitersInBottles')}
                    value={`${summary.totalOtherBottles} szt. (${summary.totalOtherLitersInBottles} l)`}
                    valueColumnClassName={'text-right'}
                  />
                ) : null}
              </tbody>
            </Table>
            <LockArea
              subscriptionTypeNeeded={SubscriptionType.PREMIUM}
              blockingMessage={
                'Przejdź na wyższy plan, aby wyświetlić dane o banderolach do rozliczenia'
              }
              label={'Odblokuj więcej statystyk'}
              itemIndexToHighlight={2}
            />
          </CardBody>
        </Card>
      </SectionWrapper>
      <SectionWrapper loading={loading} hideSpinner>
        <Card>
          <CardBody>
            <small className="text-muted float-right">
              {t('storage.board.wines.table.tanks.title')}
            </small>
            <Table>
              <tbody>
                <CommonRow
                  label={t('storage.board.summary.table.totalTanks')}
                  value={summary ? `${summary.totalTanks} szt.` : ''}
                  valueColumnClassName={'text-right'}
                />
                {summary?.totalWhiteLitersInTanks && accessInfo.hasPremiumAccess ? (
                  <CommonRow
                    label={t('storage.board.summary.table.totalWhiteLitersInTanks')}
                    value={`${summary.totalWhiteLitersInTanks} l`}
                    valueColumnClassName={'text-right'}
                  />
                ) : null}
                {summary?.totalRedLitersInTanks && accessInfo.hasPremiumAccess ? (
                  <CommonRow
                    label={t('storage.board.summary.table.totalRedLitersInTanks')}
                    value={`${summary.totalRedLitersInTanks} l`}
                    valueColumnClassName={'text-right'}
                  />
                ) : null}
                {summary?.totalRoseLitersInTanks && accessInfo.hasPremiumAccess ? (
                  <CommonRow
                    label={t('storage.board.summary.table.totalRoseLitersInTanks')}
                    value={`${summary.totalRoseLitersInTanks} l`}
                    valueColumnClassName={'text-right'}
                  />
                ) : null}
                {summary?.totalOrangeLitersInTanks && accessInfo.hasPremiumAccess ? (
                  <CommonRow
                    label={t('storage.board.summary.table.totalOrangeLitersInTanks')}
                    value={`${summary.totalOrangeLitersInTanks} l`}
                    valueColumnClassName={'text-right'}
                  />
                ) : null}
                {summary?.totalOtherLitersInTanks && accessInfo.hasPremiumAccess ? (
                  <CommonRow
                    label={t('storage.board.summary.table.totalOtherLitersInTanks')}
                    value={`${summary.totalOtherLitersInTanks} l`}
                    valueColumnClassName={'text-right'}
                  />
                ) : null}
              </tbody>
            </Table>
            <LockArea
              subscriptionTypeNeeded={SubscriptionType.PREMIUM}
              blockingMessage={
                'Przejdź na wyższy plan, aby wyświetlić dane o banderolach do rozliczenia'
              }
              label={'Odblokuj więcej statystyk'}
              itemIndexToHighlight={2}
            />
          </CardBody>
        </Card>
      </SectionWrapper>
    </>
  );
};
