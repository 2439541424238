import React, { FC } from 'react';
import { Button, Card, CardBody } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import PageWrapper from '../../../../../../layouts/PageWrapper';
import InputElement from '../../../../../../components/common/InputElement';
import { ServiceError } from '../../../../../../types/Service';
import { Tank } from '../../types/Tank';
import { FormErrorMessage } from '../../../../../../components/notifications/FormErrorMessage';
import SelectTankType from '../../../../../home/components/TanksSummaryPage/SelectTankType';
import { SectionWrapper } from '../../../../../../layouts/SectionWrapper';
import { usePushHistory } from '../../../../../../hooks/usePushHistory';

interface Props {
  onSubmit: () => void;
  error: ServiceError;
  tank: Tank;
  updateTank: () => void;
  loading: boolean;
  handleUpdateSelect: () => void;
}

const SimpleTankForm: FC<Props> = ({
  onSubmit,
  tank,
  updateTank,
  error,
  loading,
  handleUpdateSelect
}) => {
  const { pushHistory } = usePushHistory();
  const { t } = useTranslation();

  function pushToBoard() {
    pushHistory(`/mv/board/tanks`);
  }

  return (
    <PageWrapper title={'tank.TITLE'} onClick={pushToBoard} returnText={'home.TANKS'}>
      <SectionWrapper loading={loading}>
        <Card>
          <CardBody>
            <div className={'pb-5'}>
              <h5 className="float-right">
                {tank?.id ? t('sidebar.nav.element.EDIT') : t('sidebar.nav.element.ADD_NEW_TANK')}
              </h5>
            </div>
            <InputElement
              label={t('tank.NUMBER')}
              type={'text'}
              name={'number'}
              maxSize={'100'}
              onChange={updateTank}
              defaultValue={tank?.number}
              error={error}
            />
            <InputElement
              label={t('tank.CAPACITY')}
              type={'number'}
              name={'capacity'}
              onChange={updateTank}
              defaultValue={tank?.capacity}
              error={error}
            />
            <SelectTankType
              value={tank?.type}
              name={'type'}
              label={t('tank.TYPE')}
              onChange={handleUpdateSelect}
              error={error}
            />
            <FormErrorMessage error={error} messageType={'details'} />
            <div className="text-center bg-secondary">
              <Button color="info" className="btn-square" onClick={onSubmit}>
                {tank?.id ? t('common.SAVE') : t('common.ADD')}
              </Button>
            </div>
          </CardBody>
        </Card>
      </SectionWrapper>
    </PageWrapper>
  );
};

export default SimpleTankForm;
