import useCrudeApi from '../../../lib/useCrudeApi';
import { WineInfo } from '../../winery/common/WineInfoBox';

const useWineService = () => {
  const { getList: getProduction } = useCrudeApi<WineInfo>(`/ajax/board/production`);
  const { getList: getSummary } = useCrudeApi<WineInfo>(`/ajax/board/summary`);
  const { getList: getStorage } = useCrudeApi<WineInfo>(`/ajax/board/storage`);
  const { getList: getStartBoard } = useCrudeApi<WineInfo>(`/ajax/board/start`);

  return { getProduction, getSummary, getStorage, getStartBoard };
};

export default useWineService;
