import React from 'react';
import { useTranslation } from 'react-i18next';
import usePagination from '../../../../hooks/usePagination';
import { PaymentData } from '../../types';
import { PaginationComponent } from '../../../../components/PaginationComponent';
import PageWrapper from '../../../../layouts/PageWrapper';
import { PaymentRow } from './PaymentRow';

interface IProps {
  payments: PaymentData[];
}

export const PaymentsTable: React.FC<IProps> = ({ payments }) => {
  const { t } = useTranslation();
  const pagination = usePagination(payments, 10);

  return (
    <PageWrapper disabled>
      <div className="card">
        <div className="card-body pb-1">
          <small className="text-muted float-right">{t('payments.table.title')}</small>
          <div className="dataTables_wrapper dt-bootstrap4 no-footer">
            <div className="row">
              <div className="col-sm-12 table-responsive">
                <table className="table w-100 no-footer table-hover table-sm">
                  <thead>
                    <tr role="row">
                      <th style={{ width: 50 }}>{t('companies.table.id')}</th>
                      <th style={{ width: 55 }}>{t('payments.table.subscriptionId')}</th>
                      <th style={{ width: 55 }}>{t('payments.table.payed')}</th>
                      <th>{t('payments.table.paymentTitle')}</th>
                      <th>{t('payments.table.price')}</th>
                      <th>{t('payments.table.discount')}</th>
                      <th>{t('payments.table.priceToPay')}</th>
                      <th>{t('payments.table.createdDate')}</th>
                      <th>{t('payments.table.paymentDate')}</th>
                      <th>{t('payments.table.lastModifiedDate')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {pagination.getCurrentResults().map((payment) => {
                      return <PaymentRow payment={payment} key={payment.id} />;
                    })}
                    {pagination.getCurrentResults().length === 0 ? (
                      <tr>
                        <td colSpan={100} className="text-center">
                          Brak danych
                        </td>
                      </tr>
                    ) : null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <PaginationComponent pagination={pagination} />
      </div>
    </PageWrapper>
  );
};
