import React, { FC } from 'react';
import { InputElementSection } from '../InputElement/InputElementSection';
import { InputDateProps, InputElementDateItem } from './InputElementDateItem';

export const InputDate: FC<
  InputDateProps & { label?: string; optional?: boolean; extraInfo?: string }
> = (props) => {
  return (
    <InputElementSection
      label={{ text: props?.label, optional: props?.optional }}
      className={props?.className}
    >
      <InputElementDateItem {...props} />
    </InputElementSection>
  );
};
