import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { StatusType } from '../../../../../../types/Service';
import { ResponseError } from '../../../../../error/ResponseError';
import { EntityLiveStatus } from '../../../../../../components/common/enums/EntityLiveStatus';
import { usePushHistory } from '../../../../../../hooks/usePushHistory';
import { useWineProductionContext } from '../../context/WineProductionContext';
import useWineProductionService from '../../hooks/useWineProductionService';
import { Button, WineInfoButtons } from '../../../wine/components/WineInfo/WineInfoContainer';
import { useTranslation } from 'react-i18next';
import { Tank } from '../../../tank/types/Tank';
import { RouterParams } from '../../../../../../types/RouterParams';
import usePlanPicker from '../../../../../subscription/hooks/usePlanPicker';
import { SubscriptionType } from '../../../../../../types/Subscription';
import { useConfirmation } from '../../../../../notifications/useConfirmation';

const WineProductionInfoContainer = ({ render }) => {
  const { updateWineProduction, setWineProductionResult, wineProduction, error, setError } =
    useWineProductionContext();
  const [showTankSelect, setShowTankSelect] = useState(false);
  const serviceRef = useRef(useWineProductionService());
  const { wineProductionId } = useParams<RouterParams>();
  const { pushHistory } = usePushHistory();
  const { t } = useTranslation();
  const showConfirmation = useConfirmation();

  const { isValidSubscription } = usePlanPicker({
    subscriptionType: SubscriptionType.NORMAL,
    fromBlockingContent: true,
    itemIndexToHighlight: 1
  });

  useEffect(() => {
    setWineProductionResult({ status: StatusType.loading });
    wineProductionId &&
      serviceRef.current
        .get(parseInt(wineProductionId))
        .then((response) => {
          setWineProductionResult({ status: StatusType.loaded, payload: response });
        })
        .catch((error) => setWineProductionResult(new ResponseError(error)));

    return () => updateWineProduction('reset', '');
  }, [wineProductionId]); // eslint-disable-line react-hooks/exhaustive-deps

  const editWine = () => {
    pushHistory(`/mv/wine_production/e/${wineProductionId}/`);
  };

  const removeLastWineEntryAndWineProduction = () => {
    showConfirmation({
      message: t('alerts.confirmation.lastEntry.REMOVE_WINE_PRODUCTION'),
      actionLabel: 'Tak',
      actionButtonColor: 'danger',
      actionCallback: () =>
        handleRemoveLastWineEntryAndWineProduction(wineProduction?.wineEntries?.[0]?.id)
    });
  };

  function handleRemoveLastWineEntryAndWineProduction(elementId: number) {
    serviceRef.current
      .removeWineEntry(elementId)
      .then(() => pushHistory(`/mv/wine/info/${wineProduction?.wineEntries?.[0]?.wine?.id}`))
      .catch((e: Error) => setError(new ResponseError(e)));
  }

  const removeWineProduction = () => {
    showConfirmation({
      message: t('alerts.confirmation.REMOVE_WINE_PRODUCTION'),
      actionLabel: 'Tak',
      actionButtonColor: 'danger',
      actionCallback: () => handleRemoveWineProduction()
    });
  };

  function handleRemoveWineProduction() {
    serviceRef.current
      .del(wineProduction.id)
      .then(() => pushHistory(`/mv/board/production`))
      .catch((e: Error) => setError(new ResponseError(e)));
  }

  const archive = () => {
    if (!isValidSubscription()) return;
    const action =
      wineProduction?.liveStatus === EntityLiveStatus.ARCHIVED
        ? serviceRef.current.revertArchive
        : serviceRef.current.archive;
    action(wineProduction?.id)
      .then((response) => {
        setWineProductionResult({ status: StatusType.loaded, payload: response });
      })
      .catch((error) => {
        setError(error);
        setWineProductionResult({ status: StatusType.error });
      });
  };

  const updateTank = (selected: { value: number }) => {
    const tank: Partial<Tank> = { id: selected.value };
    setWineProductionResult({ status: StatusType.loading });
    serviceRef.current
      .placeInTank(tank)
      .then((response) => {
        setWineProductionResult({ status: StatusType.loaded, payload: response });
      })
      .catch((error) => {
        setError(error);
        setWineProductionResult({ status: StatusType.error });
      });
  };

  const saveEditButton = {
    action: editWine,
    label: wineProduction?.liveStatus === EntityLiveStatus.ARCHIVED ? 'Podgląd' : 'Edytuj'
  };

  const archiveButton: Button = {
    action: archive,
    label:
      wineProduction?.liveStatus === EntityLiveStatus.ARCHIVED
        ? t('button.REVERT_ARCHIVE')
        : t('button.ARCHIVE')
  };

  const removeLastWineEntryAndWineProductionButton: Button = {
    action: removeLastWineEntryAndWineProduction,
    label: t('common.REMOVE')
  };

  const removeButton: Button = {
    action: removeWineProduction,
    label: t('common.REMOVE')
  };

  const buttons: WineInfoButtons = {
    saveEditButton,
    archiveButton,
    removeLastWineEntryAndWineProductionButton,
    removeButton
  };

  const toggleTankSelect = () => setShowTankSelect((prev) => !prev);

  return render(buttons, updateTank, error, showTankSelect, toggleTankSelect);
};

export default WineProductionInfoContainer;
