import {
  Subscription,
  SubscriptionPeriod,
  SubscriptionType
} from '../../../../../types/Subscription';
import React from 'react';
import { PickerPaymentResultForBasic } from './PickerPaymentResultForBasic';
import { PickerPaymentResultForNormal } from './PickerPaymentResultForNormal';
import { PickerPlanStepTwoTrialPlansItems } from './PickerPlanStepTwoTrialPlansItems';
import { PickerPlanStepTwoPaidPlansItems } from './PickerPlanStepTwoPaidPlansItems';
import { SubscriptionPlan } from '../../../data/Plans';

export const PickerPlanStepTwo: React.FC<{
  trialAllowed: boolean;
  handleBack: () => void;
  header: string;
  info: string;
  activeSubscription: Subscription;
  period: SubscriptionPeriod;
  setPeriod: (period: SubscriptionPeriod) => void;
  continueWithUpgradedPlan: boolean;
  plan: SubscriptionPlan;
  normalPlan: SubscriptionPlan;
}> = ({
  trialAllowed,
  handleBack,
  header,
  info,
  activeSubscription,
  period,
  setPeriod,
  continueWithUpgradedPlan,
  plan,
  normalPlan
}) => {
  return (
    <>
      <div style={{ display: 'flex', alignItems: 'flex-start' }}>
        <button
          className={'btn btn-sm py-0'}
          style={{ marginTop: '-3px' }}
          onClick={() => handleBack()}
        >
          <em className={'icon-arrow-left pr-2'}></em>
        </button>
        <span className={'h5 text-bold text-gradient-orange-blue'}>{header}</span>
      </div>
      <div className={'h3 text-bold text-gradient-blue-purple'}>{info}</div>
      {trialAllowed ? <PickerPlanStepTwoTrialPlansItems /> : <PickerPlanStepTwoPaidPlansItems />}
      {/*{activeSubscription?.type === SubscriptionType.NORMAL &&*/}
      {/*activeSubscription?.status === SubscriptionStatus.ACTIVATED ? (*/}
      {/*  <div>*/}
      {/*    <Checkbox*/}
      {/*      name={'continueWithUpgradedPlan'}*/}
      {/*      label={'Aktywuj plan w bieżącym okresie'}*/}
      {/*      value={continueWithUpgradedPlan}*/}
      {/*      onChange={setContinueWithUpgradedPlanOnChange}*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*) : null}*/}
      <div className="text-bold pt-1">Następnie:</div>
      <div>
        <div className="pt-2">
          <label className="c-radio text-bold">
            <input
              id="monthly"
              type="radio"
              name="monthly"
              checked={period === SubscriptionPeriod.MONTHLY || false}
              onChange={() => setPeriod(SubscriptionPeriod.MONTHLY)}
            />
            <span className="fa fa-circle"></span> Miesięcznie
          </label>
          <div className="ml-4 pl-1">{plan.monthlyPrice} zł</div>
        </div>
        <div className="pt-2">
          <label className="c-radio text-bold">
            <input
              id="yearly"
              type="radio"
              name="yearly"
              checked={period === SubscriptionPeriod.ANNUAL || false}
              onChange={() => setPeriod(SubscriptionPeriod.ANNUAL)}
            />
            <span className="fa fa-circle"></span> Rocznie{' '}
            <div className={'badge bg-green ml-2'}>
              Najkorzystniej - Oszczędzasz {plan.monthlyPrice * 12 - plan.annualPrice} zł
            </div>
          </label>
          <div className="ml-4 pl-1">
            {plan.annualPrice} zł ( {plan.annualPricePerMonth} zł miesięcznie )
          </div>
        </div>
      </div>
      {!activeSubscription || activeSubscription?.type === SubscriptionType.BASIC ? (
        <PickerPaymentResultForBasic plan={plan} trialAllowed={trialAllowed} period={period} />
      ) : null}
      {activeSubscription?.type === SubscriptionType.NORMAL ? (
        <PickerPaymentResultForNormal
          plan={plan}
          normalPlan={normalPlan}
          period={period}
          continueWithUpgradedPlan={continueWithUpgradedPlan}
          activeSubscription={activeSubscription}
        />
      ) : null}
    </>
  );
};
