import React from 'react';
import { useTranslation } from 'react-i18next';
import usePagination from '../../../../../hooks/usePagination';
import { usePushHistory } from '../../../../../hooks/usePushHistory';
import { SummaryWineRow } from './SummaryWineRow';
import { PaginationComponent } from '../../../../../components/PaginationComponent';
import { WineBoardPresentationData } from '../../../../../types/dto';
import { useSearch } from '../../../../../hooks/useSearch';
import { SearchComponent } from '../../../../../components/common/search/SearchComponent';
import { SectionWrapper } from '../../../../../layouts/SectionWrapper';
import { useResponsiveContext } from '../../../../platform/ResponsiveContext';

export const WinesListTable: React.FC<{ wines: WineBoardPresentationData[]; loading: boolean }> = ({
  wines,
  loading
}) => {
  const { t } = useTranslation();
  const { isMobile, isSmallMobile } = useResponsiveContext();
  const [result, search] = useSearch<WineBoardPresentationData>({
    entities: wines,
    field: ['name', 'wineTaste', 'wineColor']
  });
  const pagination = usePagination(result, 10);

  const { pushHistory } = usePushHistory();

  function navigateToWine(id: number, entity: string) {
    pushHistory(`/mv/${entity}/info/${id}`);
  }

  return (
    <SectionWrapper loading={loading} hideSpinner>
      <div className="card">
        <div className="card-body pb-1">
          <small className="text-muted float-right">{t('winery.board.wines.table.title')}</small>
          <div className="float-left">
            <SearchComponent search={search} />
          </div>
          <div className="dataTables_wrapper dt-bootstrap4 no-footer">
            <div className="row">
              <div className="col-sm-12 table-responsive">
                <table className="table w-100 no-footer table-hover" style={{ width: 1068 }}>
                  <thead>
                    <tr role="row">
                      <th style={{ minWidth: '140px', width: 215 }}>
                        {t('winery.board.wines.table.name')}
                      </th>
                      <th className={'text-center'} style={{ width: 30 }}>
                        {t('winery.board.wines.table.entity')}
                      </th>
                      <th className={'text-center'} style={{ width: 85 }}>
                        {t('winery.board.wines.table.endLiters')}
                      </th>
                      {!isSmallMobile ? (
                        <th className={'text-center'} style={{ width: 85 }}>
                          {t('winery.board.wines.table.liters')}
                        </th>
                      ) : null}
                      {!isMobile ? (
                        <>
                          <th style={{ width: 215 }}>{t('winery.board.wines.table.percentage')}</th>
                          <th className={'text-center'} style={{ width: 135 }}>
                            {t('winery.board.wines.table.tank')}
                          </th>
                          <th style={{ width: 85 }}>{t('winery.board.wines.table.sugar')}</th>
                        </>
                      ) : null}
                    </tr>
                  </thead>
                  <tbody>
                    {pagination.getCurrentResults().map((wine) => {
                      return (
                        <SummaryWineRow
                          wine={wine}
                          onClick={() => navigateToWine(wine.id, wine.entity)}
                          key={wine.id}
                          short={isMobile}
                          shorter={isSmallMobile}
                        />
                      );
                    })}
                    {pagination.getCurrentResults().length === 0 ? (
                      <tr>
                        <td colSpan={100} className="text-center">
                          Brak danych
                        </td>
                      </tr>
                    ) : null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <PaginationComponent pagination={pagination} />
      </div>
    </SectionWrapper>
  );
};
