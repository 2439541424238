import { FC } from 'react';
import { WineEntry, WineProduction } from '../../../wine/types/WineProduction';
import { useCRUDListBoxContainer } from '../../../wine/components/WineInfo/list-box/shared/useCRUDListBoxContainer';
import { useWineProductionContext } from '../../context/WineProductionContext';

export interface WineEntriesListBoxProps {
  wineEntries: WineEntry[];
}

type Props = WineEntriesListBoxProps & { render: (list, elementToShow, actions) => JSX.Element };

export const DisposedWineEntriesListBoxContainer: FC<Props> = ({ render, wineEntries }) => {
  const { setWineProductionResult, setError } = useWineProductionContext();
  const { list, elementToShow, actions } = useCRUDListBoxContainer<WineProduction, WineEntry>(
    wineEntries,
    undefined,
    setWineProductionResult,
    setError
  );

  const selectedActions = {
    infoElement: actions.infoElement,
    toggleShow: actions.toggleShow
  };

  return render(list, elementToShow, selectedActions);
};
