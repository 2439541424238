import React, { FC } from 'react';
import {
  IngredientToWIneServiceProps,
  withIngredientServiceHOC
} from '../shared/withIngredientServiceHOC';
import { EditAppliedIngredientForm } from '../common/EditAppliedIngredientForm';
import { WineProduction } from '../../../../wine/types/WineProduction';

export const AppliedToWineProductionIngredientForm: FC<
  IngredientToWIneServiceProps<WineProduction>
> = (props) => {
  const EditAppliedIngredientFormWithService = withIngredientServiceHOC(EditAppliedIngredientForm);

  return <EditAppliedIngredientFormWithService {...props} />;
};
