import React, { FC } from 'react';
import { AddEventToWinePresentation } from '../common/AddEventToWinePresentation';
import {
  EventToWineServiceProps,
  withProductionEventToWineServiceHOC
} from '../common/withProductionEventToWineServiceHOC';
import { WineProduction } from '../../../wine/types/WineProduction';

export const ProductionEventToWineProductionForm: FC<EventToWineServiceProps<WineProduction>> = (
  props
) => {
  const ProductionEventFormWithEventToWineService = withProductionEventToWineServiceHOC(
    AddEventToWinePresentation
  );

  return <ProductionEventFormWithEventToWineService {...props} />;
};
