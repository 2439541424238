import { WineColor, WineEntryType } from '../../wine/types/WineProduction';

export interface Tank {
  id?: number | null;
  number?: string;
  aboutInside?: string;
  capacity?: number | null;
  liters?: number | null;
  percentOfUsage?: number | null;
  type: TankType;
  colorInside: WineColor;
  entryId: number;
  entryType: WineEntryType;
}

export enum TankType {
  'TANK' = 'TANK',
  'BARREL' = 'BARREL',
  'VAT' = 'VAT',
  'BALLOON' = 'BALLOON'
}
