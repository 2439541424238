import { useEffect, useState } from 'react';
import { SelectOption } from '../../../../../types/SelectOption';
import { useTranslation } from 'react-i18next';
import { WineEntryTypeForSelect } from '../../wine/types/WineProduction';
import { useAuthContext } from '../../../../platform/AuthContext';

export const useWineEntryEntityTypeReceiver = (
  value?: WineEntryTypeForSelect | SelectOption | null
) => {
  const { t } = useTranslation();
  const {
    subscriptionInfo: { accessInfo }
  } = useAuthContext();
  const options: SelectOption[] = Object.keys(WineEntryTypeForSelect).map((i) => ({
    value: i,
    label: t(`wineEntry.TYPE.${i}`),
    lock: !accessInfo.hasNormalAccess && i === WineEntryTypeForSelect.WINE_PRODUCTION
  }));
  const [selected, setSelected] = useState<SelectOption | string>('');

  useEffect(() => {
    if (value) {
      setSelected(value ? { value: value, label: t(`wineEntry.TYPE.${value}`) } : '');
      return;
    }
  }, [value, t]);

  return { options, selected, loading: false };
};
