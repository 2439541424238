import React, { useRef } from 'react';
import { PickPlan } from '../components/picker/PickPlan';
import { SubscriptionType } from '../../../types/Subscription';
import { usePopupContext } from '../../notifications/context/PopupContext';
import { useAuthContext } from '../../platform/AuthContext';

export default function usePlanPicker({
  subscriptionType,
  fromBlockingContent,
  blockingMessage,
  itemIndexToHighlight
}: {
  subscriptionType: SubscriptionType;
  fromBlockingContent?: boolean;
  blockingMessage?: string;
  itemIndexToHighlight?: number | number[];
}) {
  const {
    subscriptionInfo: { accessInfo }
  } = useAuthContext();
  const { showPopupComponent, closePopupComponent } = usePopupContext();
  const ref = useRef(
    <PickPlan
      planType={subscriptionType}
      closePicker={closePopupComponent}
      fromBlockingContent={fromBlockingContent}
      blockingMessage={blockingMessage}
      itemIndexToHighlight={itemIndexToHighlight}
    />
  );

  function isValidSubscription() {
    if (
      (subscriptionType === SubscriptionType.NORMAL && !accessInfo.hasNormalAccess) ||
      (subscriptionType === SubscriptionType.PREMIUM && !accessInfo.hasPremiumAccess)
    ) {
      showPopupComponent(ref.current);
      return false;
    }
    return true;
  }

  return { isValidSubscription };
}
