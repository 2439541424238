import { withAddWineStorageEntryService } from './withAddWineStorageEntryService';
import { AddStorageEntryFormPresentation } from './AddStorageEntryFormPresentation';
import React from 'react';

export const AddStorageEntryForm = (props) => {
  const AddStorageEntryFormWithService = withAddWineStorageEntryService(
    AddStorageEntryFormPresentation
  );
  return <AddStorageEntryFormWithService {...props} />;
};
