import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import HarvestList from './components/HarvestList';
import { AuthContext } from '../../../platform/AuthContext';
import HarvestForm from './components/HarvestForm';
import HarvestProvider from './context/HarvestContext';
import WineProvider from '../wine/context/WineContext';
import { HarvestInfoRedesigned } from './components/HarvestInfoRedesigned';
import NotFound from '../../../../pages/NotFound';

class HarvestApp extends Component {
  static contextType = AuthContext;

  render() {
    const { principal } = this.context;
    return (
      <HarvestProvider>
        <WineProvider>
          <Switch>
            <Route exact path="/mv/harvest">
              <Redirect to="/mv/harvest/all" />
            </Route>
            <Route exact path="/mv/harvest/all" component={HarvestList} />
            <Route exact path="/mv/harvest/:status?" component={HarvestList} />
            <Route exact path="/mv/harvest/e/:harvestId?">
              <HarvestForm principal={principal} />
            </Route>
            <Route path="/mv/harvest/info/:harvestId?">
              <HarvestInfoRedesigned principal={principal} />
            </Route>
            <Route path="*" component={NotFound} />
          </Switch>
        </WineProvider>
      </HarvestProvider>
    );
  }
}

export default HarvestApp;
