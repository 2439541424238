import React from 'react';
import { Card, CardBody, Table } from 'reactstrap';
import PageWrapper from '../../../../../../layouts/PageWrapper';
import { Trans, useTranslation } from 'react-i18next';
import ListActions from '../../../../../../components/common/ListActions';
import Pagination from '../../../../../../components/common/pagination/Pagination';
import { useParams } from 'react-router-dom';
import { EntityLiveStatus } from '../../../../../../components/common/enums/EntityLiveStatus';
import { usePushHistory } from '../../../../../../hooks/usePushHistory';
import { SectionWrapper } from '../../../../../../layouts/SectionWrapper';

const thead = [
  <th style={{ textAlign: 'center' }} key={2}>
    <Trans i18nKey="parcel.REGISTRATION_NUMBER" />
  </th>,
  <th style={{ textAlign: 'center' }} key={3}>
    <Trans i18nKey="parcel.AREA" />
  </th>,
  <th style={{ textAlign: 'center' }} key={4}>
    <Trans i18nKey="parcel.COUNTRY" />
  </th>,
  <th style={{ textAlign: 'center' }} key={5}>
    <Trans i18nKey="parcel.CITY" />
  </th>,
  <th style={{ textAlign: 'center' }} key={6}>
    <Trans i18nKey="parcel.DISTRICT" />
  </th>,
  <th style={{ textAlign: 'center' }} key={7}>
    <Trans i18nKey="parcel.ZIP_CODE" />
  </th>,
  <th style={{ textAlign: 'center' }} key={8}>
    <Trans i18nKey="common.ACTIONS" />
  </th>
];

const SimpleParcelList = ({
  parcels,
  page,
  pagination,
  limit,
  loading,
  paginationActions: { changePage, onPrev, onNext },
  entityActions: { remove, proceed, archive, revertArchive },
  children
}) => {
  const { status } = useParams();
  const { t } = useTranslation();
  const { pushHistory } = usePushHistory();

  function pushToBoard() {
    pushHistory(`/mv/board/summary`);
  }

  const createTHead = () => {
    return (
      <thead>
        <tr>{thead.filter((t, index) => index < limit || index === thead.length - 1)}</tr>
      </thead>
    );
  };

  const buildRow = (parcel) => {
    const registrationNumber = parcel?.registrationNumber;
    const area = parcel?.area;
    const country = parcel?.country;
    const city = parcel?.city;
    const district = parcel?.district;
    const zipCode = parcel?.zipCode;
    const fields = [
      <td style={{ textAlign: 'center' }} key={2}>
        {registrationNumber}
      </td>,
      <td style={{ textAlign: 'center' }} key={3}>
        {area}
      </td>,
      <td style={{ textAlign: 'center' }} key={4}>
        {country}
      </td>,
      <td style={{ textAlign: 'center' }} key={5}>
        {city}
      </td>,
      <td style={{ textAlign: 'center' }} key={6}>
        {district}
      </td>,
      <td style={{ textAlign: 'center' }} key={7}>
        {zipCode}
      </td>,
      <td style={{ textAlign: 'center' }} key={8}>
        <ListActions
          entity={parcel}
          actions={{
            remove: remove,
            proceed: proceed,
            archive: archive,
            revertArchive: revertArchive
          }}
          status={status}
        />
      </td>
    ];
    return (
      <tr key={parcel.id}>
        {fields.filter((t, index) => index < limit || index === thead.length - 1)}
      </tr>
    );
  };

  return (
    <PageWrapper title={'parcel.TITLE'} onClick={pushToBoard} returnText={'home.summary.TITLE'}>
      <SectionWrapper loading={loading}>
        <Card>
          <CardBody>
            <h5 className="float-right">
              {status === EntityLiveStatus.ARCHIVED.toLowerCase()
                ? t('parcel.list.archived.TITLE')
                : t('parcel.list.created.TITLE')}
            </h5>
            <Table hover>
              {createTHead()}
              <tbody>
                {(parcels || []).map((parcel) => buildRow(parcel))}
                {parcels && parcels.length === 0 && (
                  <tr>
                    <td style={{ textAlign: 'center' }} colSpan={'100%'}>
                      <Trans i18nKey="common.NO_DATA" />
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
            {children}
            {pagination.totalPages > 1 && (
              <Pagination
                page={page}
                pagination={pagination}
                actions={{
                  changePage: changePage,
                  prev: onPrev,
                  next: onNext
                }}
              />
            )}
          </CardBody>
        </Card>
      </SectionWrapper>
    </PageWrapper>
  );
};

export default SimpleParcelList;
