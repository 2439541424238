import React, { FC } from 'react';

export const DeleteButton: FC<{ onClick: () => void; disabled: boolean }> = ({
  onClick,
  disabled
}) => {
  return (
    <button
      type="button"
      className="btn btn-sm btn-outline-danger mr-1"
      onClick={onClick}
      disabled={disabled}
    >
      <em className="fa fa-trash fa-fw" />
    </button>
  );
};
