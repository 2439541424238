import React, { useCallback, useContext, useMemo, useReducer } from 'react';
import { Service, ServiceError, StatusType } from '../../../../../types/Service';
import { defaultError } from '../../parcel/context/ParcelContext';
import { ResponseError } from '../../../../error/ResponseError';
import { WineProduction } from '../../wine/types/WineProduction';

interface WineProductionContextInterface {
  wineProduction: WineProduction | Partial<WineProduction>;
  setWineProduction: (value: WineProduction) => void;
  updateWineProduction: (name, value) => void;
  wineProductions: WineProduction[];
  setWineProductions: (value: WineProduction[]) => void;
  wineProductionResult: Service<WineProduction>;
  setWineProductionResult: (value: Service<WineProduction>) => void;
  loading: boolean;
  setError: (value: ServiceError) => void;
  error: ServiceError;
}

const defaultWineProduction = {
  id: 0,
  name: '',
  wineColor: undefined,
  wineTaste: undefined,
  finished: false,
  status: undefined,
  tank: {},
  startDate: '',
  liters: 0,
  wineEntries: [],
  disposedAsWineEntry: [],
  ingredients: [],
  history: [],
  events: [],
  lastIngredient: undefined,
  lastEvent: undefined,
  liveStatus: undefined
};

const defaultState = {
  wineProduction: defaultWineProduction,
  setWineProduction: () => {},
  updateWineProduction: () => {},
  wineProductions: [],
  setWineProductions: () => {},
  wineProductionResult: { status: StatusType.loading },
  setWineProductionResult: () => {},
  loading: false,
  setError: () => {},
  error: defaultError
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'reset':
      return defaultState;
    case 'resetWineProduction':
      return { ...state, wineProduction: defaultWineProduction };
    case 'resetWineProductions':
      return { ...state, wineProductions: [] };
    case 'wineProduction':
      return { ...state, wineProduction: { ...action.value } };
    case 'wineProductions':
      return { ...state, wineProductions: [...action.value] };
    case 'wineProductionResult':
      return { ...state, wineProductionResult: { ...action.value } };
    case 'error':
      return { ...state, error: action.value };

    default:
      return { ...state, wineProduction: { ...state.wineProduction, [action.type]: action.value } };
  }
};

export const WineProductionContext =
  React.createContext<WineProductionContextInterface>(defaultState);
export const useWineProductionContext = (): WineProductionContextInterface => {
  return useContext(WineProductionContext);
};

const WineProductionProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, defaultState);

  const resetWineProduction = useCallback(() => {
    dispatch({ type: 'resetWineProduction', value: '' });
  }, []);

  const resetWineProductions = useCallback(() => {
    dispatch({ type: 'resetWineProductions', value: '' });
  }, []);

  const setWineProduction = useCallback((wineProduction) => {
    dispatch({ type: 'wineProduction', value: wineProduction });
  }, []);

  const setWineProductions = useCallback((wineProductions) => {
    dispatch({ type: 'wineProductions', value: wineProductions });
  }, []);

  const updateWineProduction = useCallback((name, value) => {
    dispatch({ type: name, value });
  }, []);

  const setError = useCallback((error) => {
    dispatch({ type: 'error', value: new ResponseError(error) });
  }, []);

  const setWineProductionResult = useCallback(
    (result) => {
      dispatch({ type: 'wineProductionResult', value: result });
      if (result?.payload) {
        dispatch({ type: 'wineProduction', value: result.payload });
        setError(defaultError);
      }
    },
    [setError]
  );

  const providerValue = useMemo(
    () => ({
      wineProduction: state.wineProduction,
      updateWineProduction,
      wineProductions: state.wineProductions,
      setWineProduction,
      setWineProductions,
      resetWineProduction,
      resetWineProductions,
      wineProductionResult: state.wineProductionResult,
      setWineProductionResult,
      loading: state.wineProductionResult.status === StatusType.loading,
      setError,
      error: state.error
    }),
    [
      state.wineProduction,
      updateWineProduction,
      state.wineProductions,
      setWineProduction,
      setWineProductions,
      resetWineProduction,
      resetWineProductions,
      state.wineProductionResult,
      setWineProductionResult,
      setError,
      state.error
    ]
  );

  return (
    <WineProductionContext.Provider value={providerValue}>
      {children}
    </WineProductionContext.Provider>
  );
};

export default WineProductionProvider;
