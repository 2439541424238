import React from 'react';
import { Progress } from 'reactstrap';
import { Wine } from '../../../../wine/types/Wine';

interface IProps {
  wine: Wine;
  onClick: () => void;
  hasAnyTank: boolean;
  hideFieldsForMobile: boolean;
  hideFieldsForSmallMobile: boolean;
}

export const WineInHarvestRow: React.FC<IProps> = ({
  wine,
  onClick,
  hasAnyTank,
  hideFieldsForMobile,
  hideFieldsForSmallMobile
}) => {
  const barColor = (wine: Wine) => {
    if (wine?.tank?.percentOfUsage > 75 && wine?.tank?.percentOfUsage <= 85) {
      return 'warning';
    } else if (wine?.tank?.percentOfUsage > 85) {
      return 'danger';
    }
    return 'info';
  };

  return (
    <tr className="odd parent" style={{ cursor: 'pointer' }} onClick={onClick}>
      <td className={'text-bold'}>{wine.name}</td>
      <td className="text-center">{wine.startLiters} l.</td>
      {!hideFieldsForSmallMobile ? (
        <td className="text-center">{wine.pressWasteLiters} l.</td>
      ) : null}
      {!hideFieldsForMobile ? <td className="text-center">{wine.wasteLiters} l.</td> : null}
      <td className="text-center">{wine.endLiters} l.</td>
      {hideFieldsForMobile ? null : (
        <>
          <td className="text-center">{wine.disposedLiters} l.</td>
          <td className="text-center">{wine.liters} l.</td>
          {hasAnyTank ? (
            <td className="text-center">
              {wine?.tank?.percentOfUsage ? (
                <div className="d-flex align-items-center">
                  <div className="w-100">
                    <Progress
                      className="progress-xs m-0"
                      value={wine?.tank?.percentOfUsage}
                      color={barColor(wine)}
                    />
                  </div>
                  <div className="wd-xxs text-right">
                    <div className="text-bold text-muted">{wine?.tank?.percentOfUsage || '0'}%</div>
                  </div>
                </div>
              ) : null}
            </td>
          ) : null}
          {hasAnyTank ? <td className="text-center">{wine?.tank?.number}</td> : null}
        </>
      )}
    </tr>
  );
};
