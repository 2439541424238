import { useCallback, useState } from 'react';
import { useIngredientContext } from '../context/IngredientContext';
import { Service, StatusType } from '../../../../../types/Service';
import log from 'loglevel';
import { ResponseError } from '../../../../error/ResponseError';
import { Ingredient } from '../types/Ingredient';
import { useHistory, useParams } from 'react-router-dom';
import { useEventHandlerActions } from '../../../hooks/useEventHandlerActions';
import { ToApiConverter } from '../../../../../services/Converters';
import { RouterParams } from '../../../../../types/RouterParams';

export const useIngredientOnClickService = <T>(
  addIngredient: (ingredient: Ingredient) => Promise<T>,
  putIngredient: (ingredientId: number, ingredient: Ingredient) => Promise<T>,
  fieldName: 'wine' | 'wine_production',
  setResult: (value: Service<T>) => void
) => {
  const { updateIngredient, ingredient, setIngredientResult } = useIngredientContext();
  const history = useHistory();
  const { appliedIngredientId } = useParams<RouterParams>();
  const [key, setKey] = useState(new Date());
  const [loading, setLoading] = useState(false);

  const { onChange, updateDateTime: updateDate } = useEventHandlerActions(updateIngredient);

  const updateIngredientSelect = (selected) => {
    updateIngredient('ingredient', { ...selected, id: selected.value, label: selected.label });
    updateIngredient('type', selected.type);
  };

  const updateTypeSelect = (name, selected) => {
    updateIngredient(name, selected.value);
    updateIngredient('ingredient', {});
  };

  const updateOnSubmit = useCallback(
    (e) => {
      e.preventDefault();
      if (appliedIngredientId) {
        setLoading(true);
        putIngredient(parseInt(appliedIngredientId), ToApiConverter.convertIngredient(ingredient))
          .then(() => history.push(history?.location?.state?.['from'] || `/mv/${fieldName}/all`))
          .catch((response) => {
            log.debug(response);
            setIngredientResult(new ResponseError(response));
          })
          .finally(() => setLoading(false));
      }
    },
    [ingredient, appliedIngredientId, setIngredientResult, history, fieldName, putIngredient]
  );

  const saveOnSubmit = useCallback(
    (e) => {
      e.preventDefault();
      setLoading(true);
      addIngredient(ToApiConverter.convertIngredient(ingredient))
        .then((response) => {
          updateIngredient('reset', '');
          setResult({ status: StatusType.loaded, payload: response });
          setKey(new Date());
        })
        .then(() => {
          window.scrollTo({ behavior: 'smooth', top: 0 });
        })
        .catch((response) => {
          log.debug(response);
          setIngredientResult(new ResponseError(response));
        })
        .finally(() => setLoading(false));
    },
    [ingredient, setResult, updateIngredient, setIngredientResult, addIngredient]
  );

  return {
    updateIngredientSelect,
    updateTypeSelect,
    onChange,
    updateDate,
    onSubmit: { update: updateOnSubmit, save: saveOnSubmit },
    key,
    loading
  };
};
