import React, { useContext } from 'react';
import { SubscriptionType } from '../../../types/Subscription';
import usePlanPicker from '../../../features/subscription/hooks/usePlanPicker';
import { AuthContext } from '../../../features/platform/AuthContext';
import ContentWrapper from '../../../layouts/ContentWrapper';

export const LockArea: React.FC<{
  subscriptionTypeNeeded: SubscriptionType;
  blockingMessage: string;
  label: string;
  itemIndexToHighlight: number;
}> = ({ subscriptionTypeNeeded, blockingMessage, label, itemIndexToHighlight }) => {
  const {
    subscriptionInfo: { accessInfo }
  } = useContext(AuthContext);

  const { isValidSubscription } = usePlanPicker({
    subscriptionType: subscriptionTypeNeeded,
    fromBlockingContent: true,
    blockingMessage,
    itemIndexToHighlight
  });

  function hasAccess() {
    if (subscriptionTypeNeeded === SubscriptionType.PREMIUM) return accessInfo.hasPremiumAccess;
    if (subscriptionTypeNeeded === SubscriptionType.NORMAL) return accessInfo.hasNormalAccess;
    return true;
  }

  function getLabel() {
    if (subscriptionTypeNeeded === SubscriptionType.PREMIUM) return 'premium';
    if (subscriptionTypeNeeded === SubscriptionType.NORMAL) return 'pro';
    return '';
  }

  if (hasAccess()) return null;

  return (
    <ContentWrapper noBoarder>
      <div className="text-center">
        <button className="btn" onClick={() => isValidSubscription()}>
          {label} <em className={'icon-lock ml-1'} />
          <sup className="text-yellow ml-1"> {getLabel()}</sup>
        </button>
      </div>
    </ContentWrapper>
  );
};
