import { AuthApi } from './AuthApi';
import { FromApiConverter } from '../../services/Converters';

export const AuthService = {
  getUserInfo: () => {
    return AuthApi.get().then(FromApiConverter.convertPrincipal);
  },

  login: (login, password) => {
    return AuthApi.login(login, password).then(FromApiConverter.convertPrincipal);
  },

  register: (email, username, companyName) => {
    return AuthApi.register(email, username, companyName);
  },

  logout: () => {
    return AuthApi.logout().then(FromApiConverter.convertPrincipal);
  },

  recover: (email, fields) => {
    return AuthApi.recover(email, fields);
  },

  validate: (token, fields) => {
    return AuthApi.validate(token, fields).then(FromApiConverter.convertPrincipal);
  },

  reset: (token, password, fields) => {
    return AuthApi.reset(token, password, fields).then(FromApiConverter.convertPrincipal);
  }
};
