import React, { useContext } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { AuthContext } from '../../../platform/AuthContext';
import IngredientProvider from '../ingredient/context/IngredientContext';
import ProductionEventProvider from '../production_event/context/ProductionEventContext';
import { WineProductionForm } from './components/WineProductionForm';
import WineProductionProvider from './context/WineProductionContext';
import { WineProductionList } from './components/WineProductionList';
import { WineProductionInfo } from './components/WineProductionInfo';
import { AppliedIngredientForm } from '../ingredient/components/applied/AppliedIngrediendForm';
import { ProductionEventForm } from '../production_event/components/ProductionEventForm';
import WineEntryProvider from './components/AddWineEntryForm/WineEntryContext';
import NotFound from '../../../../pages/NotFound';

export const WineProductionApp = () => {
  const { principal } = useContext(AuthContext);
  return (
    <WineProductionProvider>
      <WineEntryProvider>
        <IngredientProvider>
          <ProductionEventProvider>
            <Switch>
              <Route exact path="/mv/wine_production">
                <Redirect to="/mv/wine_production/all" />
              </Route>
              <Route exact path="/mv/wine_production/all" component={WineProductionList} />
              <Route exact path="/mv/wine_production/:status?" component={WineProductionList} />
              <Route exact path="/mv/wine_production/e/:wineProductionId?">
                <WineProductionForm principal={principal} />
              </Route>
              <Route exact path="/mv/wine_production/info/:wineProductionId?">
                <WineProductionInfo principal={principal} />
              </Route>
              <Route
                exact
                path="/mv/wine_production/ingredient/:wineProductionId?/:appliedIngredientId?"
              >
                <AppliedIngredientForm entity={'wine_production'} />
              </Route>
              <Route exact path="/mv/wine_production/event/:wineProductionId?/:productionEventId?">
                <ProductionEventForm entity={'wine_production'} />
              </Route>
              <Route path="*" component={NotFound} />
            </Switch>
          </ProductionEventProvider>
        </IngredientProvider>
      </WineEntryProvider>
    </WineProductionProvider>
  );
};
