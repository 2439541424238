import { Subscription, SubscriptionStatus, SubscriptionType } from '../../../types/Subscription';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const AccountHeaderInfo: React.FC<{ subscription: Subscription; title: string }> = ({
  subscription,
  title
}) => {
  const { t } = useTranslation();

  function getDateColor() {
    if (subscription?.status === SubscriptionStatus.PAST_DUE) return 'text-danger';
    return subscription?.status === SubscriptionStatus.TRIAL ? 'text-warning' : 'text-success';
  }

  return (
    <div>
      <div className="text-bold float-left card-header">{title}</div>
      <div className={'float-right'}>
        <div>
          <div className="mt-3 mr-3">
            <strong className="float-right h4 text-gradient-blue-purple">
              {t(`plans.subscription.type.${subscription?.type}`)}
            </strong>
          </div>
          {subscription?.type !== SubscriptionType.BASIC ? (
            <div>
              <div className={'float-right pr-3 text-sm'} style={{ marginTop: '-15px' }}>
                {subscription?.status === SubscriptionStatus.TRIAL
                  ? 'Okres próbny do:'
                  : 'Ważny do:'}{' '}
                <span className={getDateColor()}>{subscription?.validToDate}</span>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
