import React, { FC, useRef } from 'react';

import { Input } from 'reactstrap';
import { ServiceError } from '../../types/Service';
import { InputElementSection } from './form-elements/InputElement/InputElementSection';
import { useAutosizeTextArea } from '../../hooks/useAutosizeTextArea';

interface Props {
  label: string;
  name: string;
  optional?: boolean;
  placeholder?: string;
  maxSize?: number;
  defaultValue?: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  disabled?: boolean;
  error?: ServiceError;
  className?: string;
}

const InputElement: FC<Props> = ({
  label,
  name,
  optional,
  placeholder,
  maxSize,
  defaultValue,
  onChange,
  error,
  disabled,
  className
}) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useAutosizeTextArea(textAreaRef.current, defaultValue);

  return (
    <InputElementSection className={className}>
      <>
        {label && (
          <label className="col-md-2 col-form-label pr-0" htmlFor={name}>
            {label}
            <span className="text-danger" style={{ display: optional === true ? 'none' : '' }}>
              {' '}
              *
            </span>
          </label>
        )}
        <div className="col-md-10">
          <Input
            id="review-text"
            placeholder={placeholder}
            name={name}
            type={'textarea'}
            invalid={error?.hasError?.(name)}
            maxLength={maxSize}
            value={defaultValue}
            disabled={disabled}
            onChange={onChange}
            innerRef={textAreaRef}
            rows={1}
          />
          <span className="invalid-feedback">{error?.getErrorMessage?.(name)}</span>
        </div>
      </>
    </InputElementSection>
  );
};

export default InputElement;
