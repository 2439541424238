import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../../../../../components/common/buttons/Button';
import CommonRow from '../../../../../../../components/common/table/CommonRow';
import { PaginationComponent } from '../../../../../../../components/PaginationComponent';
import usePagination from '../../../../../../../hooks/usePagination';
import CheckedBadge from '../../../../../../../components/common/badges/CheckedBadge';
import { usePushHistory } from '../../../../../../../hooks/usePushHistory';
import { TaxBandsPlacement } from '../../../types/WineStorage';
import { SectionWrapper } from '../../../../../../../layouts/SectionWrapper';

interface IProps {
  taxBandsPlacements: TaxBandsPlacement[];
  loading: boolean;
  remove: (entity: TaxBandsPlacement) => void;
  children: React.ReactNode;
  removeDisabled: boolean;
  wineStorageId: number;
}

const SimpleTaxBandsPlacementsList: React.FC<IProps> = ({
  taxBandsPlacements,
  loading,
  remove,
  children,
  removeDisabled,
  wineStorageId
}) => {
  const { t } = useTranslation();
  const pagination = usePagination(taxBandsPlacements || [], 10);
  const { pushHistory } = usePushHistory();

  function getThead() {
    return [
      `${t('wineStorage.taxBandsPlacement.PLACEMENT_DATE')}`,
      `${t('wineStorage.taxBandsPlacement.DECLARATION_DATE')}`,
      `${t('wineStorage.taxBandsPlacement.PAYED')}`,
      `${t('common.ACTIONS')}`
    ];
  }

  function navigateToTaxBandPlacement(id) {
    pushHistory(`/mv/wine_storage/tax_band_placement/${wineStorageId}/${id}`);
  }

  const buildRow = (taxBandsPlacement) => {
    const fields = [
      <td style={{ textAlign: 'center' }} key={1}>
        {taxBandsPlacement?.quantity}
      </td>,
      <td style={{ textAlign: 'center' }} key={2}>
        {taxBandsPlacement?.placementDate}
      </td>,
      <td style={{ textAlign: 'center' }} key={3}>
        <CheckedBadge checked={!!taxBandsPlacement?.declarationDate} />
      </td>,
      <td style={{ textAlign: 'center' }} key={4}>
        <CheckedBadge checked={taxBandsPlacement?.payed} />
      </td>,
      <td style={{ textAlign: 'right' }} className={'text-nowrap'} key={5}>
        <Button.Edit onClick={() => navigateToTaxBandPlacement(taxBandsPlacement.id)} />
        <Button.Delete onClick={() => remove(taxBandsPlacement)} disabled={removeDisabled} />
      </td>
    ];
    return (
      <tr
        key={taxBandsPlacement.id}
        className={
          taxBandsPlacement.needAttention ? 'border-danger border border-bottom-0 bg-light' : ''
        }
      >
        {fields}
      </tr>
    );
  };

  return (
    <SectionWrapper loading={loading} hideSpinner>
      <Card>
        <CardBody>
          <h5 className="m-0 float-right">{t('wineStorage.taxBandsPlacement.ADDED')}</h5>
          <small className="text-muted">
            {t('common.SUMMARY')}:{' '}
            {taxBandsPlacements
              ?.map((taxband) => taxband.quantity)
              .reduce((total, item) => total + item, 0)}{' '}
            szt.
          </small>
          {pagination.getCurrentResults()?.length ? (
            <table className={'table w-100 no-footer table-hover table-sm'}>
              <tbody>
                <CommonRow
                  label={`${t('wineStorage.taxBandsPlacement.QUANTITY')}`}
                  value={getThead()}
                  className={'text-center'}
                />
                {(pagination.getCurrentResults() || []).map((taxBandsPlacement) =>
                  buildRow(taxBandsPlacement)
                )}
              </tbody>
            </table>
          ) : (
            <CardBody>
              <div className={'text-center'}>{t('common.NO_DATA')}</div>
            </CardBody>
          )}
          {children}
          <div className={'float-right pt-2'} style={{ marginBottom: '-20px' }}>
            <PaginationComponent pagination={pagination} />
          </div>
        </CardBody>
      </Card>
    </SectionWrapper>
  );
};

export default SimpleTaxBandsPlacementsList;
