import { Ingredient } from '../../../../ingredient/types/Ingredient';
import { FC } from 'react';
import { useCRUDListBoxContainer } from '../../../../wine/components/WineInfo/list-box/shared/useCRUDListBoxContainer';
import { WineProduction } from '../../../../wine/types/WineProduction';
import { useWineProductionContext } from '../../../context/WineProductionContext';

export interface WineProductionIngredientsListBoxProps {
  ingredients: Ingredient[];
  delIngredient: (ingredientId: number) => void;
  disabledActions?: boolean;
  entityId?: number;
  loading: boolean;
}

type Props = WineProductionIngredientsListBoxProps & {
  render: (list, elementToShow, actions) => JSX.Element;
};

export const WineProductionIngredientsListBoxContainer: FC<Props> = ({
  render,
  ingredients,
  delIngredient
}) => {
  const { setWineProductionResult, setError } = useWineProductionContext();
  const { list, elementToShow, actions } = useCRUDListBoxContainer<WineProduction, Ingredient>(
    ingredients,
    delIngredient,
    setWineProductionResult,
    setError
  );

  const selectedActions = {
    editElement: actions.editElement,
    removeElement: actions.removeElement,
    toggleShow: actions.toggleShow
  };

  return render(list, elementToShow, selectedActions);
};
