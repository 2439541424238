import React from 'react';
import { FormGroup } from 'reactstrap';
import Select from 'react-select';
import { SelectOption } from '../../../../types/SelectOption';
import { SelectOptionProps } from './SelectTypeModel';
import { SectionWrapper } from '../../../../layouts/SectionWrapper';

interface Props {
  receiver: (value: unknown) => {
    options: SelectOption[];
    selected: string | SelectOption;
    loading: boolean;
  };
  disabled?: boolean;
  clazzName?: string;
}

export const SelectType: React.FC<SelectOptionProps<unknown> & Props> = ({
  value: selected,
  onChange,
  name,
  label,
  optional,
  error,
  receiver,
  disabled,
  errorName,
  clazzName
}) => {
  const { options, selected: value, loading } = receiver(selected);

  const finalOptions = options.map((option) => {
    if (option.lock) {
      return { ...option, label: option.label + ' - opcja dostępna w wyższym planie' };
    }
    return option;
  });

  const customStyles = {
    control: (base) => ({
      ...base,
      borderColor: '#d92550',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center right calc(2.25rem / 4)',
      backgroundSize: 'calc(2.25rem / 2) calc(2.25rem / 2)'
    })
  };

  const errorFieldName = errorName || name;

  return (
    <fieldset className={clazzName}>
      <FormGroup row>
        {label && (
          <label className="col-md-2 col-form-label pr-0" htmlFor={name}>
            {label}
            <span className="text-danger" style={{ display: optional === true ? 'none' : '' }}>
              {' '}
              *
            </span>
          </label>
        )}
        <div className={label ? 'col-md-10' : 'col-md-12'}>
          <SectionWrapper loading={loading} hideSpinner>
            <Select
              name={name}
              options={finalOptions}
              onChange={(s) => onChange(name, s)}
              value={value}
              placeholder={'Wybierz'}
              styles={error?.hasError?.(errorFieldName) && customStyles}
              isDisabled={disabled}
              isOptionDisabled={(option) => {
                if (typeof option !== 'string') {
                  return option.lock;
                }
                return false;
              }}
            />
          </SectionWrapper>
          <span
            className="invalid-feedback"
            style={{ display: error?.hasError?.(errorFieldName) ? 'block' : 'none' }}
          >
            {error?.getErrorMessage?.(errorFieldName)}
          </span>
        </div>
      </FormGroup>
    </fieldset>
  );
};
