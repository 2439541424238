import React from 'react';
import { FromApiConverter } from '../../../../../../services/Converters';
import SimpleWineStorageList from './SimpleWineStorageList';
import { useEntityPageReceiver } from '../../../../hooks/pagination/useEntityPageReceiver';
import { FormErrorMessage } from '../../../../../../components/notifications/FormErrorMessage';
import { WineStorage } from '../../types/WineStorage';
import { useWineStorageService } from '../../hooks/useWineStorageService';
import { useWineStorageContext } from '../../context/WineStorageContext';

export const WineStorageList = (props) => {
  const service = useWineStorageService();
  const { setError, error } = useWineStorageContext();
  const { entities, currentPage, pagination, limit, loading, paginationActions, entityActions } =
    useEntityPageReceiver<WineStorage, WineStorage>(
      service,
      FromApiConverter.convertWineStorageList,
      'wine_storage',
      setError
    );

  return (
    <SimpleWineStorageList
      {...props}
      wineStorages={entities}
      page={currentPage}
      pagination={pagination}
      limit={limit}
      loading={loading}
      paginationActions={paginationActions}
      entityActions={entityActions}
    >
      <FormErrorMessage error={error} messageType={'details'} />
    </SimpleWineStorageList>
  );
};
