import { useEffect, useState } from 'react';
import { SelectOption } from '../../../../../types/SelectOption';
import { useTranslation } from 'react-i18next';
import { ProductionEventForWineType } from '../types/Wine';

export const useProductionEventTypesReceiverForWine = (
  value?: ProductionEventForWineType | SelectOption | null
) => {
  const { t } = useTranslation();
  const options: SelectOption[] = Object.keys(ProductionEventForWineType).map((i) => ({
    value: i,
    label: t(`event.type.${i}`)
  }));
  const [selected, setSelected] = useState<SelectOption | string>('');

  useEffect(() => {
    if (value) {
      setSelected(value ? { value: value, label: t(`event.type.${value}`) } : '');
      return;
    }
  }, [value, t]);

  return { options, selected, loading: false };
};
