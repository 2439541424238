import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PdfPageLayout } from '../../../../types/enums';
import log from 'loglevel';
import { AuthContext } from '../../../platform/AuthContext';
import { useResponsiveContext } from '../../../platform/ResponsiveContext';
import usePlanPicker from '../../../subscription/hooks/usePlanPicker';
import { SubscriptionType } from '../../../../types/Subscription';
import { LockIconInfo } from '../../../../components/common/lock/LockIconInfo';

export const TankListButtonsBar: React.FC<{ reportAvailable: boolean }> = ({ reportAvailable }) => {
  const { t } = useTranslation();
  const {
    subscriptionInfo: { accessInfo }
  } = useContext(AuthContext);
  const { isMobile } = useResponsiveContext();

  const { isValidSubscription } = usePlanPicker({
    subscriptionType: SubscriptionType.NORMAL,
    fromBlockingContent: true,
    blockingMessage: 'Przejdź na wyższy plan, aby pobrać ten raport',
    itemIndexToHighlight: 6
  });

  function getPdf(layout = PdfPageLayout.LANDSCAPE) {
    if (!isValidSubscription()) {
      return;
    }
    log.debug('GET_PDF');
    window.location.href = `/ajax/pdf/TANKS_SUMMARY?layout=${layout}`;
  }

  return (
    <div className="mb-3">
      <div>
        <Link
          to={{
            pathname: '/mv/tank/e/0',
            state: { from: '/mv/board/tanks' }
          }}
          className="btn btn-sm btn-info"
          type="button"
        >
          <em className="mr-1 fas fa-plus" /> zbiornik
        </Link>
        <Link
          to={'/mv/tank/all'}
          className="btn btn-sm btn-secondary"
          type="button"
          title={t('button.show.list')}
        >
          <em className="fas fa-outdent" />
        </Link>
        {reportAvailable ? (
          <button
            className="btn btn-sm btn-secondary ml-1 float-right"
            type="button"
            onClick={() => getPdf()}
            title={t('button.download.pdf')}
          >
            <em className="ml-auto fa fa-download fa-fw text-info"></em>
            {!isMobile ? <span className={'ml-1'}>{t('button.download.report')}</span> : null}
            <LockIconInfo
              accessInfo={accessInfo}
              subscriptionTypeNeeded={SubscriptionType.NORMAL}
            />
          </button>
        ) : null}
      </div>
    </div>
  );
};
