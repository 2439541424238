import React from 'react';
import { usePopupContext } from './context/PopupContext';

interface ConfirmationProps {
  message: string;
  actionLabel: string;
  actionCallback: () => void;
  actionButtonColor?: 'danger' | 'info' | 'success' | 'warning';
}

export const useConfirmation = () => {
  const { showPopupConfirmation, closePopupComponent } = usePopupContext();
  function showConfirmation({
    message,
    actionLabel,
    actionCallback,
    actionButtonColor
  }: ConfirmationProps) {
    showPopupConfirmation(
      message,
      <>
        <button className={'btn btn-info'} onClick={closePopupComponent}>
          Anuluj
        </button>
        <button
          className={`btn btn-${actionButtonColor || 'danger'} ml-2`}
          onClick={() => {
            actionCallback();
            closePopupComponent();
          }}
        >
          {actionLabel}
        </button>
      </>
    );
  }

  return showConfirmation;
};
