import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export function useSearch<T>({
  entities,
  field
}: {
  entities: T[];
  field: string | string[];
}): [result: T[], search: (value: string) => void] {
  const [result, setResult] = useState<T[]>(entities);
  const { t } = useTranslation();
  const [done, setDone] = useState(false);

  useEffect(() => {
    if (done) return;
    if (entities.length) {
      setResult(entities);
      setDone(true);
    }
  }, [entities, setResult, done, setDone]);

  function search(value: string) {
    const searchValue = value.toLowerCase().trim();
    if (typeof field === 'string') {
      setResult(
        entities.filter((item) => getEnumName(item, field)?.toLowerCase().includes(searchValue))
      );
    } else {
      setResult(
        entities.filter((item) =>
          field.some((f) => getEnumName(item, f)?.toLowerCase().includes(searchValue))
        )
      );
    }
  }

  function getEnumName(item: T, field: string) {
    if (field === 'wineColor') {
      return item[field] ? t(`wine.COLOR.${item[field]}`) : 'moszcz';
    }
    if (field === 'wineTaste') {
      return t(`wine.TASTE.${item[field]}`);
    }
    if (field === 'grapeColor') {
      return t(`wine.COLOR.${item[field]}`);
    }
    if (field === 'ingredientType') {
      return t(`ingredients.TYPE.${item['type']}`);
    }
    return item[field];
  }

  return [result, search];
}
