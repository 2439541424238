import { useState } from 'react';
import { FilesListItemColor } from '../../../../../types/FileType';
import { IngredientType } from '../../../../winery/components/ingredient/types/IngredientType';
import { Ingredient } from '../../../../winery/components/ingredient/types/Ingredient';
import { useTranslation } from 'react-i18next';

export function useIngredientGroups(ingredients: Ingredient[]) {
  const [selectedType, setSelectedType] = useState<IngredientType | 'all'>('all');
  const [selectedIngredients, setSelectedIngredients] = useState<Ingredient[]>(ingredients);
  const { t } = useTranslation();
  const ingredientsGroups: {
    label: string;
    type: IngredientType;
    color: FilesListItemColor;
    count: number;
  }[] = [
    {
      label: t(`ingredients.TYPE.${IngredientType.YEAST}`),
      type: IngredientType.YEAST,
      color: 'yellow',
      count: ingredients.filter((ingredient) => ingredient.type === IngredientType.YEAST).length
    },
    {
      label: t(`ingredients.TYPE.${IngredientType.NUTRIENT}`),
      type: IngredientType.NUTRIENT,
      color: 'warning',
      count: ingredients.filter((ingredient) => ingredient.type === IngredientType.NUTRIENT).length
    },
    {
      label: t(`ingredients.TYPE.${IngredientType.ENZYME}`),
      type: IngredientType.ENZYME,
      color: 'green',
      count: ingredients.filter((ingredient) => ingredient.type === IngredientType.ENZYME).length
    },
    {
      label: t(`ingredients.TYPE.${IngredientType.TANNIN}`),
      type: IngredientType.TANNIN,
      color: 'purple',
      count: ingredients.filter((ingredient) => ingredient.type === IngredientType.TANNIN).length
    },
    {
      label: t(`ingredients.TYPE.${IngredientType.BACTERIA}`),
      type: IngredientType.BACTERIA,
      color: 'primary',
      count: ingredients.filter((ingredient) => ingredient.type === IngredientType.BACTERIA).length
    },
    {
      label: t(`ingredients.TYPE.${IngredientType.DEACIDIFICATION}`),
      type: IngredientType.DEACIDIFICATION,
      color: 'success',
      count: ingredients.filter((ingredient) => ingredient.type === IngredientType.DEACIDIFICATION)
        .length
    },
    {
      label: t(`ingredients.TYPE.${IngredientType.CLARIFICATION}`),
      type: IngredientType.CLARIFICATION,
      color: 'info',
      count: ingredients.filter((ingredient) => ingredient.type === IngredientType.CLARIFICATION)
        .length
    },
    {
      label: t(`ingredients.TYPE.${IngredientType.SUGARING}`),
      type: IngredientType.SUGARING,
      color: 'danger',
      count: ingredients.filter((ingredient) => ingredient.type === IngredientType.SUGARING).length
    },
    {
      label: t(`ingredients.TYPE.${IngredientType.ALCOHOL_INCREASING}`),
      type: IngredientType.ALCOHOL_INCREASING,
      color: 'pink',
      count: ingredients.filter(
        (ingredient) => ingredient.type === IngredientType.ALCOHOL_INCREASING
      ).length
    },
    {
      label: t(`ingredients.TYPE.${IngredientType.OTHER}`),
      type: IngredientType.OTHER,
      color: 'gray',
      count: ingredients.filter((ingredient) => ingredient.type === IngredientType.OTHER).length
    }
  ];

  function handleChangeGroup(type: IngredientType) {
    setSelectedType(type);
    setSelectedIngredients(ingredients.filter((ingredient) => ingredient.type === type));
  }

  function handleAllGroups() {
    setSelectedType('all');
    setSelectedIngredients(ingredients);
  }

  return {
    ingredientsGroups: ingredientsGroups.filter((item) => item.count > 0),
    selectedType,
    selectedIngredients,
    setSelectedIngredients,
    handleAllGroups,
    handleChangeGroup
  };
}
