import React, { FC } from 'react';
import { BoxListActionButtonsSection } from './BoxListActionButtonsSection';
import { Button } from '../Button';

interface Props {
  actions: BoxListActionButtonsActions;
}

export interface BoxListActionButtonsActions {
  editElement?: () => void;
  infoElement?: () => void;
  removeElement?: () => void;
}

export const BoxListActionsButtons: FC<Props> = ({
  actions: { editElement, removeElement, infoElement }
}) => {
  return (
    <BoxListActionButtonsSection float={'right'}>
      {editElement && <Button.Edit onClick={editElement} />}
      {infoElement && <Button.Info onClick={infoElement} />}
      {removeElement && <Button.Delete onClick={removeElement} />}
    </BoxListActionButtonsSection>
  );
};
