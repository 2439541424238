import React from 'react';
import { SubscriptionPlan } from '../../../subscription/data/Plans';
import { SubscriptionType } from '../../../../types/Subscription';
import { GoToPlanPickerButton } from './GoToPlanPickerButton';

export const UpgradePlanInfo: React.FC<{
  plans: SubscriptionPlan[];
  subscriptionType: SubscriptionType;
  hasInProgressSubscription: boolean;
}> = ({ subscriptionType, hasInProgressSubscription, plans }) => {
  if (hasInProgressSubscription) return null;
  return (
    <div>
      <h4 className={'pt-3'}>Opcje, które warto rozważyć:</h4>
      <div className={'text-left'} style={{ marginLeft: 'auto', marginRight: 'auto' }}>
        {plans
          .filter(
            (plan) =>
              plan.subscriptionType !== SubscriptionType.BASIC &&
              plan.subscriptionType !== subscriptionType
          )
          .map((plan) => {
            return (
              <div className="pt-2" key={plan.id}>
                <div className="text-bold text-gradient-blue-purple">{plan.name}</div>
                <div className={'text-left py-2'}>{plan.description}</div>
                <div className={'text-left py-2 mb-2 text-gradient-blue-purple'}>
                  {plan.extraInfo}
                </div>
                <div className="text-center mb-2">
                  <GoToPlanPickerButton
                    subscriptionType={plan.subscriptionType}
                    label={'Sprawdź'}
                    btnColor={'btn-info btn-sm'}
                  />
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};
