import { Plans, SubscriptionPlan } from '../data/Plans';
import { SubscriptionType } from '../../../types/Subscription';
import { useAuthContext } from '../../platform/AuthContext';

export function useGetPlans(): {
  plans: SubscriptionPlan[];
  get: (type: SubscriptionType) => SubscriptionPlan;
} {
  const {
    subscriptionInfo: { subscriptionPlans }
  } = useAuthContext();

  const plans = Plans.map((plan) => ({
    ...(subscriptionPlans.find((planData) => planData.subscriptionType === plan.subscriptionType) ||
      {}),
    ...plan
  })) as SubscriptionPlan[];

  function get(type: SubscriptionType): SubscriptionPlan | undefined {
    return plans?.find((plan) => plan.subscriptionType === type);
  }

  return { plans, get };
}
