import React, { useContext, useEffect, useRef, useState } from 'react';
import PageWrapper from '../../../../layouts/PageWrapper';
import useBoardService from '../../hooks/useBoardService';
import { StorageSummaryData } from '../../../../types/dto';
import { SelectedWines } from './SelectedWinesCard';
import { StorageSummaryCard } from './StorageSummaryCard';
import { StorageListCard } from './StorageListCard';
import { TaxBandsNeedAttentionCard } from './TaxBandsNeedAttentionCard';
import { useTranslation } from 'react-i18next';
import { SubscriptionType } from '../../../../types/Subscription';
import { LockArea } from '../../../../components/common/lock/LockArea';
import { AuthContext } from '../../../platform/AuthContext';
import { usePushHistory } from '../../../../hooks/usePushHistory';
import { useResponsiveContext } from '../../../platform/ResponsiveContext';

export const StorageSummaryPage = () => {
  const serviceRef = useRef(useBoardService());
  const [summary, setSummary] = useState<StorageSummaryData>(null);
  const [loading, setLoading] = useState(true);
  const [favoriteWines, setFavoriteWines] = useState([]);
  const { isMobile } = useResponsiveContext();
  const { t } = useTranslation();
  const { pushHistory } = usePushHistory();

  const {
    subscriptionInfo: { accessInfo }
  } = useContext(AuthContext);

  useEffect(() => {
    if (serviceRef.current != null && loading) {
      serviceRef.current
        .getStorage()
        .then((response) => {
          setLoading(false);
          setSummary(response);
          setFavoriteWines(response.wines.filter((wine) => wine.favoriteWine));
        })
        .catch(() => setLoading(false));
    }
  }, [loading, setSummary]);

  function getWines() {
    if (!summary) return [];
    const wines = summary.wines;
    const winesNeedAttention = summary.winesNeedAttention;
    return wines.map((wine) => ({
      ...wine,
      taxBandsPlacementNeedAttention: !!winesNeedAttention.find((w) => w.id === wine.id)
        ?.taxBandsPlacementNeedAttention
    }));
  }

  return (
    <PageWrapper
      title={'home.STORAGE'}
      onClick={() => pushHistory('/mv')}
      returnText={'sidebar.nav.element.DASHBOARD'}
    >
      <div className={'row'}>
        <div className="col-lg-12">
          {accessInfo.hasPremiumAccess ? (
            <SelectedWines wines={favoriteWines} loading={loading} />
          ) : (
            <div className={'card'}>
              <LockArea
                subscriptionTypeNeeded={SubscriptionType.PREMIUM}
                blockingMessage={'Przejdź na wyższy plan, aby wyświetlić ulubione wina'}
                label={'Ulubione wina dostępne w wyższym planie'}
                itemIndexToHighlight={1}
              />
            </div>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-lg-4">
          {summary?.taxBandsPlacementsNeedAttention?.length ? (
            <TaxBandsNeedAttentionCard
              title={t('storage.board.wines.table.taxBandsNeedAttention.title')}
              taxBandsPlacementsNeedAttention={summary?.taxBandsPlacementsNeedAttention}
              loading={loading}
            />
          ) : null}
          {summary?.taxBandsPlacementsNotProcessed?.length ? (
            <TaxBandsNeedAttentionCard
              title={t('storage.board.wines.table.taxBandsNotProcessed.title')}
              taxBandsPlacementsNeedAttention={summary?.taxBandsPlacementsNotProcessed}
              loading={loading}
            />
          ) : null}
          {isMobile ? <StorageListCard wines={getWines()} loading={loading} /> : null}
          <StorageSummaryCard summary={summary} loading={loading} />
        </div>
        {!isMobile ? (
          <div className="col-lg-8">
            <StorageListCard wines={getWines()} loading={loading} />
          </div>
        ) : null}
      </div>
    </PageWrapper>
  );
};
