import React, { useContext } from 'react';
import { Card, CardBody, Table } from 'reactstrap';
import CommonRow from '../../../../components/common/table/CommonRow';
import { usePushHistory } from '../../../../hooks/usePushHistory';
import { TaxBandsPlacement } from '../../../winery/components/wine_storage/types/WineStorage';
import { LockArea } from '../../../../components/common/lock/LockArea';
import { SubscriptionType } from '../../../../types/Subscription';
import { AuthContext } from '../../../platform/AuthContext';
import { SectionWrapper } from '../../../../layouts/SectionWrapper';

export const TaxBandsNeedAttentionCard: React.FC<{
  taxBandsPlacementsNeedAttention: TaxBandsPlacement[];
  title: string;
  loading: boolean;
}> = ({ taxBandsPlacementsNeedAttention, title, loading }) => {
  const { pushHistory } = usePushHistory();
  const {
    subscriptionInfo: { accessInfo }
  } = useContext(AuthContext);

  return (
    <SectionWrapper loading={loading} hideSpinner>
      <Card>
        <CardBody>
          <small className="text-muted float-left pb-1">{title}</small>
          <small className="text-muted float-right">
            {taxBandsPlacementsNeedAttention.reduce((total, item) => total + item.quantity, 0)} szt.
          </small>
          {!accessInfo.hasPremiumAccess ? (
            <div>
              <Table className={'table-hover'}>
                <tbody>
                  <CommonRow
                    label={''}
                    value={''}
                    valueColumnClassName={'text-right text-warning'}
                  />
                </tbody>
              </Table>
              <LockArea
                subscriptionTypeNeeded={SubscriptionType.PREMIUM}
                blockingMessage={
                  'Przejdź na wyższy plan, aby wyświetlić dane o banderolach do rozliczenia'
                }
                label={'Odblokuj informacje o banderolach'}
                itemIndexToHighlight={4}
              />
            </div>
          ) : (
            <Table className={'table-hover'}>
              <tbody>
                {taxBandsPlacementsNeedAttention.map((taxBandsPlacement) => {
                  return (
                    <CommonRow
                      label={taxBandsPlacement.placementDate.toString()}
                      value={`${taxBandsPlacement.quantity} szt.`}
                      valueColumnClassName={'text-right text-warning'}
                      onClick={() =>
                        pushHistory(`/mv/wine_storage/info/${taxBandsPlacement.wineStorageId}`)
                      }
                      key={taxBandsPlacement.id}
                      style={{ cursor: 'pointer' }}
                    />
                  );
                })}
              </tbody>
            </Table>
          )}
        </CardBody>
      </Card>
    </SectionWrapper>
  );
};
