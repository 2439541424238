import React from 'react';
import { useTranslation } from 'react-i18next';
import { WineStoragePresentationData } from '../../../../types/dto';
import usePagination from '../../../../hooks/usePagination';
import { usePushHistory } from '../../../../hooks/usePushHistory';
import { PaginationComponent } from '../../../../components/PaginationComponent';
import { StorageSummaryWineRow } from './StorageSummaryWineRow';
import { useSearch } from '../../../../hooks/useSearch';
import { SearchComponent } from '../../../../components/common/search/SearchComponent';
import { SectionWrapper } from '../../../../layouts/SectionWrapper';
import { useResponsiveContext } from '../../../platform/ResponsiveContext';

export const StorageWinesListTable: React.FC<{
  wines: WineStoragePresentationData[];
  loading: boolean;
}> = ({ wines, loading }) => {
  const { t } = useTranslation();
  const { isMobile, isSmallMobile } = useResponsiveContext();
  const [result, search] = useSearch<WineStoragePresentationData>({
    entities: wines,
    field: ['name', 'wineColor', 'wineTaste']
  });
  const pagination = usePagination(result, 10);

  const { pushHistory } = usePushHistory();

  function navigateToWine(id: number) {
    pushHistory(`/mv/wine_storage/info/${id}`);
  }

  return (
    <SectionWrapper loading={loading} hideSpinner>
      <div className="card">
        <div className="card-body pb-1">
          <small className="text-muted float-right">{t('storage.board.wines.table.title')}</small>
          <div className="float-left">
            <SearchComponent search={search} />
          </div>
          <div className="dataTables_wrapper dt-bootstrap4 no-footer">
            <div className="row">
              <div className="col-sm-12 table-responsive">
                <table className="table w-100 no-footer table-hover" style={{ width: 1068 }}>
                  <thead>
                    <tr role="row">
                      <th style={{ minWidth: '140px', width: 215 }}>
                        {t('storage.board.wines.table.name')}
                      </th>
                      <th className="text-center">{t('storage.board.wines.table.taste')}</th>
                      <th className="text-center">{t('storage.board.wines.table.color')}</th>
                      {!isSmallMobile ? (
                        <th className="text-center">{t('storage.board.wines.table.bottles')}</th>
                      ) : null}
                      {!isMobile ? (
                        <>
                          <th style={{ width: 135 }}>
                            {t('storage.board.wines.table.taxBandsActualQuantity')}
                          </th>
                          <th style={{ width: 135 }}>{t('storage.board.wines.table.liters')}</th>
                          <th style={{ width: 135 }}>{t('storage.board.wines.table.tanks')}</th>
                        </>
                      ) : null}
                    </tr>
                  </thead>
                  <tbody>
                    {pagination.getCurrentResults().map((wine) => {
                      return (
                        <StorageSummaryWineRow
                          wine={wine}
                          onClick={() => navigateToWine(wine.id)}
                          key={wine.id}
                          short={isMobile}
                          shorter={isSmallMobile}
                        />
                      );
                    })}
                    {pagination.getCurrentResults().length === 0 ? (
                      <tr>
                        <td colSpan={100} className="text-center">
                          Brak danych
                        </td>
                      </tr>
                    ) : null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <PaginationComponent pagination={pagination} />
      </div>
    </SectionWrapper>
  );
};
