import React from 'react';
import { PlanSection } from './PlanSection';
import { PlanItem } from './PlanItem';
import { useAuthContext } from '../../../platform/AuthContext';
import PageWrapper from '../../../../layouts/PageWrapper';
import { useGetPlans } from '../../hooks/useGetPlans';

export const PlansPage = () => {
  const {
    subscriptionInfo: { activeSubscription }
  } = useAuthContext();
  const { plans } = useGetPlans();

  return (
    <PageWrapper disabled>
      <div className="px-4 center">
        <div className="text-center mb-3 py-5 bg-secondary rounded-bottom">
          <div className="h2 text-bold">
            Dobrze dopasowany plan pomoże Ci w stworzeniu{' '}
            <span className={'text-gradient-blue-purple'}>idealnego wina</span>
          </div>
          <small className={'h5'}>Rejestruj, analizuj, udoskonalaj</small>
        </div>
        <div className={'row pt-4 justify-content-center'}>
          {plans
            ?.filter((plan) => {
              if (activeSubscription) {
                return plan.id !== 0;
              }
              return true;
            })
            .map((plan) => {
              return (
                <div className={'col-lg-4'} style={{ maxWidth: '305px' }} key={plan.id}>
                  <PlanSection
                    title={plan.name}
                    type={plan.subscriptionType}
                    description={plan.description}
                    extraInfo={plan.extraInfo}
                    buttonLabel={plan.buttonLabel}
                    color={plan.color}
                    key={plan.id}
                    annualPricePerMonth={plan.annualPricePerMonth}
                  >
                    <div className={'text-bold mb-3'}>{plan.containsItems[0]}</div>
                    {plan.containsItems.map(
                      (item, index) =>
                        index !== 0 && <PlanItem title={item} include={true} key={index} />
                    )}
                  </PlanSection>
                </div>
              );
            })}
        </div>
        <p className="text-center text-muted">
          <small></small>
        </p>
      </div>
    </PageWrapper>
  );
};
