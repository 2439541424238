import { History } from '../../../types/History';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import ContentWrapper from '../../../../../../../layouts/ContentWrapper';
import TimelineSection from '../../../../../../../components/common/Timeline/TimelineSection';
import TimelineElement from '../../../../../../../components/common/Timeline/TimelineElement';
import PropTypes from 'prop-types';
import { Color } from '../../../../../../../components/common/enums/Color';
import { FromApiConverter } from '../../../../../../../services/Converters';
import { AuthContext } from '../../../../../../platform/AuthContext';
import usePlanPicker from '../../../../../../subscription/hooks/usePlanPicker';
import { SubscriptionType } from '../../../../../../../types/Subscription';
import { SectionWrapper } from '../../../../../../../layouts/SectionWrapper';

interface Props {
  history?: History[];
  loading: boolean;
}

const WineHistory: React.FC<Props> = ({ history, loading }) => {
  const { t } = useTranslation();
  const {
    subscriptionInfo: { accessInfo }
  } = useContext(AuthContext);
  const { isValidSubscription } = usePlanPicker({
    subscriptionType: SubscriptionType.NORMAL,
    fromBlockingContent: true,
    itemIndexToHighlight: 5
  });

  function getSubHeader(history: History) {
    if (history.name) return history.name;
    let result = '';
    if (history.actionType) {
      result = t(`history.ACTION_TYPE.${history?.actionType}`);
    }
    return result + (history?.message ? `: ${history?.message}` : '');
  }

  function getFallbackInfo() {
    return !accessInfo.hasNormalAccess ? (
      <div className="text-center">
        <button className="btn" onClick={() => isValidSubscription()}>
          Historia produkcji dostępna w wyższym planie <em className={'icon-lock ml-1'} />
          <sup className="text-yellow ml-1"> pro</sup>
        </button>
      </div>
    ) : (
      <div className="text-center py-4">{loading ? null : 'Brak historii'}</div>
    );
  }

  if (!accessInfo.hasNormalAccess) history = [];

  return (
    <SectionWrapper loading={loading} hideSpinner>
      <ContentWrapper noBoarder>
        {history?.length ? (
          <TimelineSection>
            {history?.map((item, index) => {
              return (
                <TimelineElement
                  historyItem={item}
                  inverted={item?.status === 'APPLIED_INGREDIENT'}
                  iconBg={item?.status === 'APPLIED_INGREDIENT' ? Color.Green : Color.Blue}
                  key={index}
                  header={t(`history.status.${item?.status}`)}
                  date={
                    item?.status === 'CREATED'
                      ? FromApiConverter.convertDate(item?.createdItemDate)
                      : FromApiConverter.convertDateTime(item?.createdItemDate)
                  }
                  subHeader={getSubHeader(item)}
                />
              );
            })}
          </TimelineSection>
        ) : (
          getFallbackInfo()
        )}
      </ContentWrapper>
    </SectionWrapper>
  );
};

WineHistory.propTypes = {
  history: PropTypes.array.isRequired
};

export default WineHistory;
