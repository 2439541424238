import React from 'react';
import { FromApiConverter } from '../../../../../../services/Converters';
import SimpleHarvestList from './SimpleHarvestList';
import useHarvestService from '../../hooks/useHarvestService';
import { useEntityPageReceiver } from '../../../../hooks/pagination/useEntityPageReceiver';
import { Harvest, HarvestPresentation } from '../../types/Harvest';
import { FormErrorMessage } from '../../../../../../components/notifications/FormErrorMessage';
import { useHarvestContext } from '../../context/HarvestContext';

const HarvestList = () => {
  const service = useHarvestService();
  const { setError, error } = useHarvestContext();
  const { entities, currentPage, pagination, limit, loading, paginationActions, entityActions } =
    useEntityPageReceiver<Harvest, HarvestPresentation>(
      service,
      FromApiConverter.convertHarvestList,
      'harvest',
      setError
    );

  return (
    <SimpleHarvestList
      harvests={entities}
      page={currentPage}
      pagination={pagination}
      limit={limit}
      loading={loading}
      paginationActions={paginationActions}
      entityActions={entityActions}
    >
      <FormErrorMessage error={error} messageType={'details'} />
    </SimpleHarvestList>
  );
};

export default HarvestList;
