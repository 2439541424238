import useCrudeApi from '../../../../../lib/useCrudeApi';
import { useParams } from 'react-router-dom';
import {
  BottleEntry,
  BottleOutput,
  TankEntry,
  TankOutput,
  TaxBandsPlacement,
  WineStorage
} from '../types/WineStorage';
import { RouterParams } from '../../../../../types/RouterParams';

export const useWineStorageService = () => {
  const BASE_URL = '/ajax/wine_storages';

  const { wineStorageId } = useParams<RouterParams>();
  const crudeApi = useCrudeApi<WineStorage>(`${BASE_URL}`);

  const { get, post, put, del, getList, getAll } = crudeApi;

  const { putId: archive } = useCrudeApi<WineStorage>(`${BASE_URL}/archive`);
  const { putId: revertArchive } = useCrudeApi<WineStorage>(`${BASE_URL}/revert-archive`);

  const { putBody: addBottleEntry } = useCrudeApi<BottleEntry>(
    `${BASE_URL}/${wineStorageId}/bottle_entry`
  );
  const { del: removeBottleEntry } = useCrudeApi<BottleEntry>(
    `${BASE_URL}/${wineStorageId}/bottle_entry`
  );

  const { putBody: addBottleOutput } = useCrudeApi<BottleOutput>(
    `${BASE_URL}/${wineStorageId}/bottle_output`
  );
  const { del: removeBottleOutput } = useCrudeApi<BottleOutput>(
    `${BASE_URL}/${wineStorageId}/bottle_output`
  );

  const { putBody: addTankEntry } = useCrudeApi<TankEntry>(
    `${BASE_URL}/${wineStorageId}/tank_entry`
  );
  const { del: removeTankEntry } = useCrudeApi<TankEntry>(
    `${BASE_URL}/${wineStorageId}/tank_entry`
  );

  const { putBody: addTankOutput } = useCrudeApi<TankOutput>(
    `${BASE_URL}/${wineStorageId}/tank_output`
  );

  const { putBody: addTaxBandsPlacement } = useCrudeApi<TaxBandsPlacement>(
    `${BASE_URL}/${wineStorageId}/tax_bands_placement`
  );
  const { del: removeTaxBandsPlacement } = useCrudeApi<TaxBandsPlacement>(
    `${BASE_URL}/${wineStorageId}/tax_bands_placement`
  );
  const { get: getTaxBandsPlacement } = useCrudeApi<TaxBandsPlacement>(
    `${BASE_URL}/tax_bands_placement`
  );

  const { put: updateTaxBandsPlacement } = useCrudeApi<TaxBandsPlacement>(
    `${BASE_URL}/tax_bands_placement`
  );

  return {
    get,
    getList,
    getAll,
    post,
    put,
    del,
    archive,
    revertArchive,
    addBottleEntry,
    removeBottleEntry,
    addBottleOutput,
    removeBottleOutput,
    addTankEntry,
    removeTankEntry,
    addTankOutput,
    addTaxBandsPlacement,
    removeTaxBandsPlacement,
    getTaxBandsPlacement,
    updateTaxBandsPlacement
  };
};
