import { SubscriptionTimeLineItem } from '../SubscriptionTimeLineItem';
import { Color } from '../../../../../components/common/enums/Color';
import React from 'react';
import { SubscriptionType } from '../../../../../types/Subscription';
import { useTranslation } from 'react-i18next';

export const TrialPickerInfo: React.FC<{ planType: SubscriptionType }> = ({ planType }) => {
  const { t } = useTranslation();
  return (
    <ul className="timeline container timeline-left">
      <SubscriptionTimeLineItem
        header={'Dzisiaj - pierwszy z 30 dni bezpłatnego okresu próbnego.'}
        subheader={'Możesz korzystać z wszystkich funkcji tego planu'}
        iconColor={Color.Green}
      />
      <SubscriptionTimeLineItem
        header={'Jeśli chcesz kontynuować plan, opłać go do końca okresu próbnego'}
        subheader={
          '7 dni przed końcem okresu próbnego ' +
          t(`plans.subscription.type.${planType}`) +
          ' wyślemy Ci przypomnienie'
        }
        icon={'icon-bell'}
      />
      <SubscriptionTimeLineItem
        header={'Opłacenie planu w trakcie okresu próbnego nie spowoduje utraty dni!'}
        subheader={
          'Twój nowy plan zostanie aktywowany, a jego okres obowiązywania zostanie dodany na koniec okresu próbnego. W ten sposób nic nie tracisz. '
        }
        icon={'fas fa-crown'}
      />
    </ul>
  );
};
