import React, { useState } from 'react';
import { ServiceError, StatusType } from '../../../../../../../types/Service';
import { ResponseError } from '../../../../../../error/ResponseError';
import { useWineStorageService } from '../../../hooks/useWineStorageService';
import { useWineStorageContext } from '../../../context/WineStorageContext';
import {
  BottleOutput,
  BottleOutputType,
  TankEntry,
  TankOutput,
  TankOutputType,
  WineStorage,
  WineStorageEntryType
} from '../../../types/WineStorage';
import { SelectOption } from '../../../../../../../types/SelectOption';
import { ToApiConverter } from '../../../../../../../services/Converters';

export const withAddWineStorageOutputService =
  <Props,>(WrappedComponent: React.ComponentType<Props>) =>
  (props) => {
    const service = useWineStorageService();
    const { setResult, wineStorage } = useWineStorageContext();
    const [loading, setLoading] = useState(false);
    const [entryType, setEntryType] = useState<WineStorageEntryType>(
      wineStorage?.tankEntries?.length ? undefined : WineStorageEntryType.BOTTLE
    );
    const [tankEntry, setTankEntry] = useState<Partial<TankEntry>>({});
    const [bottleOutput, setBottleOutput] = useState<Partial<BottleOutput>>({
      hasTaxBands: true,
      outputDate: new Date(),
      outputType: BottleOutputType.SALE
    });
    const [tankOutput, setTankOutput] = useState<Partial<TankOutput>>({
      outputDate: new Date(),
      outputType: TankOutputType.SALE
    });
    const [error, setError] = useState<ServiceError>();

    const updateEntryType = (name, selected) => {
      setEntryType(selected.value);
      setBottleOutput((output) => ({ ...output, outputType: null }));
      setTankOutput((output) => ({ ...output, outputType: null }));
      setTankEntry({});
    };

    const resetForm = () => {
      setEntryType(undefined);
      setTankEntry({});
    };

    const updateLiters = (event: React.ChangeEvent<HTMLInputElement>) => {
      const liters = event.target.value;
      setTankOutput((entry) => ({ ...entry, liters: parseInt?.(liters) }));
    };

    const updateCheckbox = (name: string, value: boolean) => {
      setBottleOutput((output) => ({ ...output, [name]: value }));
    };

    const handleUpdateSelect = (name: string, selected: SelectOption) => {
      setBottleOutput((output) => ({ ...output, [name]: selected.value }));
      setTankOutput((output) => ({ ...output, [name]: selected.value }));
    };

    const handleUpdateTankSelect = (selected: SelectOption) => {
      const selectedTank = { id: selected.value, label: selected.label };
      setTankOutput((output) => ({ ...output, tank: selectedTank }));
    };

    const updateDate = (dateName: string, date: Date) => {
      setBottleOutput((output) => ({
        ...output,
        [dateName]: date ? ToApiConverter.convertDate(date) : ''
      }));
      setTankOutput((output) => ({
        ...output,
        [dateName]: date ? ToApiConverter.convertDate(date) : ''
      }));
    };

    const updateQuantity = (event: React.ChangeEvent<HTMLInputElement>) => {
      const quantity = event.target.value;
      quantity && setBottleOutput((output) => ({ ...output, quantity: parseInt?.(quantity) }));
    };

    const updateBottleEntry = (event: React.ChangeEvent<HTMLInputElement>) => {
      const quantity = event.target.value;
      quantity &&
        setTankOutput((output) => ({
          ...output,
          bottleEntry: { bottle: { id: 1 }, quantity: parseInt?.(quantity) }
        }));
    };

    const onSubmit = () => {
      setLoading(true);
      const handleResponse = (response: WineStorage) => {
        setResult({ status: StatusType.loaded, payload: response });
        setLoading(false);
        resetForm();
      };

      const handleError = (response: { message: string; errors: NonNullable<unknown> }) => {
        setError(new ResponseError(response));
        setLoading(false);
      };

      if (entryType === WineStorageEntryType.BOTTLE) {
        console.log(bottleOutput);
        service
          .addBottleOutput({ ...bottleOutput, bottle: { id: 1 } })
          .then((response) => {
            handleResponse(response);
          })
          .then(() => {
            window.scrollTo({ behavior: 'smooth', top: 0 });
          })
          .catch(handleError);
      }
      if (entryType === WineStorageEntryType.TANK) {
        service
          .addTankOutput(tankOutput)
          .then((response) => {
            handleResponse(response);
          })
          .then(() => {
            window.scrollTo({ behavior: 'smooth', top: 0 });
          })
          .catch(handleError);
      }
    };

    const newProps = {
      ...props,
      entryType,
      updateEntryType,
      tank: tankEntry.tank,
      updateQuantity,
      onSubmit,
      error,
      updateCheckbox,
      hasTaxBands: !!bottleOutput.hasTaxBands,
      handleUpdateSelect,
      handleUpdateTankSelect,
      outputType: bottleOutput.outputType,
      updateDate,
      outputDate: bottleOutput.outputDate,
      loading,
      tankOutput,
      updateLiters,
      updateBottleEntry
    };

    return <WrappedComponent {...newProps} />;
  };
