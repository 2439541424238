import React, { useCallback, useContext, useMemo, useReducer, useState } from 'react';

interface IPopupContext {
  showPopup: boolean;
  component: React.ReactNode;
  buttons: React.ReactNode;
  closable: boolean;
  // eslint-disable-next-line no-unused-vars
  showPopupComponent: (component?: React.ReactNode, buttons?: React.ReactNode) => void;
  showPopupConfirmation: (message: string, buttons?: React.ReactNode) => void;
  closePopupComponent: () => void;
}

const defaultState = {
  showPopup: false,
  component: <div></div>,
  buttons: <div></div>,
  closable: true,
  showPopupComponent: () => {},
  showPopupConfirmation: () => {},
  closePopupComponent: () => {}
};

const PopupContext = React.createContext<IPopupContext>(defaultState);

export const usePopupContext = (): IPopupContext => useContext(PopupContext);

const reducer = (
  state: IPopupContext,
  action: { type: string; value: { component?: React.ReactNode; buttons?: React.ReactNode } }
) => {
  switch (action.type) {
    case 'SHOW_POPUP':
      return {
        ...state,
        component: action.value.component,
        buttons: action.value.buttons,
        showPopup: true
      };
    case 'CLOSE_POPUP':
      return { ...state, ...defaultState };
    default:
      return { ...state, showPopup: true };
  }
};
const PopupProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, defaultState);

  const closePopupComponent = useCallback(() => {
    dispatch({ type: 'CLOSE_POPUP', value: {} });
  }, []);

  const showPopupComponent = useCallback(
    (component?: React.ReactNode, buttons?: React.ReactNode) => {
      dispatch({ type: 'SHOW_POPUP', value: { component: component || state.component, buttons } });
    },
    [state.component]
  );

  const showPopupConfirmation = useCallback((message: string, buttons: React.ReactNode) => {
    dispatch({
      type: 'SHOW_POPUP',
      value: {
        component: (
          <div
            className={'card-header text-center'}
            style={{ minWidth: '250px', minHeight: '50px', maxWidth: '400px!important' }}
          >
            {message}
          </div>
        ),
        buttons
      }
    });
  }, []);

  const providerValue: IPopupContext = useMemo(
    () => ({
      showPopup: state.showPopup,
      component: state.component,
      buttons: state.buttons,
      closable: state.closable,
      showPopupComponent,
      showPopupConfirmation,
      closePopupComponent
    }),
    [
      state.showPopup,
      state.component,
      state.closable,
      showPopupComponent,
      closePopupComponent,
      showPopupConfirmation,
      state.buttons
    ]
  );

  return <PopupContext.Provider value={providerValue}>{children}</PopupContext.Provider>;
};

export default PopupProvider;
