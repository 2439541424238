import { useState } from 'react';
import { Tank, TankType } from '../../../../winery/components/tank/types/Tank';
import { FilesListItemColor } from '../../../../../types/FileType';

export function useTankGroups(tanks: Tank[]) {
  const [selectedType, setSelectedType] = useState<TankType | 'all'>('all');
  const [selectedTanks, setSelectedTanks] = useState<Tank[]>(tanks);

  const tanksGroups: { label: string; type: TankType; color: FilesListItemColor; count: number }[] =
    [
      {
        label: 'Zbiornik',
        type: TankType.TANK,
        color: 'green',
        count: tanks.filter((tank) => tank.type === TankType.TANK).length
      },
      {
        label: 'Beczka',
        type: TankType.BARREL,
        color: 'info',
        count: tanks.filter((tank) => tank.type === TankType.BARREL).length
      },
      {
        label: 'Kadź',
        type: TankType.VAT,
        color: 'warning',
        count: tanks.filter((tank) => tank.type === TankType.VAT).length
      },
      {
        label: 'Balon',
        type: TankType.BALLOON,
        color: 'purple',
        count: tanks.filter((tank) => tank.type === TankType.BALLOON).length
      }
    ];

  function handleChangeGroup(type: TankType) {
    setSelectedType(type);
    setSelectedTanks(tanks.filter((tank) => tank.type === type));
  }

  function handleAllGroups() {
    setSelectedType('all');
    setSelectedTanks(tanks);
  }

  return {
    tanksGroups,
    selectedType,
    selectedTanks,
    setSelectedTanks,
    handleAllGroups,
    handleChangeGroup
  };
}
