import { useWineProductionContext } from '../../context/WineProductionContext';
import { useEventHandlerActions } from '../../../../hooks/useEventHandlerActions';
import React, { useEffect } from 'react';
import { ServiceError, StatusType } from '../../../../../../types/Service';
import { useHistory, useParams } from 'react-router-dom';
import log from 'loglevel';
import { ResponseError } from '../../../../../error/ResponseError';
import { Wine } from '../../../wine/types/Wine';
import { SelectOption } from '../../../../../../types/SelectOption';
import { WineProduction } from '../../../wine/types/WineProduction';
import useWineProductionService from '../../hooks/useWineProductionService';
import { RouterParams } from '../../../../../../types/RouterParams';

interface Props {
  updateWineProduction: () => void;
  loading: boolean;
  onClickBack: () => void;
  onSubmit: () => void;
  wineProduction: WineProduction;
  error: ServiceError;
  handleUpdateSelect: () => void;
  updateDate: (date: Date | string) => void;
}

export const withWineProductionFromServiceHOC =
  <Props,>(WrappedComponent: React.ComponentType<Props>) =>
  (props) => {
    const { wineProduction, updateWineProduction, setWineProductionResult, wineProductionResult } =
      useWineProductionContext();

    const { onChange: handleUpdateWineProduction, updateDate } =
      useEventHandlerActions(updateWineProduction);

    const updateCheckbox = (name: string, value: boolean) => {
      updateWineProduction(name, value);
    };

    const { wineProductionId } = useParams<RouterParams>();
    const history = useHistory();

    const service = useWineProductionService();

    useEffect(() => {
      if (wineProductionId?.toString() === '0') {
        setWineProductionResult({ status: StatusType.loaded });
        return;
      }
      wineProductionId &&
        service
          .get(parseInt(wineProductionId))
          .then((response) => {
            setWineProductionResult({ status: StatusType.loaded, payload: response });
          })
          .catch((response) =>
            setWineProductionResult(new ResponseError(response) as ServiceError)
          );

      return () => {
        updateWineProduction('reset', '');
      };
    }, [wineProductionId, updateWineProduction]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleUpdateSelect = (name: string, selected: SelectOption) => {
      updateWineProduction(name, selected.value);
    };

    const onSubmit = (e) => {
      e.preventDefault();
      log.debug('withWineProductionFromServiceHOC:onSubmit', e, wineProduction);
      setWineProductionResult({ status: StatusType.loading });

      const action = () =>
        wineProduction?.id
          ? service.put(wineProduction.id, wineProduction)
          : service.post(wineProduction);
      action()
        .then((response) => {
          setWineProductionResult(response);
          history?.push(history?.location?.state?.['from'] || `/mv/wine_production/all`);
        })
        .catch((response) => setWineProductionResult(new ResponseError(response) as ServiceError));
    };

    const onClickBack = () => {
      history?.push(history?.location?.state?.['from'] || `/mv/wine_production/archived`);
    };

    const error = wineProductionResult as ServiceError;

    const newProps = {
      ...props,
      updateWineProduction: handleUpdateWineProduction,
      loading: wineProductionResult.status === StatusType.loading,
      onClickBack,
      onSubmit,
      wineProduction,
      error,
      handleUpdateSelect,
      updateDate,
      updateCheckbox
    };

    return <WrappedComponent {...newProps} />;
  };
