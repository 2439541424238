import { Checkbox } from '../../../../../../../components/common/form-elements/Checkbox';
import { SelectStorageEntryType } from '../SelectStorageEntryType';
import {
  BottleOutputType,
  TankOutput,
  TankOutputType,
  WineStorageEntryType
} from '../../../types/WineStorage';
import { FormErrorMessage } from '../../../../../../../components/notifications/FormErrorMessage';
import React, { FC } from 'react';
import { Card, CardBody } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Tank } from '../../../../tank/types/Tank';
import { ServiceError } from '../../../../../../../types/Service';
import InputElement from '../../../../../../../components/common/InputElement';
import SelectBottleOutputType from './SelectBottleOutputType';
import { InputDate } from '../../../../../../../components/common/form-elements/InputDate';
import SelectTankOutputType from './SelectTankOutputType';
import SelectTankInStorage from './SelectTankInStorage';
import { useWineStorageContext } from '../../../context/WineStorageContext';
import { useResponsiveContext } from '../../../../../../platform/ResponsiveContext';
import { useToggleAndGoToRef } from '../../../../../../../hooks/useToggleAndGoToRef';
import { useAuthContext } from '../../../../../../platform/AuthContext';
import usePlanPicker from '../../../../../../subscription/hooks/usePlanPicker';
import { SubscriptionType } from '../../../../../../../types/Subscription';
import { SectionWrapper } from '../../../../../../../layouts/SectionWrapper';
import { DivDropped } from '../../../../../../../components/common/dropdowns/button-and-div/DivDropped';

interface Props {
  selectDifferentTank: boolean;
  updateCheckbox: (name: string, value: boolean) => void;
  entryType: WineStorageEntryType;
  updateEntryType: () => void;
  tank: Tank;
  updateQuantity: () => void;
  updateBottleEntry: () => void;
  liters: number;
  updateLiters: () => void;
  onSubmit: () => void;
  error: ServiceError;
  loading: boolean;
  disableTankAndLiters: boolean;
  hasTaxBands: boolean;
  outputType: BottleOutputType;
  handleUpdateSelect: () => void;
  handleUpdateTankSelect: () => void;
  updateDate: () => void;
  outputDate: string;
  tankOutput: TankOutput;
}

export const AddStorageOutputFormPresentation: FC<Props> = ({
  entryType,
  updateEntryType,
  updateQuantity,
  onSubmit,
  error,
  loading,
  outputType,
  hasTaxBands,
  updateCheckbox,
  handleUpdateSelect,
  handleUpdateTankSelect,
  updateDate,
  outputDate,
  updateBottleEntry,
  updateLiters,
  tankOutput
}) => {
  const { t } = useTranslation();
  const { isMobile } = useResponsiveContext();
  const {
    value: isOpen,
    toggleValue: toggleOpen,
    currentRef
  } = useToggleAndGoToRef(false, isMobile);
  const { wineStorage } = useWineStorageContext();

  const {
    subscriptionInfo: { accessInfo }
  } = useAuthContext();

  const { isValidSubscription } = usePlanPicker({
    subscriptionType: SubscriptionType.PREMIUM,
    fromBlockingContent: true,
    blockingMessage: 'Przejdź na wyższy plan, aby rozdysponować wino',
    itemIndexToHighlight: 5
  });

  function handleOpen() {
    if (!isValidSubscription()) {
      return;
    }
    toggleOpen();
  }

  return (
    <SectionWrapper loading={loading}>
      <Card innerRef={currentRef} className={isOpen ? 'border-info-dark' : 'bg-info-dark'}>
        <CardBody className={'pt-2 pb-1'}>
          <div
            onClick={() => handleOpen()}
            style={{ cursor: 'pointer', height: '1.5em' }}
            className={'pt-1'}
          >
            <i className="float-left">
              {!accessInfo.hasPremiumAccess ? (
                <em className={'icon-lock'} />
              ) : (
                <em
                  className={
                    isOpen ? 'fa fa-minus text-primary text-gradient-blue-purple' : 'fa fa-plus'
                  }
                />
              )}
              <sup className="text-yellow ml-1"> premium</sup>
            </i>
            <h5 className={'m-0 mb-2 float-right ' + (isOpen ? 'text-gradient-blue-purple' : '')}>
              {t('wineStorage.addOutput')}
            </h5>
          </div>
          <DivDropped open={isOpen}>
            <div className={'mt-2'}>
              <SelectStorageEntryType
                name={'entryType'}
                value={entryType}
                onChange={updateEntryType}
                optional
                label={t('wineStorage.entry.TYPE')}
                key={entryType}
              />
              {entryType === WineStorageEntryType.BOTTLE ? (
                <SelectBottleOutputType
                  value={outputType}
                  name={'outputType'}
                  label={t('wineStorage.outputType')}
                  onChange={handleUpdateSelect}
                  error={error}
                />
              ) : null}
              {entryType === WineStorageEntryType.TANK ? (
                <SelectTankOutputType
                  value={outputType}
                  name={'outputType'}
                  label={t('wineStorage.outputType')}
                  onChange={handleUpdateSelect}
                  error={error}
                />
              ) : null}
              <InputDate
                label={t('bottleOutput.date')}
                name={'outputDate'}
                onChange={updateDate}
                defaultValue={outputDate}
                error={error}
              />
              {entryType === WineStorageEntryType.TANK && (
                <SelectTankInStorage
                  value={tankOutput.tank}
                  name={'tank'}
                  onChange={handleUpdateTankSelect}
                  label={t('wineStorage.entry.TANK')}
                  error={error}
                  tanks={wineStorage.tankEntries?.map((entry) => entry.tank)}
                />
              )}
              {entryType === WineStorageEntryType.BOTTLE && (
                <>
                  <InputElement
                    name={'quantity'}
                    onChange={updateQuantity}
                    label={t('wineStorage.entry.QUANTITY')}
                    placeholder={''}
                    type={'number'}
                    className={'mb-0 pb-2'}
                    error={error}
                  />
                  <div className="row pl-3 pb-2 pt-4">
                    <Checkbox
                      name={'withTaxBands'}
                      label={t('wineStorage.entry.checkbox.withTaxBands')}
                      value={hasTaxBands}
                      onChange={() => updateCheckbox('hasTaxBands', !hasTaxBands)}
                    />
                  </div>
                </>
              )}
              {entryType === WineStorageEntryType.TANK ? (
                <InputElement
                  name={'liters'}
                  onChange={updateLiters}
                  defaultValue={tankOutput.liters}
                  type={'number'}
                  label={t('wineStorage.tank.bottleEntry.LITERS')}
                  error={error}
                  className={tankOutput.outputType === TankOutputType.BOTTLING ? '' : 'mb-0 pb-2'}
                />
              ) : null}
              {entryType === WineStorageEntryType.TANK &&
              tankOutput.outputType === TankOutputType.BOTTLING ? (
                <InputElement
                  name={'quantity'}
                  onChange={updateBottleEntry}
                  defaultValue={tankOutput.bottleEntry?.quantity}
                  placeholder={
                    tankOutput.liters ? 'max ' + Math.floor(tankOutput.liters / 0.75) + ' l.' : ''
                  }
                  type={'number'}
                  label={t('wineStorage.tank.bottleEntry.QUANTITY')}
                  error={error}
                  className={'mb-0 pb-2'}
                />
              ) : null}
              <FormErrorMessage error={error} messageType={'details'} clazzName={'p-1'} />
              <div className={'text-center bg-secondary'}>
                <button
                  className="btn btn-info"
                  type="button"
                  onClick={onSubmit}
                  disabled={!entryType}
                >
                  {' '}
                  {t('common.ADD')}
                </button>
              </div>
            </div>
          </DivDropped>
        </CardBody>
      </Card>
    </SectionWrapper>
  );
};
