import React, { FC } from 'react';
import { WineEventsListBoxContainer, WineEventsListBoxProps } from './WineEventsListBoxContainer';
import EventsListBox from '../shared/EventsListBox';

export const WineEventsListBox: FC<WineEventsListBoxProps> = (props) => {
  return (
    <WineEventsListBoxContainer
      {...props}
      render={(events, eventToShow, actions) => (
        <EventsListBox
          events={events}
          eventToShow={eventToShow}
          actions={actions}
          entityName={'wine'}
          loading={props.loading}
          disabledActions={props?.disabledActions}
          entityId={props.entityId}
        />
      )}
    />
  );
};
