import React, { FC } from 'react';
import { Button, Card, CardBody, CardHeader } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { AddIngredientFormFields } from '../shared/AddIngredientFormFields';
import PageWrapper from '../../../../../../../layouts/PageWrapper';
import { IngredientFormProps } from '../shared/withIngredientServiceHOC';
import { usePushHistory } from '../../../../../../../hooks/usePushHistory';
import { SectionWrapper } from '../../../../../../../layouts/SectionWrapper';

export const EditAppliedIngredientForm: FC<IngredientFormProps> = ({
  ingredient,
  actions: { onSubmit, updateIngredientSelect, updateTypeSelect, onChange, updateDate },
  error,
  loading
}) => {
  const { t } = useTranslation();
  const { pushHistory } = usePushHistory();

  function pushToBoard() {
    pushHistory(`/mv/board/ingredients`);
  }

  return (
    <PageWrapper title={'ingredients.TITLE'} onClick={pushToBoard} returnText={'home.INGREDIENTS'}>
      <SectionWrapper loading={loading}>
        <Card>
          <CardHeader> {t('sidebar.nav.element.EDIT')}</CardHeader>
          <CardBody>
            <AddIngredientFormFields
              ingredient={ingredient}
              actions={{ updateIngredientSelect, updateTypeSelect, onChange, updateDate }}
              error={error}
              editing={true}
            />
            <div className="text-center bg-secondary">
              <Button color="info" className="btn-square" onClick={onSubmit?.update}>
                {t('common.SAVE')}
              </Button>
            </div>
          </CardBody>
        </Card>
      </SectionWrapper>
    </PageWrapper>
  );
};
