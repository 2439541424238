import { FC } from 'react';
import { ProductionEvent } from '../../../../wine/types/Wine';
import { useCRUDListBoxContainer } from '../../../../wine/components/WineInfo/list-box/shared/useCRUDListBoxContainer';
import { WineProduction } from '../../../../wine/types/WineProduction';
import { useWineProductionContext } from '../../../context/WineProductionContext';

export interface WineProductionEventsListBoxProps {
  events: ProductionEvent[];
  removeEvent: (eventId: number) => void;
  disabledActions?: boolean;
  entityId?: number;
  loading: boolean;
}

type Props = WineProductionEventsListBoxProps & {
  render: (list, elementToShow, actions) => JSX.Element;
};

export const WineProductionEventsListBoxContainer: FC<Props> = ({
  render,
  events,
  removeEvent
}) => {
  const { setWineProductionResult, setError } = useWineProductionContext();
  const { list, elementToShow, actions } = useCRUDListBoxContainer<WineProduction, ProductionEvent>(
    events,
    removeEvent,
    setWineProductionResult,
    setError
  );

  const selectedActions = {
    editElement: actions.editElement,
    removeElement: actions.removeElement,
    toggleShow: actions.toggleShow
  };

  return render(list, elementToShow, selectedActions);
};
