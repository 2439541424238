import React from 'react';

const SmallTextBadge = ({ title, color, float = 'right' }) => {
  return (
    <div className={'p-1 float-' + float}>
      <div className={'badge bg-' + (color || 'info')}>{title}</div>
    </div>
  );
};

export default SmallTextBadge;
