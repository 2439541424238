import React, { FC } from 'react';
import {
  IngredientToWIneServiceProps,
  withIngredientServiceHOC
} from '../shared/withIngredientServiceHOC';
import { Wine } from '../../../../wine/types/Wine';
import { EditAppliedIngredientForm } from '../common/EditAppliedIngredientForm';

export const AppliedToWineIngredientForm: FC<IngredientToWIneServiceProps<Wine>> = (props) => {
  const EditAppliedIngredientFormWithService = withIngredientServiceHOC(EditAppliedIngredientForm);

  return <EditAppliedIngredientFormWithService {...props} />;
};
