import { useEffect, useState } from 'react';
import { Service, ServiceError, ServiceWorking, StatusType } from '../../../../../types/Service';
import { ResponseError } from '../../../../error/ResponseError';
import { SelectOption } from '../../../../../types/SelectOption';
import { Wine } from '../../wine/types/Wine';
import useWineService from '../../wine/hooks/useWineService';

export const useWinesReceiver = (
  value?
): {
  result: Service<Wine[]>;
  options: SelectOption[];
  selected: (SelectOption & { liters: number }) | string;
  loading: boolean;
} => {
  const service = useWineService();
  const [result, setResult] = useState<Service<Wine[]>>({
    status: StatusType.loading
  });
  const [options, setOptions] = useState<SelectOption[]>([]);
  const [selected, setSelected] = useState<(SelectOption & { liters: number }) | string>('');

  useEffect(() => {
    if (value) {
      setSelected(
        value?.id
          ? { value: value.id, label: value.label ? value.label : value.name, liters: value.liters }
          : ''
      );
    }
    const workingResult = result as ServiceWorking<Wine[]>;
    if (!workingResult?.payload) {
      service
        ?.getListWithPositiveLiters()
        .then((response) => {
          setResult({ status: StatusType.loaded, payload: response });
          setOptions(response?.map((p) => ({ value: p.id, label: p.name, liters: p.liters })));
        })
        .catch((error) => setResult(new ResponseError(error) as ServiceError));
    }
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  return { result, options, selected, loading: result.status === StatusType.loading };
};
