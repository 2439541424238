import React, { useRef, useState } from 'react';
import '../../assets/subscription.css';
import { useAuthContext } from '../../../platform/AuthContext';
import { useTranslation } from 'react-i18next';
import { useResponsiveContext } from '../../../platform/ResponsiveContext';
import {
  Subscription,
  SubscriptionPeriod,
  SubscriptionStatus,
  SubscriptionType
} from '../../../../types/Subscription';
import { TrialPickerInfo } from './info/TrialPickerInfo';
import { BasicPickerInfo } from './info/BasicPickerInfo';
import useSubscriptionService from '../../hooks/useSubscriptionService';
import { usePushHistory } from '../../../../hooks/usePushHistory';
import { PickerPlanStepOne } from './steps/PickerPlanStepOne';
import { PickerPlanStepTwo } from './steps/PickerPlanStepTwo';
import { PaidPickerInfo } from './info/PaidPickerInfo';
import { UpgradePaidPickerInfo } from './info/UpgradePaidPickerInfo';
import { useGetPlans } from '../../hooks/useGetPlans';
import { SectionWrapper } from '../../../../layouts/SectionWrapper';

export const PickPlan: React.FC<{
  planType: SubscriptionType;
  fromBlockingContent?: boolean;
  blockingMessage?: string;
  itemIndexToHighlight?: number | number[];
  closePicker: () => void;
}> = ({ planType, fromBlockingContent, blockingMessage, itemIndexToHighlight, closePicker }) => {
  const { t } = useTranslation();
  const [view, setView] = useState<'step1' | 'step2'>('step1');
  const [period, setPeriod] = useState<SubscriptionPeriod>(SubscriptionPeriod.MONTHLY);
  const { get } = useGetPlans();
  const plan = get(planType);
  const normalPlan = get(SubscriptionType.NORMAL);

  const {
    setPrincipal,
    subscriptionInfo: { accessInfo, activeSubscription, inProgressSubscription }
  } = useAuthContext();

  const continueWithUpgradedPlan =
    activeSubscription?.type === SubscriptionType.NORMAL &&
    activeSubscription?.status === SubscriptionStatus.ACTIVATED;

  const { isMobile } = useResponsiveContext();
  const serviceRef = useRef(useSubscriptionService());

  const [loading, setLoading] = useState(false);
  const { pushHistory } = usePushHistory();

  function handleSelect(period?: SubscriptionPeriod, status?: SubscriptionStatus) {
    if (planType === SubscriptionType.BASIC) {
      applySubscription(SubscriptionPeriod.ANNUAL);
      return;
    }
    if (inProgressSubscription && activeSubscription.status !== SubscriptionStatus.TRIAL) {
      pushHistory('/mv/account');
      closePicker();
    }
    if (view === 'step1') {
      setView('step2');
    } else {
      applySubscription(period, status);
    }
  }

  function applySubscription(period: SubscriptionPeriod, status?: SubscriptionStatus) {
    setLoading(true);
    serviceRef.current
      .userChooseSubscription({
        type: planType,
        period: period,
        status,
        continueWithUpgradedPlan
      } as Subscription)
      .then((response) => {
        setPrincipal(response);
        close?.();
        // !fromBlockingContent && pushHistory('/mv/account');
        pushHistory('/mv/account');
      })
      .finally(() => {
        setLoading(false);
        closePicker();
      });
  }

  function getComponentHeight() {
    if (planType === SubscriptionType.PREMIUM) return '670px';
    if (planType === SubscriptionType.NORMAL) return '650px';
    return '610px';
  }

  function getButtonLabel() {
    if (inProgressSubscription && activeSubscription.status !== SubscriptionStatus.TRIAL)
      return 'Dokończ poprzedni plan';
    if (inProgressSubscription && activeSubscription.status === SubscriptionStatus.TRIAL)
      return 'Kontynuuj bezpłatny okres próbny';
    if (accessInfo?.trialAllowed && planType !== SubscriptionType.BASIC)
      return 'Rozpocznij bezpłatny okres próbny';
    return 'Rozpocznij';
  }

  function getHeader() {
    if (planType === SubscriptionType.BASIC) return 'Standardowy plan';
    if (fromBlockingContent && blockingMessage) return blockingMessage;
    if (fromBlockingContent) return 'Przejdź na wyższy plan, aby skorzystać z tej funkcji';
    return 'Uzyskaj dostęp do licznych nowych funkcji';
  }

  function getInfo() {
    if (planType === SubscriptionType.BASIC) return 'Za darmo równie wiele zyskujesz';
    if (accessInfo?.trialAllowed) return 'Skorzystaj z 30-dniowej wersji próbnej';
    return 'Skorzystaj z oferty nowego planu ' + t(`plans.subscription.type.${planType}`);
  }

  return (
    <SectionWrapper loading={loading}>
      <div className={'container container-lg mb-0'} style={{ width: isMobile ? '' : '800px' }}>
        <div className={'row'} style={{ minHeight: getComponentHeight() }}>
          <div className={'bg-secondary p-4 rounded-left ' + (isMobile ? '' : 'col-6')}>
            {view === 'step1' && plan ? (
              <PickerPlanStepOne
                plan={plan}
                header={getHeader()}
                info={getInfo()}
                itemIndexToHighlight={itemIndexToHighlight}
              />
            ) : null}
            {view === 'step2' && plan ? (
              <PickerPlanStepTwo
                plan={plan}
                normalPlan={normalPlan}
                trialAllowed={accessInfo?.trialAllowed}
                handleBack={() => setView('step1')}
                header={getHeader()}
                info={getInfo()}
                activeSubscription={activeSubscription}
                period={period}
                setPeriod={setPeriod}
                continueWithUpgradedPlan={continueWithUpgradedPlan}
              />
            ) : null}
            <div className={'text-sm text-thin mt-2 position-absolute'} style={{ bottom: 15 }}>
              <div className="text-center pt-2 mr-4 mb-1">
                <button
                  className={
                    `btn w-100 btn-lg ` +
                    (inProgressSubscription &&
                    activeSubscription.status !== SubscriptionStatus.TRIAL
                      ? 'btn-warning'
                      : 'btn-info bg-info-dark')
                  }
                  onClick={() => handleSelect(period)}
                  disabled={loading}
                >
                  {getButtonLabel()}
                </button>
              </div>
              <div>
                <span>
                  Kontynuując potwierdzasz, że akceptujesz{' '}
                  <button
                    className="btn btn-link p-0"
                    style={{ cursor: 'pointer', marginBottom: '3px' }}
                    onClick={(e) => {
                      e.preventDefault();
                      window.location.href = 'http://mistrzwina.pl/regulamin.html#tresc';
                    }}
                  >
                    regulamin
                  </button>{' '}
                  planu {t(`plans.subscription.type.${planType}`)}.
                </span>
              </div>
            </div>
          </div>
          {!isMobile ? (
            <div className={'col-6 p-3 align-content-center'}>
              {accessInfo?.trialAllowed && planType !== SubscriptionType.BASIC ? (
                <TrialPickerInfo planType={planType} />
              ) : null}
              {!accessInfo?.trialAllowed &&
              activeSubscription.type === SubscriptionType.BASIC &&
              planType !== SubscriptionType.BASIC ? (
                <PaidPickerInfo />
              ) : null}
              {planType === SubscriptionType.BASIC ? <BasicPickerInfo /> : null}
              {planType === SubscriptionType.PREMIUM &&
              !accessInfo?.trialAllowed &&
              accessInfo?.hasNormalAccess &&
              activeSubscription.type === SubscriptionType.NORMAL ? (
                <UpgradePaidPickerInfo />
              ) : null}
            </div>
          ) : null}
        </div>
      </div>
    </SectionWrapper>
  );
};
