import React from 'react';
import { useTranslation } from 'react-i18next';
import { WineStoragePresentationData } from '../../../../types/dto';
import { WineColor } from '../../../winery/components/wine/types/WineProduction';
import { FilesListItemColor } from '../../../../types/FileType';
import { useLongTouch } from '../../../../hooks/useLongTouch';
import { usePushHistory } from '../../../../hooks/usePushHistory';

interface IProps {
  wine: WineStoragePresentationData;
  onClick: () => void;
  short?: boolean;
  shorter?: boolean;
}

export const StorageSummaryWineRow: React.FC<IProps> = ({ wine, onClick, short, shorter }) => {
  const { t } = useTranslation();
  const { pushHistory } = usePushHistory();

  function handleNavigateToEdit(
    e: React.TouchEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement>
  ) {
    e.preventDefault();
    pushHistory(`/mv/wine_storage/e/${wine.id}`);
  }

  const { handleTouchStart, handleTouchEnd, handleTouchMove } = useLongTouch(handleNavigateToEdit);

  function getColor(): FilesListItemColor | string {
    if (wine.wineColor === WineColor.WHITE) return '';
    if (wine.wineColor === WineColor.ORANGE) return '';
    if (wine.wineColor === WineColor.RED) return 'pink';
    if (wine.wineColor === WineColor.ROSE) return 'pink';
    return 'gray';
  }

  function getBackgroundColor(): FilesListItemColor | string {
    if (wine.wineColor === WineColor.WHITE) return 'rgba(255,207,81,0.49)';
    if (wine.wineColor === WineColor.ORANGE) return 'rgba(255,142,0,0.44)';
    if (wine.wineColor === WineColor.RED) return 'rgba(255,1,1,0.53)';
    if (wine.wineColor === WineColor.ROSE) return 'rgba(255,44,44,0.39)';
    return '';
  }

  function getType(wine: WineStoragePresentationData) {
    if (wine?.wineColor === null) {
      return t('wine_info_box.badge.text.wine');
    }
    return t(`wine.COLOR.${wine.wineColor}`);
  }

  function getTaste(wine: WineStoragePresentationData) {
    if (wine?.wineColor === null) {
      return null;
    }
    return wine.wineTaste ? t(`wine.TASTE.${wine.wineTaste}`) : null;
  }

  return (
    <tr
      className={
        'odd parent ' +
        (wine.taxBandsPlacementNeedAttention ? 'border-danger border border-bottom-0 bg-light' : '')
      }
      style={{ cursor: 'pointer', userSelect: 'none', WebkitUserSelect: 'none' }}
      onClick={onClick}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      onTouchMove={handleTouchMove}
      onContextMenu={handleNavigateToEdit}
    >
      <td className={'text-bold'}>{wine.name}</td>
      <td className="dtr-control sorting_1 text-center" tabIndex={0}>
        <div className={'badge bg-gray-lighter'}>{getTaste(wine)}</div>
      </td>
      <td className="dtr-control sorting_1 text-center" tabIndex={0}>
        <div className={'badge bg-' + getColor()} style={{ backgroundColor: getBackgroundColor() }}>
          {!short ? getType(wine) : <div style={{ height: '8px' }}></div>}
        </div>
      </td>
      {!shorter ? <td className="text-center">{wine.totalBottles}</td> : null}
      {!short ? (
        <>
          <td>{wine.taxBandsActualQuantity}</td>
          <td>{wine.totalLiters}</td>
          <td>{wine.totalTanks}</td>
        </>
      ) : null}
    </tr>
  );
};
