import useCrudeApi from '../../../../../lib/useCrudeApi';
import { useParams } from 'react-router-dom';
import { TaxBandsPlacement } from '../types/WineStorage';

export const useTaxBandsPlacementService = () => {
  const { wineStorageId } = useParams();
  const BASE_URL = `/ajax/wine_storages/${wineStorageId}/tax_bands_placement`;

  const crudeApi = useCrudeApi<TaxBandsPlacement>(`${BASE_URL}`);

  const { get, post, put, del, getList, getAll } = crudeApi;

  const { putId: archive } = useCrudeApi<TaxBandsPlacement>(`${BASE_URL}/archive`);
  const { putId: revertArchive } = useCrudeApi<TaxBandsPlacement>(`${BASE_URL}/revert-archive`);

  return {
    get,
    getList,
    getAll,
    post,
    put,
    del,
    archive,
    revertArchive
  };
};
