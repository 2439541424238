import { withAddWineStorageOutputService } from './withAddWineStorageOutputService';
import { AddStorageOutputFormPresentation } from './AddStorageOutputFormPresentation';
import React from 'react';

export const AddStorageOutputForm = (props) => {
  const AddStorageOutputFormWithService = withAddWineStorageOutputService(
    AddStorageOutputFormPresentation
  );
  return <AddStorageOutputFormWithService {...props} />;
};
