import React from 'react';
import { GrapevineSummaryPresentationData } from '../../../../../types/dto';
import { useResponsiveContext } from '../../../../platform/ResponsiveContext';
import { useScroll } from '../../../../../hooks/useScroll';
import { GrapevineItem } from './GrapevineItem';
import { SectionWrapper } from '../../../../../layouts/SectionWrapper';
import ContentWrapper from '../../../../../layouts/ContentWrapper';
import { Card } from 'reactstrap';

interface IProps {
  grapevines: GrapevineSummaryPresentationData[];
  totalPlants: number;
  vineyardArea: number;
  loading: boolean;
}

export const VarietiesCard: React.FC<IProps> = ({
  grapevines,
  totalPlants,
  vineyardArea,
  loading
}) => {
  const { isMobile } = useResponsiveContext();
  const { scrollRef, scrollLeft, scrollRight, canScrollLeft, canScrollRight } = useScroll();

  return (
    <div style={{ minHeight: '216px' }}>
      <div>
        <small className={'text-muted float-' + (isMobile ? 'right' : 'left')}>
          Uprawiane odmiany winorośli
        </small>
      </div>
      <br />
      <div className="text-center">
        {!isMobile && canScrollLeft ? (
          <button
            className="page-link position-absolute"
            style={{ zIndex: '1000', top: '35%', opacity: '0.8' }}
            onClick={scrollLeft}
          >
            «
          </button>
        ) : null}
        <table
          className="table-responsive w-100 no-footer"
          ref={scrollRef}
          style={{ maxWidth: grapevines.length * 150 }}
        >
          <tbody>
            <tr className="d-flex flex-row">
              {grapevines.map((grapevine) => {
                return (
                  <td
                    key={grapevine.id}
                    className="d-flex flex-row pr-2"
                    style={{ maxWidth: '150px' }}
                  >
                    <div className="card mr-2 card-hovered" style={{ borderColor: '#37bc9b' }}>
                      <GrapevineItem
                        grapevine={grapevine}
                        totalPlants={totalPlants}
                        vineyardArea={vineyardArea}
                      />
                    </div>
                  </td>
                );
              })}
            </tr>
          </tbody>
        </table>
        {!grapevines.length ? (
          <SectionWrapper loading={loading}>
            <Card>
              <ContentWrapper noBoarder>
                <div className="text-center">
                  <div className={'text-muted'} style={{ height: '183px', paddingTop: '90px' }}>
                    {loading ? null : 'Brak uprawianych winorośli'}
                  </div>
                </div>
              </ContentWrapper>
            </Card>
          </SectionWrapper>
        ) : null}
        {!isMobile && canScrollRight ? (
          <button
            className="page-link position-absolute"
            style={{ zIndex: '1000', top: '35%', right: '0', opacity: '0.8' }}
            onClick={scrollRight}
          >
            »
          </button>
        ) : null}
      </div>
    </div>
  );
};
