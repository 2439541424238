import React from 'react';
import { UpgradePlanInfo } from './UpgradePlanInfo';
import { Subscription, SubscriptionType } from '../../../../types/Subscription';
import { SubscriptionPlan } from '../../../subscription/data/Plans';

export const NormalPlanBody: React.FC<{
  inProgressSubscription: Subscription;
  plans: SubscriptionPlan[];
}> = ({ inProgressSubscription, plans }) => {
  return (
    <div>
      {!inProgressSubscription || inProgressSubscription?.type === SubscriptionType.NORMAL ? (
        <>
          <p>
            Wybierając plan <strong className={'text-gradient-blue-purple'}>Pro</strong>, zyskujesz
            pełen zestaw narzędzi, które umożliwią kompleksowe zarządzanie Twoją winnicą i
            winiarnią. Oprócz podstawowych funkcji, takich jak rejestrowanie zbiorów winogron,
            tworzenie nowych win oraz monitorowanie każdego etapu produkcji i składników, jakie
            oferuje plan Podstawowy, Pro otwiera przed Tobą znacznie więcej możliwości.
          </p>

          <p>
            Dzięki funkcjom z planu{' '}
            <strong className={'text-gradient-blue-purple'}>PRO dla winiarza</strong>, możesz
            tworzyć <strong className={'text-gradient-blue-green'}>nielimitowaną ilość win</strong>,
            śledzić <strong className={'text-gradient-blue-green'}>pełną historię</strong> każdego
            produkowanego wina, a także generować{' '}
            <strong className={'text-gradient-blue-green'}>raporty</strong>, które pomogą Ci lepiej
            zrozumieć i zoptymalizować procesy produkcyjne.
          </p>

          <p>
            Dzięki planowi Pro masz pełną kontrolę nad każdym aspektem produkcji wina – od zbiorów
            do gotowego wina.
          </p>
        </>
      ) : null}
      <UpgradePlanInfo
        plans={plans}
        subscriptionType={SubscriptionType.NORMAL}
        hasInProgressSubscription={!!inProgressSubscription}
      />
    </div>
  );
};
