import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../../../../../components/common/buttons/Button';
import CommonRow from '../../../../../../../components/common/table/CommonRow';
import usePagination from '../../../../../../../hooks/usePagination';
import { PaginationComponent } from '../../../../../../../components/PaginationComponent';
import { TankOutputType } from '../../../types/WineStorage';
import { SectionWrapper } from '../../../../../../../layouts/SectionWrapper';

export const SimpleTankOutputList = ({
  tankOutputs,
  loading,
  removeOutput,
  children,
  title,
  removeDisabled
}) => {
  const { t } = useTranslation();
  const pagination = usePagination(tankOutputs || [], 10);

  function getThead() {
    return [
      `${t('tankOutput.tankNumber')}`,
      `${t('tankOutput.type')}`,
      `${t('tankOutput.date')}`,
      `${t('common.ACTIONS')}`
    ];
  }

  const buildRow = (tankOutput) => {
    const fields = [
      <td className={'text-nowrap'} style={{ textAlign: 'center' }} key={2}>
        {tankOutput?.liters}
        {' l.'}
      </td>,
      <td style={{ textAlign: 'center' }} key={3}>
        {tankOutput?.tank.number}
      </td>,
      <td style={{ textAlign: 'center' }} key={4}>
        {t(`wine.OUTPUT.TYPE.${tankOutput?.outputType}`)}
        {tankOutput.outputType === TankOutputType.BOTTLING ? (
          <div style={{ marginTop: '-10px' }}>
            <small className={'text-muted'}>{` (${tankOutput?.bottleEntry?.quantity} szt.)`}</small>
          </div>
        ) : null}
      </td>,
      <td style={{ textAlign: 'center' }} key={5}>
        {tankOutput?.outputDate}
      </td>,
      <td style={{ textAlign: 'right', width: '60px' }} key={7}>
        <Button.Delete onClick={() => removeOutput(tankOutput)} disabled={removeDisabled} />
      </td>
    ];
    return <tr key={tankOutput.id}>{fields}</tr>;
  };

  return (
    <SectionWrapper loading={loading} hideSpinner>
      <Card>
        <CardBody>
          <h5 className="m-0 float-right">{title}</h5>
          <small className="text-muted">
            {t('common.SUMMARY')}:{' '}
            {tankOutputs?.map((output) => output.liters).reduce((total, item) => total + item, 0)}{' '}
            l.
          </small>
          {pagination.getCurrentResults()?.length ? (
            <div className={'table-responsive'}>
              <table className={'table w-100 no-footer table-hover table-sm'}>
                <tbody>
                  <CommonRow
                    label={`${t('tankOutput.liters')}`}
                    value={getThead()}
                    className={'text-center'}
                  />
                  {(pagination.getCurrentResults() || []).map((item) => buildRow(item))}
                </tbody>
              </table>
            </div>
          ) : (
            <CardBody>
              <div className={'text-center'}>{t('common.NO_DATA')}</div>
            </CardBody>
          )}
          {children}
          <div className={'float-right pt-2'} style={{ marginBottom: '-20px' }}>
            <PaginationComponent pagination={pagination} />
          </div>
        </CardBody>
      </Card>
    </SectionWrapper>
  );
};
