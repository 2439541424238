import React from 'react';
import { SelectOption } from '../../../../../../types/SelectOption';
import { IngredientType } from '../../types/IngredientType';
import useIngredientTypesReceiver from '../../hooks/useIngredientTypesReceiver';
import { ServiceError } from '../../../../../../types/Service';
import { SelectType } from '../../../../../../components/common/form-elements/SelectType';

interface Props {
  value?: IngredientType | SelectOption | null;
  onChange: (name: string, s: Record<string, string>) => void;
  name: string;
  label?: string;
  optional?: boolean;
  error?: ServiceError;
  disabled?: boolean;
}

const SelectIngredientType: React.FC<Props> = ({
  value,
  onChange,
  name,
  label,
  optional,
  error,
  disabled
}) => {
  return (
    <SelectType
      onChange={onChange}
      name={name}
      receiver={useIngredientTypesReceiver}
      error={error}
      optional={optional}
      label={label}
      value={value}
      disabled={disabled}
    />
  );
};

export default SelectIngredientType;
