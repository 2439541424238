import React from 'react';
import { FromApiConverter } from '../../../../../../services/Converters';
import SimpleWineProductionList from './SimpleWineProductionList';
import { useEntityPageReceiver } from '../../../../hooks/pagination/useEntityPageReceiver';
import { FormErrorMessage } from '../../../../../../components/notifications/FormErrorMessage';
import useWineProductionService from '../../hooks/useWineProductionService';
import { useWineProductionContext } from '../../context/WineProductionContext';
import { WineProduction, WineProductionPresentation } from '../../../wine/types/WineProduction';

export const WineProductionList = (props) => {
  const service = useWineProductionService();
  const { setError, error } = useWineProductionContext();
  const { entities, currentPage, pagination, limit, loading, paginationActions, entityActions } =
    useEntityPageReceiver<WineProduction, WineProductionPresentation>(
      service,
      FromApiConverter.convertWineProductionList,
      'wine_production',
      setError
    );

  return (
    <SimpleWineProductionList
      {...props}
      wineProductions={entities}
      page={currentPage}
      pagination={pagination}
      limit={limit}
      loading={loading}
      paginationActions={paginationActions}
      entityActions={entityActions}
    >
      <FormErrorMessage error={error} messageType={'details'} />
    </SimpleWineProductionList>
  );
};
