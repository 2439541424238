import { Error, ServiceError, StatusType } from '../../types/Service';

export class ResponseError implements ServiceError {
  status;
  error: Error;

  constructor(error: Error) {
    this.status = StatusType.error;
    this.error = error;
  }

  hasError = (name: string) => {
    if (this.error?.errors) {
      return Object.keys(this.error?.errors).some((e) => e === name);
    }
    return false;
  };

  getErrorMessage = (name: string) => {
    return this.error?.errors?.[name];
  };

  getErrorDetails = () => {
    return this.error?.details;
  };
}
