import React from 'react';
import { FilesListItemColor } from '../../../types/FileType';

interface IProps extends React.HTMLAttributes<HTMLButtonElement> {
  label: string;
  value: number;
  active: boolean;
  color: FilesListItemColor;
  isLast?: boolean;
}
export const ListItem: React.FC<IProps> = ({ label, value, active, color, isLast, ...props }) => {
  return (
    <button
      {...props}
      className={
        'list-group-item list-group-item-action d-flex justify-content-between align-items-center ' +
        (active ? 'active' : '')
      }
      style={isLast ? { borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px' } : {}}
    >
      <span className={`circle bg-${color} mr-2`} />
      <span>{label}</span>
      <span className="ml-auto badge">{value}</span>
    </button>
  );
};
