import React from 'react';
import WineInfoContainer, { WineInfoButtons } from './WineInfoContainer';
import WineInfoPanel from './WineInfoPanel';

const WineInfo = () => {
  return (
    <WineInfoContainer
      render={(buttons: WineInfoButtons, updateTank, error, showTankSelect, toggleTankSelect) => (
        <WineInfoPanel
          buttons={buttons}
          updateTank={updateTank}
          error={error}
          showTankSelect={showTankSelect}
          toggleTankSelect={toggleTankSelect}
        />
      )}
    />
  );
};

export default WineInfo;
