import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { PlansPage } from './PlansPage';
import NotFound from '../../../../pages/NotFound';

export const PlansApp = () => {
  return (
    <Switch>
      <Route exact path="/mv/plans" component={PlansPage} />
      <Route path="*" component={NotFound} />
    </Switch>
  );
};
