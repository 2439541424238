import React from 'react';
import { withWineEntryToWineProductionServiceHOC } from './withWineEntryToWineProductionServiceHOC';
import { AddWineEntryFormPresentation } from './AddWineEntryFormPresentation';

export const AddWineEntryForm = ({ numberOdWineEntriesInWine }) => {
  const WineEntryFormWithWineEntryToWineProductionService = withWineEntryToWineProductionServiceHOC(
    AddWineEntryFormPresentation
  );

  return (
    <WineEntryFormWithWineEntryToWineProductionService
      numberOdWineEntriesInWine={numberOdWineEntriesInWine}
    />
  );
};
