import React from 'react';
import SimpleParcelList from './SimpleParcelList';
import { withRouter } from 'react-router-dom';
import { FromApiConverter } from '../../../../../../services/Converters';
import useParcelService from '../../hooks/useParcelService';
import { useEntityPageReceiver } from '../../../../hooks/pagination/useEntityPageReceiver';
import { Parcel } from '../../types/Parcel';
import { FormErrorMessage } from '../../../../../../components/notifications/FormErrorMessage';
import { useParcelContext } from '../../context/ParcelContext';

const ParcelList = () => {
  const service = useParcelService();
  const { setError, error } = useParcelContext();
  const { entities, currentPage, pagination, limit, loading, paginationActions, entityActions } =
    useEntityPageReceiver<Parcel, Parcel>(
      service,
      FromApiConverter.convertParcelList,
      'parcel',
      setError
    );

  return (
    <SimpleParcelList
      parcels={entities}
      page={currentPage}
      pagination={pagination}
      limit={limit}
      loading={loading}
      paginationActions={paginationActions}
      entityActions={entityActions}
    >
      <FormErrorMessage error={error} messageType={'details'} />
    </SimpleParcelList>
  );
};

export default withRouter(ParcelList);
