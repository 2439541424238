import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Error500 from '../../pages/Error500';
import NotFound from '../../pages/NotFound';

const ErrorApp = () => {
  return (
    <Switch>
      <Route exact path="/mv/error">
        <Redirect to="/mv/error/404" />
      </Route>
      <Route path="/mv/error/500" component={Error500} />
      <Route path="/mv/error/404" component={NotFound} />
      <Route path="*" component={NotFound} />
    </Switch>
  );
};

export default ErrorApp;
