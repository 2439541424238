import React from 'react';
import { SelectOption } from '../../../../../../types/SelectOption';
import { ServiceError } from '../../../../../../types/Service';
import { SelectType } from '../../../../../../components/common/form-elements/SelectType';
import { WineStorageEntryType } from '../../types/WineStorage';
import { useWineStorageEntryEntityTypeReceiver } from '../../hooks/useWineStorageEntryEntityTypeReceiver';

interface Props {
  value?: WineStorageEntryType | SelectOption | null;
  onChange: (name: string, s: Record<string, string>) => void;
  name: string;
  label?: string;
  optional?: boolean;
  error?: ServiceError;
  disabled?: boolean;
  clazzName?: string;
}

export const SelectStorageEntryType: React.FC<Props> = ({
  value,
  onChange,
  name,
  label,
  optional,
  error,
  disabled,
  clazzName
}) => {
  return (
    <SelectType
      onChange={onChange}
      name={name}
      receiver={useWineStorageEntryEntityTypeReceiver}
      error={error}
      optional={optional}
      label={label}
      value={value}
      disabled={disabled}
      clazzName={clazzName}
    />
  );
};
