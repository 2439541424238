import React from 'react';

const CheckedBadge: React.FC<{ checked: boolean }> = ({ checked }) => {
  return (
    <div className={'p-1'}>
      {checked ? (
        <em className="fa-1x fas fa-check text-info"></em>
      ) : (
        <em className="fa-1x fas fa-minus text-warning"></em>
      )}
    </div>
  );
};

export default CheckedBadge;
