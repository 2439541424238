import React from 'react';
import { WineBoardPresentationData } from '../../../../../types/dto';
import { WineInTankItem } from './WineInTankItem';
import { useResponsiveContext } from '../../../../platform/ResponsiveContext';
import { useScroll } from '../../../../../hooks/useScroll';
import { SectionWrapper } from '../../../../../layouts/SectionWrapper';
import { Card } from 'reactstrap';
import ContentWrapper from '../../../../../layouts/ContentWrapper';

interface IProps {
  wines: WineBoardPresentationData[];
  loading: boolean;
}

export const WinesInTanksCard: React.FC<IProps> = ({ wines, loading }) => {
  const { isMobile } = useResponsiveContext();
  const { scrollRef, scrollLeft, scrollRight, canScrollLeft, canScrollRight } = useScroll();

  return (
    <div style={{ minHeight: '237px' }}>
      <div>
        <small className={'text-muted float-' + (isMobile ? 'right' : 'left')}>
          Aktualnie w zbiornikach
        </small>
      </div>
      <br />
      <div className="text-center">
        {!isMobile && canScrollLeft ? (
          <button
            className="page-link position-absolute"
            style={{ zIndex: '1000', top: '35%', opacity: '0.8' }}
            onClick={scrollLeft}
          >
            «
          </button>
        ) : null}
        <table
          className="table-responsive w-100 no-footer"
          ref={scrollRef}
          style={{ maxWidth: wines.length * 150 }}
        >
          <tbody>
            <tr className="d-flex flex-row">
              {wines.map((wine) => {
                return (
                  <td key={wine.id} className="d-flex flex-row pr-2" style={{ maxWidth: '150px' }}>
                    <div className="card mr-2 card-hovered border-info">
                      <WineInTankItem wine={wine} key={wine.id} />
                    </div>
                  </td>
                );
              })}
            </tr>
          </tbody>
        </table>
        {!wines.length ? (
          <SectionWrapper loading={loading}>
            <Card>
              <ContentWrapper noBoarder>
                <div className="text-center">
                  <div className={'text-muted'} style={{ height: '183px', paddingTop: '90px' }}>
                    {loading ? null : 'Zbiorniki są puste'}
                  </div>
                </div>
              </ContentWrapper>
            </Card>
          </SectionWrapper>
        ) : null}
        {!isMobile && canScrollRight ? (
          <button
            className="page-link position-absolute"
            style={{ zIndex: '1000', top: '35%', right: '0', opacity: '0.8' }}
            onClick={scrollRight}
          >
            »
          </button>
        ) : null}
      </div>
    </div>
  );
};
