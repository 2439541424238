import React from 'react';
import { useTranslation } from 'react-i18next';
import { SummaryGrapevineRow } from './SummaryGrapevineRow';
import { GrapevineSummaryPresentationData } from '../../../../../types/dto';
import { usePushHistory } from '../../../../../hooks/usePushHistory';
import usePagination from '../../../../../hooks/usePagination';
import { PaginationComponent } from '../../../../../components/PaginationComponent';
import { useSearch } from '../../../../../hooks/useSearch';
import { SearchComponent } from '../../../../../components/common/search/SearchComponent';
import { SectionWrapper } from '../../../../../layouts/SectionWrapper';
import { useResponsiveContext } from '../../../../platform/ResponsiveContext';

export const VarietiesListTable: React.FC<{
  grapevines: GrapevineSummaryPresentationData[];
  loading: boolean;
}> = ({ grapevines, loading }) => {
  const { t } = useTranslation();
  const { isMobile } = useResponsiveContext();
  const [result, search] = useSearch<GrapevineSummaryPresentationData>({
    entities: grapevines,
    field: ['name', 'grapeColor']
  });
  const pagination = usePagination(result, 10);

  const { pushHistory } = usePushHistory();

  function navigateToGrapevine(id: number) {
    pushHistory('/mv/grapevine/e/' + id);
  }

  return (
    <SectionWrapper loading={loading} hideSpinner>
      <div className="card">
        <div className="card-body pb-1">
          <small className="text-muted float-right">
            {t('vineyard.board.grapevine.table.title')}
          </small>
          <SearchComponent search={search} />
          <div className="dataTables_wrapper dt-bootstrap4 no-footer">
            <div className="row">
              <div className="col-sm-12 table-responsive">
                <table className="table w-100 no-footer table-hover" style={{ width: 1068 }}>
                  <thead>
                    <tr role="row">
                      <th style={{ width: 215 }}>{t('vineyard.board.grapevine.table.name')}</th>
                      <th style={{ width: 85 }}>{t('vineyard.board.grapevine.table.color')}</th>
                      <th style={{ width: 135 }} className={'text-center'}>
                        {t('vineyard.board.grapevine.table.amount')}
                      </th>
                      {!isMobile ? (
                        <>
                          <th style={{ width: 165 }} className={'text-center'}>
                            {t('vineyard.board.grapevine.table.area')}
                          </th>
                          <th style={{ width: 135 }} className={'text-center'}>
                            {t('vineyard.board.grapevine.table.parcels')}
                          </th>
                          <th style={{ width: 135 }} className={'text-center'}>
                            {t('vineyard.board.grapevine.table.date')}
                          </th>
                        </>
                      ) : null}
                    </tr>
                  </thead>
                  <tbody>
                    {pagination.getCurrentResults().map((grapevine) => {
                      return (
                        <SummaryGrapevineRow
                          color={grapevine.grapeColor}
                          name={grapevine.name}
                          parcels={grapevine.parcelNames}
                          area={grapevine.area}
                          date={grapevine.yearOfPlanting}
                          quantity={grapevine.numberOfPlants}
                          onClick={() => navigateToGrapevine(grapevine.id)}
                          key={grapevine.id}
                          hideFieldsForMobile={isMobile}
                        />
                      );
                    })}
                    {pagination.getCurrentResults().length === 0 ? (
                      <tr>
                        <td colSpan={100} className="text-center">
                          Brak danych
                        </td>
                      </tr>
                    ) : null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <PaginationComponent pagination={pagination} />
      </div>
    </SectionWrapper>
  );
};
