import React, { FC } from 'react';
import {
  IngredientToWIneServiceProps,
  withIngredientServiceHOC
} from '../../applied/shared/withIngredientServiceHOC';
import AddIngredientForm from '../ApplyIngredient/AddIngredientForm';
import { WineProduction } from '../../../../wine/types/WineProduction';

export const ApplyIngredientToWineProduction: FC<IngredientToWIneServiceProps<WineProduction>> = (
  props
) => {
  const AddIngredientFormWithService = withIngredientServiceHOC(AddIngredientForm);
  return <AddIngredientFormWithService {...props} />;
};
