import React from 'react';
import PropTypes from 'prop-types';

/**
 * Wrapper element for template content
 */
const ContentWrapper: React.FC<{
  noBoarder?: boolean;
  children: React.ReactNode;
  unwrap?: boolean;
}> = ({ noBoarder, children, unwrap }) => (
  <div className={'content-wrapper ' + (noBoarder ? 'border-0' : '')}>
    {unwrap ? <div className="unwrap">{children}</div> : children}
  </div>
);

ContentWrapper.propTypes = {
  /** add element with 'unwrap' class to expand content area */
  unwrap: PropTypes.bool
};
ContentWrapper.defaultProps = {
  unwrap: false
};

export default ContentWrapper;
