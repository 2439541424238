import React, { FC } from 'react';
import {
  DisposedWineEntriesListBoxContainer,
  WineEntriesListBoxProps
} from './DisposedWineEntriesListBoxContainer';
import DisposedWineEntriesListBoxPresentation from './DisposedWineEntriesListBoxPresentation';

export const DisposedWineEntriesListBox: FC<WineEntriesListBoxProps> = (props) => {
  return (
    <DisposedWineEntriesListBoxContainer
      {...props}
      render={(wineEntries, eventToShow, actions) => (
        <DisposedWineEntriesListBoxPresentation
          wineEntries={wineEntries}
          elementToShow={eventToShow}
          actions={actions}
        />
      )}
    />
  );
};
