import React from 'react';
import { Card, CardBody, Table } from 'reactstrap';
import { Trans, useTranslation } from 'react-i18next';
import { FromApiConverter } from '../../../../../../services/Converters';
import PageWrapper from '../../../../../../layouts/PageWrapper';
import ListActions from '../../../../../../components/common/ListActions';
import Pagination from '../../../../../../components/common/pagination/Pagination';
import { useParams } from 'react-router-dom';
import { EntityLiveStatus } from '../../../../../../components/common/enums/EntityLiveStatus';
import { usePushHistory } from '../../../../../../hooks/usePushHistory';
import { SectionWrapper } from '../../../../../../layouts/SectionWrapper';

const thead = [
  <th style={{ textAlign: 'center' }} key={2}>
    <Trans i18nKey="grapevine.NAME" />
  </th>,
  <th style={{ textAlign: 'center' }} key={3}>
    <Trans i18nKey="grapevine.NUMBER_OF_PLANTS" />
  </th>,
  <th style={{ textAlign: 'center' }} key={4}>
    <Trans i18nKey="grapevine.AREA" />
  </th>,
  <th style={{ textAlign: 'center' }} key={5}>
    <Trans i18nKey="grapevine.YEAR_OF_PLANTING" />
  </th>,
  <th style={{ textAlign: 'center' }} key={6}>
    <Trans i18nKey="grapevine.GRAPE_COLOR" />
  </th>,
  <th style={{ textAlign: 'center' }} key={7}>
    <Trans i18nKey="common.ACTIONS" />
  </th>
];

const SimpleGrapevineList = ({
  grapevines,
  page,
  pagination,
  limit,
  loading,
  paginationActions: { changePage, onPrev, onNext },
  entityActions: { remove, proceed, archive, revertArchive },
  children
}) => {
  const { status } = useParams();
  const { t } = useTranslation();
  const { pushHistory } = usePushHistory();

  function pushToBoard() {
    pushHistory(`/mv/board/summary`);
  }

  const createTHead = () => {
    return (
      <thead>
        <tr>{thead.filter((t, index) => index < limit || index === thead.length - 1)}</tr>
      </thead>
    );
  };

  const buildRow = (grapevine) => {
    const fields = [
      <td style={{ textAlign: 'center' }} key={2}>
        {grapevine?.name}
      </td>,
      <td style={{ textAlign: 'center' }} key={3}>
        {grapevine?.numberOfPlants}
      </td>,
      <td style={{ textAlign: 'center' }} key={4}>
        {grapevine?.area}
      </td>,
      <td style={{ textAlign: 'center' }} key={5}>
        {grapevine?.yearOfPlanting}
      </td>,
      <td style={{ textAlign: 'center' }} key={6}>
        <Trans i18nKey={FromApiConverter.convertGrapeColor(grapevine?.grapeColor)} />
      </td>,
      <td style={{ textAlign: 'center' }} key={7}>
        <ListActions
          entity={grapevine}
          actions={{
            remove: remove,
            proceed: proceed,
            archive: archive,
            revertArchive: revertArchive
          }}
          status={status}
        />
      </td>
    ];
    return (
      <tr key={grapevine?.id}>
        {fields.filter((t, index) => index < limit || index === thead.length - 1)}
      </tr>
    );
  };

  return (
    <PageWrapper title={'grapevine.TITLE'} onClick={pushToBoard} returnText={'home.summary.TITLE'}>
      <SectionWrapper loading={loading}>
        <Card>
          <CardBody>
            <h5 className="float-right">
              {status === EntityLiveStatus.ARCHIVED.toLowerCase()
                ? t('grapevine.list.archived.TITLE')
                : t('grapevine.list.created.TITLE')}
            </h5>
            <Table hover>
              {createTHead()}
              <tbody>
                {(grapevines || []).map((grapevine) => buildRow(grapevine))}
                {grapevines && grapevines.length === 0 && (
                  <tr>
                    <td style={{ textAlign: 'center' }} colSpan={'100%'}>
                      <Trans i18nKey="common.NO_DATA" />
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
            {children}
            {pagination.totalPages > 1 && (
              <Pagination
                page={page}
                pagination={pagination}
                actions={{
                  changePage: changePage,
                  prev: onPrev,
                  next: onNext
                }}
              />
            )}
          </CardBody>
        </Card>
      </SectionWrapper>
    </PageWrapper>
  );
};

export default SimpleGrapevineList;
