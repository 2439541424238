import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import log from 'loglevel';
import { useTranslation } from 'react-i18next';
import { PdfPageLayout } from '../../../../types/enums';
import { AuthContext } from '../../../platform/AuthContext';
import { useResponsiveContext } from '../../../platform/ResponsiveContext';
import usePlanPicker from '../../../subscription/hooks/usePlanPicker';
import { SubscriptionType } from '../../../../types/Subscription';
import { LockIconInfo } from '../../../../components/common/lock/LockIconInfo';

export const StorageWinesListButtonsBar: React.FC<{ reportAvailable: boolean }> = ({
  reportAvailable
}) => {
  const { t } = useTranslation();

  const {
    subscriptionInfo: { accessInfo }
  } = useContext(AuthContext);
  const { isMobile } = useResponsiveContext();

  const { isValidSubscription } = usePlanPicker({
    subscriptionType: SubscriptionType.PREMIUM,
    fromBlockingContent: true,
    blockingMessage: 'Przejdź na wyższy plan, aby pobrać ten raport',
    itemIndexToHighlight: 6
  });

  const { isValidSubscription: isValidSubscriptionForAddWine } = usePlanPicker({
    subscriptionType: SubscriptionType.NORMAL,
    fromBlockingContent: true,
    blockingMessage: 'Przejdź na wyższy plan, aby dodać wino',
    itemIndexToHighlight: 7
  });

  function getPdf(layout = PdfPageLayout.LANDSCAPE) {
    if (!isValidSubscription()) {
      return;
    }
    log.debug('GET_PDF');
    window.location.href = `/ajax/pdf/STORAGE_SUMMARY?layout=${layout}`;
  }

  function handleAddWine(e: React.MouseEvent) {
    if (!isValidSubscriptionForAddWine()) {
      e.preventDefault();
    }
  }

  return (
    <div className="mb-3">
      <div>
        <Link
          to={{
            pathname: '/mv/wine_storage/e/0',
            state: { from: '/mv/board/storage' }
          }}
          className="btn btn-sm btn-info"
          type="button"
          onClick={handleAddWine}
        >
          <em className="mr-1 fas fa-plus" /> wino
          <LockIconInfo subscriptionTypeNeeded={SubscriptionType.NORMAL} accessInfo={accessInfo} />
        </Link>
        <Link
          to={'/mv/wine_storage/all'}
          className="btn btn-sm btn-secondary"
          type="button"
          title={t('button.show.list')}
        >
          <em className="fas fa-outdent" />
        </Link>
        {reportAvailable ? (
          <button
            className="btn btn-sm btn-secondary ml-1 float-right"
            type="button"
            onClick={() => getPdf()}
            title={t('button.download.pdf')}
          >
            <em className="ml-auto fa fa-download fa-fw text-info"></em>
            {!isMobile ? <span className={'ml-1'}>{t('button.download.report')}</span> : null}
            <LockIconInfo
              accessInfo={accessInfo}
              subscriptionTypeNeeded={SubscriptionType.PREMIUM}
            />
          </button>
        ) : null}
      </div>
    </div>
  );
};
