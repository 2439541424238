import { Ingredient } from '../../../../../ingredient/types/Ingredient';
import { FC } from 'react';
import { useCRUDListBoxContainer } from '../shared/useCRUDListBoxContainer';
import { useWineContext } from '../../../../context/WineContext';
import { Wine } from '../../../../types/Wine';

export interface WineIngredientsListBoxProps {
  ingredients: Ingredient[];
  delIngredient: (ingredientId: number) => void;
  disabledActions?: boolean;
  entityId?: number;
  loading: boolean;
}

type Props = WineIngredientsListBoxProps & {
  render: (list, elementToShow, actions) => JSX.Element;
};

export const WineIngredientsListBoxContainer: FC<Props> = ({
  render,
  ingredients,
  delIngredient
}) => {
  const { setWineResult, setError } = useWineContext();
  const { list, elementToShow, actions } = useCRUDListBoxContainer<Wine, Ingredient>(
    ingredients,
    delIngredient,
    setWineResult,
    setError
  );

  const selectedActions = {
    editElement: actions.editElement,
    removeElement: actions.removeElement,
    toggleShow: actions.toggleShow
  };

  return render(list, elementToShow, selectedActions);
};
