import { useState } from 'react';
import { useUpdateEffect } from './useUpdateEffect';
import { Pagination } from '../types/Pagination';

function usePagination<T>(data: T[] = [], itemsPerPage: number): Pagination<T> {
  const [currentPage, setCurrentPage] = useState(1);
  const maxPage = itemsPerPage < 0 ? 1 : Math.ceil(data.length / itemsPerPage);

  function getCurrentResults(): T[] {
    if (itemsPerPage < 0) {
      return data;
    }
    const begin = (currentPage - 1) * itemsPerPage;
    const end = begin + itemsPerPage;
    return data.slice(begin, end);
  }

  useUpdateEffect(() => {
    if (currentPage > maxPage) {
      jump(maxPage);
    }
    if (currentPage === 0) {
      jump(1);
    }
  }, [itemsPerPage, currentPage, maxPage]);

  function next() {
    setCurrentPage((currentPage) => Math.min(currentPage + 1, maxPage));
  }

  function prev() {
    setCurrentPage((currentPage) => Math.max(currentPage - 1, 1));
  }

  function jump(page: number) {
    const pageNumber = Math.max(1, page);
    setCurrentPage(() => Math.min(pageNumber, maxPage));
  }

  return { next, prev, jump, getCurrentResults, currentPage, maxPage };
}

export default usePagination;
