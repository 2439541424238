import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { WineStorageList } from './components/WineStorageList';
import WineStorageProvider from './context/WineStorageContext';
import { WineStorageForm } from './components/WineStorageForm';
import { WineStorageInfo } from './components/WineStorageInfo';
import { AddTaxBandsPlacementForm } from './components/WineStorageInfo/AddTaxBandsPlacementForm';

export const WineStorageApp = () => {
  return (
    <WineStorageProvider>
      <Switch>
        <Route exact path="/mv/wine_storage">
          <Redirect to="/mv/wine_storage/all" />
        </Route>
        <Route exact path="/mv/wine_storage/all" component={WineStorageList} />
        <Route exact path="/mv/wine_storage/:status?" component={WineStorageList} />
        <Route exact path="/mv/wine_storage/e/:wineStorageId?" component={WineStorageForm} />
        <Route
          exact
          path="/mv/wine_storage/tax_band_placement/:wineStorageId?/:taxBandsPlacementId?"
          component={AddTaxBandsPlacementForm}
        />
        <Route exact path="/mv/wine_storage/info/:wineStorageId?" component={WineStorageInfo} />
      </Switch>
    </WineStorageProvider>
  );
};
