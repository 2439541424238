import { AccountHeaderInfo } from './AccountHeaderInfo';
import React, { useState } from 'react';
import useAccountService from '../hooks/useAccountService';
import { useAuthContext } from '../../platform/AuthContext';
import { useTranslation } from 'react-i18next';
import { useConfirmation } from '../../notifications/useConfirmation';
import { usePushHistory } from '../../../hooks/usePushHistory';
import { SectionWrapper } from '../../../layouts/SectionWrapper';

export const AccountTab: React.FC<{ setLoading: (val: boolean) => void; loading: boolean }> = ({
  setLoading,
  loading
}) => {
  const {
    subscriptionInfo: { activeSubscription: subscription }
  } = useAuthContext();
  const service = useAccountService();
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [error, setError] = useState('');
  const [removeAccountError, setRemoveAccountError] = useState('');
  const [success, setSuccess] = useState(false);
  const { t } = useTranslation();

  const showConfirmation = useConfirmation();

  const { pushHistory } = usePushHistory();

  function resetPasswordDisabled() {
    return !(oldPassword && newPassword && newPassword === confirmNewPassword);
  }

  function resetPassword() {
    if (!resetPasswordDisabled()) {
      setLoading(true);
      setError('');
      setSuccess(false);
      service
        .updatePassword({ newPassword, oldPassword })
        .then(() => {
          setSuccess(true);
          setOldPassword('');
          setNewPassword('');
          setConfirmNewPassword('');
        })
        .catch((response) => setError(response.errors.newPassword || response.errors.oldPassword))
        .finally(() => setLoading(false));
    }
  }

  const removeAccount = (password: string) => {
    showConfirmation({
      message: t('alerts.confirmation.REMOVE_ACCOUNT'),
      actionLabel: 'Usuń konto',
      actionCallback: () => handleRemoveAccount(password)
    });
  };

  function handleRemoveAccount(password: string) {
    setLoading(true);
    service
      .removeAccount({ password })
      .then((res) => {
        if (res) {
          pushHistory('/welcome/login');
        }
      })
      .catch((response) => setRemoveAccountError(response.errors.password))
      .finally(() => setLoading(false));
  }

  return (
    <SectionWrapper loading={loading}>
      <div className="card">
        <AccountHeaderInfo subscription={subscription} title={'Konto'} />
        <div className="card-body">
          <form action="#">
            <div className="form-group">
              <label>Obecne hasło</label>
              <input
                className="form-control"
                type="password"
                onChange={(e) => setOldPassword(e.target.value)}
                key={success ? '5' : '6'}
              />
            </div>
            <div className="form-group">
              <label>Nowe hasło</label>
              <input
                className="form-control"
                type="password"
                onChange={(e) => setNewPassword(e.target.value)}
                key={success ? '1' : '2'}
              />
            </div>
            <div className="form-group">
              <label>Powtórz nowe hasło</label>
              <input
                className="form-control required error"
                type="password"
                onChange={(e) => setConfirmNewPassword(e.target.value)}
                key={success ? '3' : '4'}
              />
            </div>
            {error ? (
              <p>
                <small className="text-danger">
                  {error.split(',').map((message, index) => (
                    <>
                      <span key={index}>{message}</span>
                      <br />
                    </>
                  ))}
                </small>
              </p>
            ) : null}
            {success ? (
              <p>
                <small className="text-success">Hasło zostało prawidłowo zmienione</small>
              </p>
            ) : null}
            <button
              className="btn btn-info"
              type="button"
              onClick={resetPassword}
              disabled={resetPasswordDisabled()}
            >
              Zaktualizuj hasło
            </button>
          </form>
        </div>
      </div>
      <div className="card">
        <div
          className="card-header bg-danger text-bold"
          style={{
            borderTopLeftRadius: '10px',
            borderTopRightRadius: '10px'
          }}
        >
          Usuń konto
        </div>
        <div className="card-body bt">
          <p>
            Podaj hasło i naciśnij przycisk. Następnie zostaniesz poproszony o potwierdzenie swojej
            decyzji.
          </p>
          <div className="form-group">
            <label>Hasło</label>
            <input
              className="form-control"
              type="password"
              onChange={(e) => setPassword(e.target.value)}
              key={success ? '7' : '8'}
            />
            {removeAccountError ? (
              <small className="text-danger">
                {removeAccountError.split(',').map((message, index) => (
                  <>
                    <span key={index}>{message}</span>
                    <br />
                  </>
                ))}
              </small>
            ) : null}
          </div>
          <button
            className="btn btn-secondary"
            type="button"
            onClick={() => removeAccount(password)}
            disabled={!password.length}
          >
            <span className="text-danger">Usuń konto</span>
          </button>
        </div>
      </div>
    </SectionWrapper>
  );
};
