import React from 'react';
import { Card, CardBody, Table } from 'reactstrap';
import { Trans, useTranslation } from 'react-i18next';
import Pagination from '../../../../../../components/common/pagination/Pagination';
import ListActions from '../../../../../../components/common/ListActions';
import PageWrapper from '../../../../../../layouts/PageWrapper';
import { useParams } from 'react-router-dom';
import { EntityLiveStatus } from '../../../../../../components/common/enums/EntityLiveStatus';
import { usePushHistory } from '../../../../../../hooks/usePushHistory';
import { SectionWrapper } from '../../../../../../layouts/SectionWrapper';

const thead = [
  <th style={{ textAlign: 'center' }} key={1}>
    #
  </th>,
  <th style={{ textAlign: 'center' }} key={2}>
    <Trans i18nKey="wine.NAME" />
  </th>,
  <th style={{ textAlign: 'center' }} key={3}>
    <Trans i18nKey="wine.DATE" />
  </th>,
  <th style={{ textAlign: 'center' }} key={4}>
    <Trans i18nKey="wine.TANK_NUMBER" />
  </th>,
  <th style={{ textAlign: 'center' }} key={5}>
    <Trans i18nKey="wine.TANK_CAPACITY" />
  </th>,
  <th style={{ textAlign: 'center' }} key={6}>
    <Trans i18nKey="wine.LITERS" />
  </th>,
  <th style={{ textAlign: 'center' }} key={7}>
    <Trans i18nKey="common.ACTIONS" />
  </th>
];

const SimpleWineList = ({
  wines,
  page,
  pagination,
  limit,
  loading,
  paginationActions: { changePage, onPrev, onNext },
  entityActions: { remove, proceed, info, archive, revertArchive },
  wrapperDisabled,
  addWine,
  reloadHarvest,
  children,
  title,
  harvest
}) => {
  const { status } = useParams();
  const { t } = useTranslation();

  const { pushHistory } = usePushHistory();

  function pushToBoard() {
    pushHistory(`/mv/board/production`);
  }

  const createTHead = () => {
    return (
      <thead>
        <tr>{thead.filter((t, index) => index < limit || index === thead.length - 1)}</tr>
      </thead>
    );
  };

  const buildRow = (wine) => {
    const fields = [
      <td style={{ textAlign: 'center' }} key={1}>
        {wine?.id}
      </td>,
      <td style={{ textAlign: 'center' }} key={2}>
        {wine?.name}
      </td>,
      <td style={{ textAlign: 'center' }} key={3}>
        {wine?.startDate}
      </td>,
      <td style={{ textAlign: 'center' }} key={4}>
        {wine?.tankNumber}
      </td>,
      <td style={{ textAlign: 'center' }} key={5}>
        {wine?.tankCapacity}
      </td>,
      <td style={{ textAlign: 'center' }} key={6}>
        {wine?.liters}
      </td>,
      <td style={{ textAlign: 'center' }} key={7}>
        <ListActions
          entity={wine}
          actions={{
            remove: remove,
            proceed: proceed,
            info: info,
            archive: archive,
            revertArchive: revertArchive
          }}
          triggerRemoveCallback={reloadHarvest}
        />
      </td>
    ];
    return (
      <tr key={wine.id}>
        {fields.filter((t, index) => index < limit || index === thead.length - 1)}
      </tr>
    );
  };

  return (
    <PageWrapper
      title={'wine.TITLE'}
      onClick={pushToBoard}
      disabled={wrapperDisabled}
      returnText={'home.TITLE'}
    >
      <SectionWrapper loading={loading}>
        <Card>
          <CardBody>
            <h5 className="float-right">
              {title ||
                (status === EntityLiveStatus.ARCHIVED.toLowerCase()
                  ? t('wine.list.archived.TITLE')
                  : t('wine.list.created.TITLE'))}
            </h5>
            <Table hover>
              {createTHead()}
              <tbody>
                {(wines || []).map((harvest) => buildRow(harvest))}
                {wines && wines.length === 0 && (
                  <tr>
                    <td style={{ textAlign: 'center' }} colSpan={'100%'}>
                      <Trans i18nKey="common.NO_DATA" />
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
            {children}
            {pagination.totalPages > 1 && (
              <Pagination
                page={page}
                pagination={pagination}
                actions={{
                  changePage: changePage,
                  prev: onPrev,
                  next: onNext
                }}
              />
            )}
          </CardBody>
          {addWine && !harvest.allDisposedToWine && (
            <div className="card-footer text-center">
              <button type="button" className="btn btn-info btn-square" onClick={addWine}>
                <Trans i18nKey="button.MAKE_WINE" />
              </button>
            </div>
          )}
        </Card>
      </SectionWrapper>
    </PageWrapper>
  );
};

export default SimpleWineList;
