import React from 'react';
import { SelectOption } from '../../../../types/SelectOption';
import { ServiceError } from '../../../../types/Service';
import { SelectType } from '../../../../components/common/form-elements/SelectType';
import { SubscriptionPeriod } from '../../../../types/Subscription';
import { useSubscriptionPeriodReceiver } from '../../hooks/useSubscriptionPeriodReceiver';
import { SectionWrapper } from '../../../../layouts/SectionWrapper';

interface Props {
  value?: SubscriptionPeriod | SelectOption | null;
  onChange: (name: string, s: Record<string, string>) => void;
  name: string;
  label?: string;
  optional?: boolean;
  error?: ServiceError;
  disabled?: boolean;
  loading?: boolean;
}

const SelectSubscriptionPeriod: React.FC<Props> = ({
  value,
  onChange,
  name,
  label,
  optional,
  error,
  disabled,
  loading
}) => {
  return (
    <SectionWrapper loading={loading}>
      <SelectType
        onChange={onChange}
        name={name}
        receiver={useSubscriptionPeriodReceiver}
        error={error}
        optional={optional}
        label={label}
        value={value}
        disabled={disabled}
        clazzName={'pb-0 mb-0'}
      />
    </SectionWrapper>
  );
};

export default SelectSubscriptionPeriod;
