import React from 'react';
import { FromApiConverter } from '../../../../../../services/Converters';
import SimpleGrapevineList from './SimpleGrapevineList';
import useGrapevineService from '../../hooks/useGrapevineService';
import { useEntityPageReceiver } from '../../../../hooks/pagination/useEntityPageReceiver';
import { Grapevine } from '../../types/Grapevine';
import { useGrapevineContext } from '../../context/GrapevineContext';
import { FormErrorMessage } from '../../../../../../components/notifications/FormErrorMessage';

const GrapevineList = () => {
  const service = useGrapevineService();
  const { setError, error } = useGrapevineContext();
  const { entities, currentPage, pagination, limit, loading, paginationActions, entityActions } =
    useEntityPageReceiver<Grapevine, Grapevine>(
      service,
      FromApiConverter.convertGrapevineList,
      'grapevine',
      setError
    );

  return (
    <SimpleGrapevineList
      grapevines={entities}
      page={currentPage}
      pagination={pagination}
      limit={limit}
      loading={loading}
      paginationActions={paginationActions}
      entityActions={entityActions}
    >
      <FormErrorMessage error={error} messageType={'details'} />
    </SimpleGrapevineList>
  );
};

export default GrapevineList;
