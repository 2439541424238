import { FC } from 'react';
import { WineEntry, WineProduction } from '../../../wine/types/WineProduction';
import { useCRUDListBoxContainer } from '../../../wine/components/WineInfo/list-box/shared/useCRUDListBoxContainer';
import { useWineProductionContext } from '../../context/WineProductionContext';

export interface WineEntriesListBoxProps {
  wineEntries: WineEntry[];
  removeWineEntries: (wineEntryIdId: number) => void;
}

type Props = WineEntriesListBoxProps & { render: (list, elementToShow, actions) => JSX.Element };

export const WineEntriesListBoxContainer: FC<Props> = ({
  render,
  wineEntries,
  removeWineEntries
}) => {
  const { setWineProductionResult, setError } = useWineProductionContext();
  const { list, elementToShow, actions } = useCRUDListBoxContainer<WineProduction, WineEntry>(
    wineEntries,
    removeWineEntries,
    setWineProductionResult,
    setError
  );

  const selectedActions = {
    infoElement: actions.infoElement,
    removeElement: actions.removeElement,
    toggleShow: actions.toggleShow
  };

  return render(list, elementToShow, selectedActions);
};
