import React, { FC } from 'react';
import { DeleteButton } from './DeleteButton';
import { EditButton } from './EditButton';
import { InfoButton } from './InfoButton';

interface Props {
  onClick: () => void;
  className: string;
  icon?: string;
  label?: string;
  loading?: string;
  disabled?: boolean;
}

type ButtonComponent = FC<Props>;
type DeleteComponent = FC<{ onClick: () => void; disabled?: boolean }>;
type EditComponent = FC<{ onClick: () => void }>;
type InfoComponent = FC<{ onClick: () => void }>;

export const Button: ButtonComponent & { Delete: DeleteComponent } & { Edit: EditComponent } & {
  Info: InfoComponent;
} = ({ onClick, className, icon, label, loading, disabled }) => {
  return (
    <button
      type="button"
      className={'btn btn-sm mr-1 ' + (className ? className : 'btn-primary')}
      onClick={onClick}
      disabled={disabled}
    >
      {icon && <em className={'fa fa-fw ' + icon} />}
      {loading ? (
        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
      ) : (
        <span>{label}</span>
      )}
    </button>
  );
};

Button.Delete = DeleteButton;
Button.Edit = EditButton;
Button.Info = InfoButton;
