import React from 'react';
import { GrapeColor } from '../../../../winery/components/grapevine/types/GrapeColor';

interface IProps {
  color: GrapeColor;
  name: string;
  parcels: string;
  area: number;
  date: string;
  quantity: number;
  onClick: () => void;
  hideFieldsForMobile: boolean;
}

export const SummaryGrapevineRow: React.FC<IProps> = ({
  color,
  name,
  parcels,
  area,
  date,
  quantity,
  onClick,
  hideFieldsForMobile
}) => {
  return (
    <tr className="odd parent" style={{ cursor: 'pointer' }} onClick={onClick}>
      <td className={'text-bold'}>{name}</td>
      <td className="dtr-control sorting_1" tabIndex={0}>
        <div
          className={'badge bg-' + ('WHITE' === color.valueOf() ? 'yellow-light' : 'danger-light')}
        >
          {'WHITE' === color.valueOf() ? 'białe' : 'czerwone'}
        </div>
      </td>
      <td className={'text-center'}>{quantity}</td>
      {hideFieldsForMobile ? null : (
        <>
          <td className={'text-center'}>{area} ha</td>
          <td className={'text-center'}>{parcels}</td>
          <td className={'text-center'}>{date}</td>
        </>
      )}
    </tr>
  );
};
