import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import usePagination from '../../../../hooks/usePagination';
import { usePushHistory } from '../../../../hooks/usePushHistory';
import { UserData, UserInfoData } from '../../types';
import { PaginationComponent } from '../../../../components/PaginationComponent';
import { UserRow } from './UserRow';
import { useParams } from 'react-router-dom';
import { RouterParams } from '../../../../types/RouterParams';
import { Button, CardBody } from 'reactstrap';
import InputElement from '../../../../components/common/InputElement';
import { useToggle } from '../../../../hooks/useToggle';
import { SectionWrapper } from '../../../../layouts/SectionWrapper';

interface IProps {
  users: UserInfoData[];
  loading: boolean;
  actions?: {
    deactivateUser: (id: number) => void;
    activateUser: (id: number) => void;
    addNewUser: (user: UserData) => void;
  };
}

export const UsersTable: React.FC<IProps> = ({ users, loading, actions }) => {
  const { t } = useTranslation();
  const pagination = usePagination(users, 10);
  const { companyId } = useParams<RouterParams>();
  const [showForm, toggleShowForm] = useToggle(false);
  const [newUser, setNewUser] = useState<UserData>({ name: '', login: '' });
  const [submitting, setSubmitting] = useState(false);

  const { pushHistory } = usePushHistory();

  function navigateToCompany(id: number) {
    pushHistory(`/admin_console/company/${id}`);
  }

  function handleUpdate(e: React.ChangeEvent<HTMLInputElement>) {
    const name = e.target.name;
    const newValue = e.target.value;
    setNewUser((prev) => ({ ...prev, [name]: newValue }));
  }

  async function submitUser() {
    setSubmitting(true);
    await actions.addNewUser(newUser);
    setSubmitting(false);
    toggleShowForm(false);
  }

  return (
    <SectionWrapper loading={loading}>
      <div className="mb-3">
        <div>
          <button className="btn btn-sm btn-info" type="button" onClick={() => toggleShowForm()}>
            <em className={'mr-2 fas fa-' + (showForm ? 'minus' : 'plus')} /> użytkownika
          </button>
        </div>
      </div>
      {showForm ? (
        <SectionWrapper loading={submitting}>
          <div className="card">
            <div className="card-body pb-1">
              <small className="text-muted float-right">{t('users.add.form.table.title')}</small>
              <div className="dataTables_wrapper dt-bootstrap4 no-footer">
                <CardBody>
                  <InputElement
                    label={t('users.add.form.table.name')}
                    name={'name'}
                    onChange={handleUpdate}
                  />
                  <InputElement
                    label={t('users.add.form.table.login')}
                    name={'login'}
                    onChange={handleUpdate}
                  />
                </CardBody>
                <div className="text-center mb-2">
                  <Button color="info" className="btn-square" onClick={submitUser}>
                    {t('common.ADD')}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </SectionWrapper>
      ) : null}
      <div className="card">
        <div className="card-body pb-1">
          <small className="text-muted float-right">{t('users.table.title')}</small>
          {/*<div className="float-left">*/}
          {/*	<label>*/}
          {/*		/!*<em className="fas fa-search"/>*!/*/}
          {/*		<input*/}
          {/*			type="search"*/}
          {/*			className="form-control form-control-sm"*/}
          {/*			placeholder="Szukaj"*/}
          {/*		/>*/}
          {/*	</label>*/}
          {/*</div>*/}
          <div className="dataTables_wrapper dt-bootstrap4 no-footer">
            <div className="row">
              <div className="col-sm-12 table-responsive">
                <table className="table w-100 no-footer table-hover table-sm">
                  <thead>
                    <tr role="row">
                      <th style={{ width: 50 }}>{t('companies.table.id')}</th>
                      <th style={{ width: 55 }}>{t('users.table.active')}</th>
                      <th>{t('users.table.name')}</th>
                      {companyId ? null : <th>{t('users.table.companyName')}</th>}
                      <th>{t('users.table.lastLogin')}</th>
                      <th>{t('users.table.roles')}</th>
                      <th>{t('users.table.login')}</th>
                      <th>{t('users.table.created')}</th>
                      {companyId ? null : <th>{t('users.table.actions')}</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {pagination.getCurrentResults().map((user) => {
                      return (
                        <UserRow
                          user={user}
                          onClick={() => navigateToCompany(user.companyId)}
                          actions={actions}
                          key={user.id}
                        />
                      );
                    })}
                    {pagination.getCurrentResults().length === 0 ? (
                      <tr>
                        <td colSpan={100} className="text-center">
                          Brak danych
                        </td>
                      </tr>
                    ) : null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <PaginationComponent pagination={pagination} />
      </div>
    </SectionWrapper>
  );
};
