import React from 'react';
import { WineProductionFormPresentation } from './WineProductionFormPresentation';
import { withWineProductionFromServiceHOC } from './withWineProductionFromServiceHOC';

export const WineProductionForm = () => {
  const WineProductionFormWithService = withWineProductionFromServiceHOC(
    WineProductionFormPresentation
  );

  return <WineProductionFormWithService />;
};
