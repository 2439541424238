import React from 'react';
import { withAddTaxBandsPlacementService } from './withAddTaxBandsPlacementService';
import { AddTaxBandsPlacementFormPresentation } from './AddTaxBandsPlacementPresentation';

export const AddTaxBandsPlacementForm = () => {
  const AddTaxBandsPlacementFormWithService = withAddTaxBandsPlacementService(
    AddTaxBandsPlacementFormPresentation
  );
  return <AddTaxBandsPlacementFormWithService />;
};
