import { TService, useFetchEntityPage } from './useFetchEntityPage';
import { useLimitedRowsToDimension } from './useLimitedRowsToDimension';
import { useEntityActions } from './useEntityActions';
import { Service, ServiceError } from '../../../../types/Service';

export const useEntityPageReceiver = <T, RETURN_TYPE>(
  service: TService<T>,
  listConverter: (entity: T[]) => T[],
  entityName: string,
  setError: (value: ServiceError) => void,
  setResult?: (value: Service<RETURN_TYPE>) => void,
  triggerReload?: boolean
) => {
  const {
    pagination,
    paginationActions,
    loading,
    removeEntity,
    currentPage,
    archiveEntity,
    revertArchiveEntity,
    reload
  } = useFetchEntityPage({ service, setError, setResult, triggerReload });
  const { limit } = useLimitedRowsToDimension();
  const { proceed, info } = useEntityActions(entityName);

  const entityActions = {
    remove: removeEntity,
    proceed: proceed,
    info: info,
    archive: archiveEntity,
    revertArchive: revertArchiveEntity
  };

  const entities = pagination.content ? listConverter(pagination.content) : [];

  return {
    entities,
    currentPage,
    pagination,
    limit,
    loading,
    paginationActions,
    entityActions,
    reload
  };
};
