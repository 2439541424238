import useCrudeApi from '../../../../../lib/useCrudeApi';
import useFetch from '../../../../../lib/useFetch';
import { useParams } from 'react-router-dom';
import { Ingredient } from '../../ingredient/types/Ingredient';
import { WineEntry, WineProduction } from '../../wine/types/WineProduction';
import { ProductionEvent } from '../../wine/types/Wine';
import { Tank } from '../../tank/types/Tank';
import { RouterParams } from '../../../../../types/RouterParams';
import { ListMode } from '../../../../../types/enums';

const useWineProductionService = () => {
  const BASE_URL = '/ajax/wine_productions';

  const { wineProductionId } = useParams<RouterParams>();
  const crudeApi = useCrudeApi<WineProduction>(`${BASE_URL}`);
  const crudeApiForPresentation = useCrudeApi<WineProduction>(`${BASE_URL}/select`);
  const eventWineProductionCrudApi = useCrudeApi<ProductionEvent>(
    `${BASE_URL}/${wineProductionId}/event`
  );
  const wineEntryCrudApi = useCrudeApi<ProductionEvent>(
    `${BASE_URL}/wine_entry/${wineProductionId}`
  );

  const { get, post, put, del, getList, getAll } = crudeApi;

  const getListWithPositiveLiters = (excludeId = wineProductionId) => {
    if (excludeId) {
      return crudeApiForPresentation.getAllWithParams(
        new Map<string, string>([
          ['mode', ListMode.WITH_LITERS],
          ['excludeId', excludeId]
        ])
      );
    }
    return crudeApiForPresentation.getAllWithParams(
      new Map<string, string>([['mode', ListMode.WITH_LITERS]])
    );
  };

  const { putBody: addIngredient, del: delIngredient } = useFetch<Ingredient>(
    `${BASE_URL}/${wineProductionId}/ingredient`
  );
  const { putId: archive } = useCrudeApi<WineProduction>(`${BASE_URL}/archive`);
  const { putId: revertArchive } = useCrudeApi<WineProduction>(`${BASE_URL}/revert-archive`);

  const { putBody: addWineEntry } = useCrudeApi<WineEntry>(
    `${BASE_URL}/wine_entry/${wineProductionId}`
  );
  const { get: getWineEntry } = useCrudeApi<WineEntry>(`${BASE_URL}/${wineProductionId}`);

  const { put: putIngredient } = useCrudeApi<Ingredient>(
    `${BASE_URL}/${wineProductionId}/ingredient`
  );

  const { putBody: placeInTank } = useCrudeApi<Tank>(`${BASE_URL}/${wineProductionId}/tank`);

  const addEvent = (body: ProductionEvent) => {
    return eventWineProductionCrudApi.putBody(body);
  };

  const editEvent = (eventId: number, body: ProductionEvent) => {
    return eventWineProductionCrudApi.put(eventId, body);
  };

  const removeEvent = (eventId: number) => {
    return eventWineProductionCrudApi.del(eventId);
  };

  const removeWineEntry = (wineEntryId: number) => {
    return wineEntryCrudApi.del(wineEntryId);
  };

  return {
    get,
    getList,
    getAll,
    post,
    put,
    del,
    addIngredient,
    delIngredient,
    addEvent,
    editEvent,
    removeEvent,
    putIngredient,
    archive,
    revertArchive,
    removeWineEntry,
    addWineEntry,
    getWineEntry,
    getListWithPositiveLiters,
    placeInTank
  };
};

export default useWineProductionService;
