import React, { FC } from 'react';
import CommonRow from '../../../../../../../../components/common/table/CommonRow';
import { BoxListActionsButtons } from '../../../../../../../../components/common/buttons/BoxListActionButtons';
import { useTranslation } from 'react-i18next';

interface Props {
  header: string[];
  actions: ListBoxActions;
  elementToSHow: ListBoxElementToShow;
  path: string;
  elementId?: number | null;
  infoElementId?: number | null;
  dropdownInfo: {
    paragraph?: string;
    footer?: { label: string; value?: string };
    node?: React.ReactNode;
  };
  disableActions?: boolean;
  elementToShowKey: number;
}

export interface ListBoxActions {
  toggleShow?: (id) => void;
  removeElement?: (id) => void;
  editElement?: (entityName, id) => void;
  infoElement?: (entityName, id) => void;
}

export interface ListBoxElementToShow {
  id?: number;
  isOpen?: boolean;
}

export const CommonListBox: FC<Props> = ({
  header,
  actions: { toggleShow, editElement, removeElement, infoElement },
  disableActions,
  elementToSHow,
  path,
  elementId,
  infoElementId,
  dropdownInfo,
  elementToShowKey
}) => {
  const { t } = useTranslation();

  return (
    <>
      <CommonRow
        label={header?.[0]}
        value={header?.slice(1)}
        onClick={() => toggleShow?.(elementToShowKey)}
        style={{ cursor: 'pointer' }}
        className={elementToSHow.id === elementToShowKey && elementToSHow.isOpen ? 'bg-light' : ''}
      />
      {elementToSHow.id === elementToShowKey && elementToSHow.isOpen && (
        <tr>
          <td className="bg-light" colSpan={3}>
            <div className="float-left" style={{ whiteSpace: 'pre-wrap' }}>
              <p>{dropdownInfo?.paragraph || dropdownInfo.node || t('common.NO_INFO')}</p>
              {dropdownInfo?.footer?.value && (
                <p className="m-0">{`${dropdownInfo?.footer?.label}: ${dropdownInfo?.footer?.value}`}</p>
              )}
            </div>
            <BoxListActionsButtons
              actions={{
                editElement:
                  editElement && !disableActions ? () => editElement?.(path, elementId) : undefined,
                infoElement: infoElement ? () => infoElement?.(path, infoElementId) : undefined,
                removeElement:
                  removeElement && !disableActions ? () => removeElement?.(elementId) : undefined
              }}
            />
          </td>
        </tr>
      )}
    </>
  );
};
