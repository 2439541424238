import React from 'react';
import { CardBody } from 'reactstrap';
import { useIngredientContext } from '../context/IngredientContext';
import { useTranslation } from 'react-i18next';

const IngredientInfoPanelTab = () => {
  const { ingredient } = useIngredientContext();
  const { t } = useTranslation();

  return (
    <>
      {ingredient?.ingredient?.id ? (
        <CardBody>
          <p className="text-bold">{ingredient?.ingredient?.name}</p>
          <p style={{ whiteSpace: 'pre-wrap' }}>{ingredient?.ingredient?.info}</p>
        </CardBody>
      ) : (
        <CardBody className={'text-center'}>
          <p>{t('add_ingredient.NO_INGREDIENT_SELECTED')}</p>
        </CardBody>
      )}
    </>
  );
};

export default IngredientInfoPanelTab;
