import useCrudeApi from '../../../lib/useCrudeApi';
import { useParams } from 'react-router-dom';
import { RouterParams } from '../../../types/RouterParams';
import { PlanData } from '../../../types/Subscription';

const useSubscriptionService = () => {
  const { companyId } = useParams<RouterParams>();

  const { get: getPlans } = useCrudeApi<PlanData[]>(`/ajax/subscription/plans`);
  const { post: selectSubscription } = useCrudeApi<boolean>(`/ajax/subscription`);
  const { putBody: changeStatus } = useCrudeApi<boolean>(`/ajax/subscription/status`);
  const { putBody: changePeriod } = useCrudeApi<boolean>(`/ajax/subscription/period`);
  const { putBody: confirm } = useCrudeApi<boolean>(`/ajax/subscription/confirm`);
  const { putBody: reject } = useCrudeApi<boolean>(`/ajax/subscription/reject`);
  const { put: increase } = useCrudeApi<boolean>(`/ajax/subscription/valid`);
  const { del: removeSubscription } = useCrudeApi<boolean>(
    `/ajax/subscription/remove/${companyId}/`
  );
  const { post: userChooseSubscription } = useCrudeApi<boolean>(`/ajax/subscription/user`);
  const { post: userRejectSubscription } = useCrudeApi<boolean>(`/ajax/subscription/user/reject`);

  return {
    selectSubscription,
    userChooseSubscription,
    userRejectSubscription,
    confirm,
    reject,
    changeStatus,
    changePeriod,
    increase,
    removeSubscription,
    getPlans
  };
};

export default useSubscriptionService;
