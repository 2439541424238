const AdminMenu = [
  {
    heading: 'Start',
    translate: 'sidebar.heading.START'
  },
  {
    name: 'Companies',
    icon: 'icon-grid',
    path: '/admin_console/companies/active',
    translate: 'sidebar.nav.element.COMPANIES.ACTIVE'
  },
  {
    name: 'Companies',
    icon: 'icon-grid',
    path: '/admin_console/companies/inactive',
    translate: 'sidebar.nav.element.COMPANIES.INACTIVE'
  },
  {
    name: 'Users',
    icon: 'icon-user',
    path: '/admin_console/users',
    translate: 'sidebar.nav.element.USERS'
  }
];

export default AdminMenu;
