import useCrudeApi from '../../../../../lib/useCrudeApi';
import { Tank } from '../types/Tank';
import { Parcel } from '../../parcel/types/Parcel';
import { ListMode } from '../../../../../types/enums';

const useTankService = () => {
  const crudeApi = useCrudeApi<Tank>('/ajax/tanks');
  const crudeApiForPresentation = useCrudeApi<Tank>('/ajax/tanks/select');
  const { getList: getListPresentation } = useCrudeApi<Tank>('/ajax/tanks/list');
  const { get, getAll, getList, post, put, del } = crudeApi;
  const { putId: archive } = useCrudeApi<Parcel>('/ajax/tanks/archive');
  const { putId: revertArchive } = useCrudeApi<Parcel>('/ajax/tanks/revert-archive');

  const getAvailableList = (excludeId?: string) => {
    if (excludeId) {
      return crudeApiForPresentation.getAllWithParams(
        new Map<string, string>([
          ['mode', ListMode.WITHOUT_LITERS],
          ['excludeId', excludeId]
        ])
      );
    }
    return crudeApiForPresentation.getAllWithParams(
      new Map<string, string>([['mode', ListMode.WITHOUT_LITERS]])
    );
  };

  return {
    get,
    getAll,
    getList,
    getListPresentation,
    post,
    put,
    del,
    getAvailableList,
    archive,
    revertArchive
  };
};

export default useTankService;
