import React, { FC } from 'react';
import { FormGroup } from 'reactstrap';

interface Props {
  children: React.ReactNode;
  label?: Label;
  className?: string;
}

interface Label {
  text?: string;
  labelColMd?: string;
  optional?: boolean;
}

export const InputElementSection: FC<Props> = ({ children, label, className }) => {
  return (
    <fieldset className={className}>
      <FormGroup row>
        {label?.text && (
          <label className={'col-form-label pr-0 col-md-' + (label?.labelColMd || '2')}>
            {label?.text}
            <span
              className="text-danger"
              style={{ display: label?.optional === true ? 'none' : '' }}
            >
              {' '}
              *
            </span>
          </label>
        )}
        {children}
      </FormGroup>
    </fieldset>
  );
};
