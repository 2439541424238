import { SubscriptionTimeLineItem } from '../SubscriptionTimeLineItem';
import { Color } from '../../../../../components/common/enums/Color';
import React from 'react';

export const UpgradePaidPickerInfo = () => {
  return (
    <ul className="timeline container timeline-left">
      <SubscriptionTimeLineItem
        header={'Zwiększ sój plan do PREMIUM'}
        subheader={'Postępuj zgodnie z instrukcjami po lewej stronie'}
        iconColor={Color.Green}
      />
      <SubscriptionTimeLineItem
        header={'Dopłać wskazaną kwotę za bieżący okres'}
        subheader={
          'Aktualnie dostępna jest opcja przelewu na konto. Wszystkie dane odnośnie płatności znajdziesz w sekcji Konto -> Plan'
        }
        icon={'icon-wallet'}
      />
      <SubscriptionTimeLineItem
        header={'Po zaksięgowaniu wpłaty Twój nowy plan zostanie aktywowany'}
        subheader={'Powiadomimy Cię o tym w osobnej wiadomości email'}
        icon={'fas fa-crown'}
      />
    </ul>
  );
};
