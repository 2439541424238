import React from 'react';
import { PaymentData } from '../../types';

interface IProps {
  payment: PaymentData;
}

export const PaymentRow: React.FC<IProps> = ({ payment }) => {
  return (
    <tr className="odd parent">
      <td>{payment.id}</td>
      <td>{payment.subscriptionId}</td>
      <td>
        {payment.payed ? (
          <div className={'badge bg-green'}>Tak</div>
        ) : (
          <div className={'badge bg-danger'}>Nie</div>
        )}
      </td>
      <td>{payment.paymentTitle}</td>
      <td>{payment.price}</td>
      <td>{payment.discount}</td>
      <td>{payment.priceToPay}</td>
      <td>{payment.createdDate?.split('T').join(' ')}</td>
      <td>{payment.paymentDate?.split('T').join(' ')}</td>
      <td>{payment.lastModifiedDate?.split('T').join(' ')}</td>
    </tr>
  );
};
