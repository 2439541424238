export enum Color {
  Red = 'danger',
  Error = 'error',
  Yellow = 'warning',
  Blue = 'primary',
  LightBlue = 'info',
  Muted = 'muted',
  Green = 'green',
  Success = 'success',
  White = 'white',
  Purple = 'purple',
  Pink = 'pink',
  Inverse = 'inverse',
  Secondary = 'secondary'
}
