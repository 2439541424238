import React, { FC } from 'react';
import {
  WineEntriesListBoxContainer,
  WineEntriesListBoxProps
} from './WineEntriesListBoxContainer';
import WineEntriesListBoxPresentation from './WineEntriesListBoxPresentation';

export const WineEntriesListBox: FC<WineEntriesListBoxProps> = (props) => {
  return (
    <WineEntriesListBoxContainer
      {...props}
      render={(wineEntries, eventToShow, actions) => (
        <WineEntriesListBoxPresentation
          wineEntries={wineEntries}
          elementToShow={eventToShow}
          actions={actions}
        />
      )}
    />
  );
};
