import { SubscriptionTimeLineItem } from '../SubscriptionTimeLineItem';
import { Color } from '../../../../../components/common/enums/Color';
import React from 'react';

export const BasicPickerInfo = () => {
  return (
    <ul className="timeline container timeline-left">
      <SubscriptionTimeLineItem
        header={'Rozpocznij korzystanie z podstawowych funkcji aplikacji już teraz'}
        subheader={
          'Darmowy plan zapewnia dostęp do kluczowych funkcji, które ułatwią zarządzanie okazjonalną produkcją win.'
        }
        iconColor={Color.Green}
      />
      <SubscriptionTimeLineItem
        header={'Możesz w każdej chwili przejść na wyższy plan'}
        subheader={
          'Jeśli potrzebujesz dodatkowych narzędzi, zawsze możesz wybrać plan Pro lub Premium, aby rozszerzyć możliwości aplikacji.'
        }
        icon={'fas fa-crown'}
      />
    </ul>
  );
};
