import {
  Subscription,
  SubscriptionPeriod,
  SubscriptionStatus,
  SubscriptionType
} from '../../../../../types/Subscription';
import React from 'react';
import { SubscriptionPlan } from '../../../data/Plans';

export const PickerPaymentResultForNormal: React.FC<{
  plan: SubscriptionPlan;
  normalPlan: SubscriptionPlan;
  period: SubscriptionPeriod;
  continueWithUpgradedPlan: boolean;
  activeSubscription: Subscription;
}> = ({ plan, normalPlan, period, continueWithUpgradedPlan, activeSubscription }) => {
  function getText() {
    if (
      activeSubscription.type === SubscriptionType.NORMAL &&
      activeSubscription.status === SubscriptionStatus.ACTIVATED
    ) {
      return 'Do zapłaty w kolejnym okresie:';
    }

    return period === SubscriptionPeriod.ANNUAL
      ? 'Do zapłaty w kolejnym roku:'
      : 'Do zapłaty w kolejnym miesiącu:';
  }

  function getPrice() {
    if (
      activeSubscription.type === SubscriptionType.NORMAL &&
      activeSubscription.status === SubscriptionStatus.ACTIVATED
    ) {
      return activeSubscription.period === SubscriptionPeriod.ANNUAL
        ? plan.annualPrice - normalPlan.annualPrice
        : plan.monthlyPrice - normalPlan.monthlyPrice;
    }
    return period === SubscriptionPeriod.ANNUAL
      ? plan.annualPrice - normalPlan.annualPrice
      : plan.monthlyPrice - normalPlan.monthlyPrice;
  }

  return (
    <div className="mt-4">
      <div className="row px-3 justify-content-between">
        <div className="mt-2 float-left">{getText()}</div>
        <div className="mt-2 float-right">
          {period === SubscriptionPeriod.ANNUAL ? plan.annualPrice : plan.monthlyPrice} zł
        </div>
      </div>
      <div className="row px-3 justify-content-between">
        <div className="float-left text-bold">Do zapłaty dziś:</div>
        {continueWithUpgradedPlan ? (
          <div className="float-right mb-4">{getPrice()} zł</div>
        ) : (
          <div className="float-right mb-4">0 zł</div>
        )}
      </div>
    </div>
  );
};
