import React, { useRef } from 'react';

interface TouchPosition {
  x: number;
  y: number;
  timestamp?: number;
}
export function useLongTouch(
  eventCallback: (e: React.TouchEvent<HTMLDivElement>, entity?: string, id?: string) => void
) {
  const touchStartRef = useRef<TouchPosition>({ x: 0, y: 0, timestamp: 0 });
  const touchMoveRef = useRef<boolean>(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const calculateDistance = (start: TouchPosition, end: TouchPosition) => {
    const dx = end.x - start.x;
    const dy = end.y - start.y;
    return Math.sqrt(dx * dx + dy * dy);
  };

  const handleTouchStart = (event: React.TouchEvent<HTMLDivElement>) => {
    event.preventDefault();
    const touch = event.touches[0];
    touchStartRef.current = { x: touch.clientX, y: touch.clientY, timestamp: Date.now() };
    touchMoveRef.current = false;

    timeoutRef.current = setTimeout(() => {
      if (!touchMoveRef.current) {
        const duration = Date.now() - touchStartRef.current.timestamp;
        if (duration >= 500) {
          eventCallback(event);
        }
      }
    }, 500);
  };

  const handleTouchMove = (event: React.TouchEvent<HTMLDivElement>) => {
    const touch = event.touches[0];
    const currentPos = { x: touch.clientX, y: touch.clientY };

    // Calculate distance moved
    const distance = calculateDistance(touchStartRef.current, currentPos);

    // Cancel the long tap detection if the distance is greater than a threshold
    if (distance > 10) {
      touchMoveRef.current = true;
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    }
  };

  const handleTouchEnd = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };

  return { handleTouchStart, handleTouchEnd, handleTouchMove };
}
