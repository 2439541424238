import React from 'react';
import { useTranslation } from 'react-i18next';
import { Ingredient } from '../../../winery/components/ingredient/types/Ingredient';
import { useResponsiveContext } from '../../../platform/ResponsiveContext';
import { IngredientType } from '../../../winery/components/ingredient/types/IngredientType';
import { FilesListItemColor } from '../../../../types/FileType';

interface IProps {
  ingredient: Ingredient;
  onClick: () => void;
}

export const IngredientRow: React.FC<IProps> = ({ ingredient, onClick }) => {
  const { t } = useTranslation();
  const { isMobile } = useResponsiveContext();

  function getColor(type: IngredientType): FilesListItemColor | string {
    if (type === IngredientType.YEAST) return '';
    if (type === IngredientType.NUTRIENT) return '';
    if (type === IngredientType.ENZYME) return 'green';
    if (type === IngredientType.TANNIN) return 'purple';
    if (type === IngredientType.BACTERIA) return 'primary';
    if (type === IngredientType.DEACIDIFICATION) return 'success';
    if (type === IngredientType.CLARIFICATION) return 'info';
    if (type === IngredientType.SUGARING) return 'pink';
    if (type === IngredientType.ALCOHOL_INCREASING) return 'pink';
    return 'gray';
  }

  function getBackgroundColor(type: IngredientType): FilesListItemColor | string {
    if (type === IngredientType.YEAST) return 'rgba(255,207,81,0.49)';
    if (type === IngredientType.NUTRIENT) return 'rgba(255,142,0,0.44)';
    if (type === IngredientType.SUGARING) return 'rgba(255,1,1,0.53)';
    if (type === IngredientType.ALCOHOL_INCREASING) return 'rgba(255,44,44,0.39)';
    return '';
  }

  return (
    <tr className="odd parent" style={{ cursor: 'pointer' }} onClick={onClick}>
      <td className="dtr-control sorting_1" tabIndex={0}>
        <div
          className={'badge bg-' + getColor(ingredient.type)}
          style={{ backgroundColor: getBackgroundColor(ingredient.type) }}
        >
          {t(`ingredients.TYPE.${ingredient.type}`)}
        </div>
      </td>
      <td>{ingredient.name}</td>
      {!isMobile ? (
        <td>
          {ingredient.shortInfo ||
            ingredient.info?.substring(0, 120).split('.')[0] +
              (ingredient.info?.length > 120 ? ' ...' : '')}
        </td>
      ) : null}
    </tr>
  );
};
