import { SubscriptionTimeLineItem } from '../SubscriptionTimeLineItem';
import { Color } from '../../../../../components/common/enums/Color';
import React from 'react';

export const PaidPickerInfo = () => {
  return (
    <ul className="timeline container timeline-left">
      <SubscriptionTimeLineItem
        header={'Wybierz plan odpowiedni dla Twoich potrzeb.'}
        subheader={'Postępuj zgodnie z instrukcjami po lewej stronie.'}
        iconColor={Color.Green}
      />
      <SubscriptionTimeLineItem
        header={'Opłać plan zgodnie ze wcześniejszym wyborem.'}
        subheader={
          'Aktualnie dostępna jest opcja przelewu na konto. Wszystkie dane odnośnie płatności znajdziesz w sekcji Konto -> Plan'
        }
        icon={'icon-wallet'}
      />
      <SubscriptionTimeLineItem
        header={'Po zaksięgowaniu wpłaty Twój nowy plan zostanie aktywowany.'}
        subheader={'Powiadomimy Cię o tym w osobnej wiadomości email.'}
        icon={'fas fa-crown'}
      />
    </ul>
  );
};
