/*!
 *
 * Angle - Bootstrap Admin Template
 *
 * Version: 4.5
 * Author: @themicon_co
 * Website: http://themicon.co
 * License: https://wrapbootstrap.com/help/licenses
 *
 */

import React from 'react';
import { HashRouter } from 'react-router-dom';
// App Routes
// Vendor dependencies
import './Vendor';
// Application Styles
import './assets/bootstrap.scss';
import './assets/app.scss';
import AppRouting from './features/platform/AppRouting';
import ErrorProvider from './features/error/ErrorContext';
import ValidationSchemaProvider from './features/validation/ValidationSchemaContext';
import { FormContext, useForm } from 'react-hook-form';
import AuthProvider from './features/platform/AuthContext';
import ResponsiveProvider from './features/platform/ResponsiveContext';
import PopupProvider from './features/notifications/context/PopupContext';
import Popup from './features/notifications/Popup';

const App = () => {
  // specify base href from env varible 'PUBLIC_URL'
  // use only if application isn't served from the root
  // for development it is forced to root only

  // const basename = process.env.NODE_ENV === 'development' ? '/' : (PUBLIC_URL || '/');

  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    validateCriteriaMode: 'all',
    submitFocusError: true
  });

  return (
    <AuthProvider>
      <ErrorProvider>
        <ValidationSchemaProvider>
          <ResponsiveProvider>
            <PopupProvider>
              <FormContext {...methods}>
                <HashRouter>
                  <AppRouting />
                  <Popup />
                </HashRouter>
              </FormContext>
            </PopupProvider>
          </ResponsiveProvider>
        </ValidationSchemaProvider>
      </ErrorProvider>
    </AuthProvider>
  );
};

export default App;
