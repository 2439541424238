import React, { FC } from 'react';
import {
  IngredientToWIneServiceProps,
  withIngredientServiceHOC
} from '../../applied/shared/withIngredientServiceHOC';
import { Wine } from '../../../../wine/types/Wine';
import AddIngredientForm from '../ApplyIngredient/AddIngredientForm';

export const ApplyIngredientToWine: FC<IngredientToWIneServiceProps<Wine>> = (props) => {
  const AddIngredientFormWithService = withIngredientServiceHOC(AddIngredientForm);
  return <AddIngredientFormWithService {...props} />;
};
