import React, { FC } from 'react';
import { ProductionEventToWineForm } from '../ProductionEventToWineForm';
import useWineService from '../../../wine/hooks/useWineService';
import useWineProductionService from '../../../wine_production/hooks/useWineProductionService';
import { useWineContext } from '../../../wine/context/WineContext';
import { useWineProductionContext } from '../../../wine_production/context/WineProductionContext';
import { ProductionEventToWineProductionForm } from '../ProductionEventToWineProductionForm';

export const ProductionEventForm: FC<{ entity: 'wine' | 'wine_production' }> = ({ entity }) => {
  const wineService = useWineService();
  const wineProductionService = useWineProductionService();
  const wineContext = useWineContext();
  const wineProductionContext = useWineProductionContext();

  if (entity === 'wine') {
    return (
      <ProductionEventToWineForm
        addEvent={wineService.addEvent}
        editEvent={wineService.editEvent}
        fieldName={entity}
        setResult={wineContext.setWineResult}
        entity={entity}
      />
    );
  }
  if (entity === 'wine_production') {
    return (
      <ProductionEventToWineProductionForm
        addEvent={wineProductionService.addEvent}
        editEvent={wineProductionService.editEvent}
        fieldName={entity}
        setResult={wineProductionContext.setWineProductionResult}
        entity={entity}
      />
    );
  } else {
    return <></>;
  }
};
