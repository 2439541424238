import useCrudeApi from '../../../../../lib/useCrudeApi';
import { useParams } from 'react-router-dom';
import { BottleEntry } from '../types/WineStorage';
import { RouterParams } from '../../../../../types/RouterParams';

export const useBottleEntryService = () => {
  const { wineStorageId } = useParams<RouterParams>();
  const BASE_URL = `/ajax/wine_storages/${wineStorageId}/bottle_entry`;

  const crudeApi = useCrudeApi<BottleEntry>(`${BASE_URL}`);

  const { get, post, put, del, getList, getAll } = crudeApi;

  const { putId: archive } = useCrudeApi<BottleEntry>(`${BASE_URL}/archive`);
  const { putId: revertArchive } = useCrudeApi<BottleEntry>(`${BASE_URL}/revert-archive`);

  return {
    get,
    getList,
    getAll,
    post,
    put,
    del,
    archive,
    revertArchive
  };
};
