import { StatusType } from '../../../../../../../types/Service';
import { ResponseError } from '../../../../../../error/ResponseError';
import log from 'loglevel';
import { defaultError } from '../../../../parcel/context/ParcelContext';
import { useHarvestContext } from '../../../context/HarvestContext';
import { useEffect, useRef } from 'react';
import useHarvestService from '../../../hooks/useHarvestService';
import { useParams } from 'react-router-dom';
import { RouterParams } from '../../../../../../../types/RouterParams';
import { usePushHistory } from '../../../../../../../hooks/usePushHistory';
import { Button } from '../../../../wine/components/WineInfo/WineInfoContainer';
import { EntityLiveStatus } from '../../../../../../../components/common/enums/EntityLiveStatus';
import { useTranslation } from 'react-i18next';
import { useConfirmation } from '../../../../../../notifications/useConfirmation';
import usePlanPicker from '../../../../../../subscription/hooks/usePlanPicker';
import { SubscriptionType } from '../../../../../../../types/Subscription';

export interface HarvestInfoButtons {
  editButton: Button;
  archiveButton?: Button;
  disposeButton?: Button;
  removeButton?: Button;
}

export function useHarvestInfo() {
  const { harvest, setHarvestResult, setError: setHarvestError } = useHarvestContext();
  const serviceRef = useRef(useHarvestService());
  const { harvestId } = useParams<RouterParams>();
  const { pushHistory, goBack } = usePushHistory();
  const { t } = useTranslation();
  const showConfirmation = useConfirmation();
  const { isValidSubscription } = usePlanPicker({
    subscriptionType: SubscriptionType.NORMAL,
    fromBlockingContent: true,
    itemIndexToHighlight: 1
  });

  useEffect(() => {
    setHarvestResult({ status: StatusType.loading });
    harvestId &&
      serviceRef.current
        .get(parseInt(harvestId))
        .then((response) => {
          setHarvestResult({ status: StatusType.loaded, payload: response });
        })
        .catch((error: Error) => {
          setHarvestResult(new ResponseError(error));
        });
  }, [harvestId, setHarvestResult]);

  const edit = () => {
    const target = harvest.fromOtherVineyard ? 'grapes' : 'harvest';
    pushHistory(`/mv/${target}/e/${harvestId}/`);
  };
  const dispose = () => {
    setHarvestResult({ status: StatusType.loading });
    const action = harvest.allDisposedToWine
      ? serviceRef.current.revertDispose
      : serviceRef.current.dispose;
    harvest?.id &&
      action(harvest.id)
        .then((response) => {
          setHarvestResult({ status: StatusType.loaded, payload: response });
        })
        .catch((error: Error) => {
          setHarvestResult(new ResponseError(error));
        });
  };

  const addBoxToHarvest = (e) => {
    e.preventDefault();
    log.debug('HarvestInfo:addBoxToHarvest', e, harvest);
    setHarvestResult({ status: StatusType.loading });

    serviceRef.current
      .addBox(harvest.box || {})
      .then((response) => {
        setHarvestResult({ status: StatusType.loaded, payload: response });
        pushHistory(`/mv/harvest/info/${harvestId}`);
      })
      .catch((error: Error) => {
        setHarvestResult(new ResponseError(error));
      });
  };

  const reloadHarvest = () => {
    setHarvestError(defaultError);
  };

  const archive = () => {
    if (!isValidSubscription()) return;
    const action =
      harvest?.liveStatus === EntityLiveStatus.ARCHIVED
        ? serviceRef.current.revertArchive
        : serviceRef.current.archive;
    setHarvestResult({ status: StatusType.loading });
    action(harvest?.id)
      .then((response) => {
        setHarvestResult({ status: StatusType.loaded, payload: response });
      })
      .catch((error: Error) => {
        setHarvestResult(new ResponseError(error));
      });
  };

  const remove = () => {
    showConfirmation({
      message: t('alerts.confirmation.REMOVE_HARVEST'),
      actionLabel: 'Tak',
      actionButtonColor: 'danger',
      actionCallback: () => handleRemove()
    });
  };

  function handleRemove() {
    serviceRef.current
      .del(harvest.id)
      .then(() => goBack(`/mv/board/summary`))
      .catch((error: Error) => {
        setHarvestResult(new ResponseError(error));
      });
  }

  const editButton: Button = {
    action: edit,
    label: harvest?.liveStatus === EntityLiveStatus.ARCHIVED ? t('button.VIEW') : t('button.EDIT')
  };

  const archiveButton: Button = {
    action: archive,
    label:
      harvest?.liveStatus === EntityLiveStatus.ARCHIVED
        ? t('button.REVERT_ARCHIVE')
        : t('button.ARCHIVE')
  };

  const disposeButton: Button = {
    action: dispose,
    label: !harvest?.allDisposedToWine ? t('harvest.dispose') : t('harvest.revert.dispose')
  };

  const removeButton: Button = {
    action: remove,
    label: t('common.REMOVE')
  };

  const buttons: HarvestInfoButtons = {
    editButton,
    archiveButton,
    disposeButton,
    removeButton
  };

  return {
    buttons,
    addBoxToHarvest,
    reloadHarvest
  };
}
