import React, { FC } from 'react';

interface Props {
  name: string;
  label: string;
  value: boolean;
  disabled?: boolean;
  onChange: () => void;
  favorite?: boolean;
  className?: string;
}

export const Checkbox: FC<Props> = ({
  name,
  label,
  value,
  disabled,
  favorite,
  onChange,
  className
}) => {
  return (
    <div className={'checkbox c-checkbox ' + (className || '')}>
      <label>
        <input
          id={name}
          type="checkbox"
          checked={value || false}
          onChange={onChange}
          disabled={disabled}
        />
        {favorite ? (
          <span className="fa fa-heart" style={{ backgroundColor: value ? '#ffd700' : '' }}></span>
        ) : (
          <span className="fa fa-check"></span>
        )}
        {label}
      </label>
    </div>
  );
};
