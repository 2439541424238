import React from 'react';

export type PlanItemType = {
  title: string;
  include: boolean;
  highlight?: boolean;
};

export const PlanItem: React.FC<PlanItemType> = ({ title, include, highlight }) => {
  return (
    <p style={{ display: 'flex', alignItems: 'flex-start' }}>
      {include ? (
        <em className="fa fa-check fa-fw text-info mr-2 pt-1" style={{ width: '15px' }} />
      ) : (
        <em className="fa fa-times fa-fw text-warning mr-2 pt-1" style={{ width: '15px' }} />
      )}
      <span className={highlight ? 'text-gradient-blue-green text-bold' : ''}>{title}</span>
    </p>
  );
};
