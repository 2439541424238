import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { AuthContext } from '../../../platform/AuthContext';
import WineList from './components/WineList';
import WineForm from './components/WineForm';
import WineInfo from './components/WineInfo';
import WineProvider from './context/WineContext';
import IngredientProvider from '../ingredient/context/IngredientContext';
import ProductionEventProvider from '../production_event/context/ProductionEventContext';
import { AppliedIngredientForm } from '../ingredient/components/applied/AppliedIngrediendForm';
import { ProductionEventForm } from '../production_event/components/ProductionEventForm';
import NotFound from '../../../../pages/NotFound';

class WineApp extends Component {
  static contextType = AuthContext;

  render() {
    const { principal } = this.context;
    return (
      <WineProvider>
        <IngredientProvider>
          <ProductionEventProvider>
            <Switch>
              <Route exact path="/mv/wine">
                <Redirect to="/mv/wine/all" />
              </Route>
              <Route exact path="/mv/wine/all" component={WineList} />
              <Route exact path="/mv/wine/:status?" component={WineList} />
              <Route exact path="/mv/wine/e/:wineId?">
                <WineForm principal={principal} />
              </Route>
              <Route exact path="/mv/wine/info/:wineId?">
                <WineInfo principal={principal} />
              </Route>
              <Route exact path="/mv/wine/info/:wineId?/:harvestId?">
                <WineForm principal={principal} />
              </Route>
              <Route exact path="/mv/wine/ingredient/:wineId?/:appliedIngredientId?">
                <AppliedIngredientForm entity={'wine'} />
              </Route>
              <Route exact path="/mv/wine/event/:wineId?/:productionEventId?">
                <ProductionEventForm entity={'wine'} />
              </Route>
              <Route path="*" component={NotFound} />
            </Switch>
          </ProductionEventProvider>
        </IngredientProvider>
      </WineProvider>
    );
  }
}

export default WineApp;
