import React, { ChangeEvent } from 'react';
import { useIngredientOnClickService } from '../../../hooks/useIngredientOnClickService';
import { useGetAppliedIngredient } from '../../../hooks/useGetAppliedIngredient';
import { Ingredient } from '../../../types/Ingredient';
import { Service, ServiceError } from '../../../../../../../types/Service';

export interface IngredientFormProps {
  ingredient: Ingredient;
  actions: {
    onSubmit: {
      update: (e: React.MouseEvent<HTMLButtonElement>) => void;
      save: (e: React.MouseEvent<HTMLButtonElement>) => void;
    };
    updateIngredientSelect: (e: ChangeEvent<HTMLInputElement>) => void;
    updateTypeSelect: (name: string, s: Record<string, string>) => void;
    onChange: () => void;
    updateDate: () => void;
    key?: string;
  };
  error: ServiceError;
  loading: boolean;
}

export interface IngredientToWIneServiceProps<T> {
  addIngredient: (ingredient: Ingredient) => Promise<T>;
  putIngredient: (ingredientId: number, ingredient: Ingredient) => Promise<T>;
  fieldName: 'wine' | 'wine_production';
  setResult: (value: Service<T>) => void;
}

export const withIngredientServiceHOC =
  <Props,>(WrappedComponent: React.ComponentType<Props>) =>
  (props) => {
    const { ingredient, error } = useGetAppliedIngredient();

    const { addIngredient, putIngredient, fieldName, setResult } = props;

    const actions = useIngredientOnClickService(addIngredient, putIngredient, fieldName, setResult);

    const newProps = {
      ...props,
      actions,
      error,
      ingredient,
      loading: actions.loading
    };

    return <WrappedComponent {...newProps} />;
  };
