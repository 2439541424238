import React, { ChangeEvent, FC } from 'react';

import { Input } from 'reactstrap';
import { ServiceError } from '../../types/Service';
import { InputElementSection } from './form-elements/InputElement/InputElementSection';
import { InputType } from 'reactstrap/es/Input';

interface Props {
  label: string;
  name: string;
  optional?: boolean;
  placeholder?: string;
  type?: InputType;
  maxSize?: string;
  defaultValue?: string | number | null;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  error?: ServiceError;
  className?: string;
}

const InputElement: FC<Props> = ({
  label,
  name,
  optional,
  placeholder,
  maxSize,
  defaultValue,
  onChange,
  error,
  disabled,
  type = 'text',
  className
}) => {
  return (
    <InputElementSection className={className}>
      <>
        {label && (
          <label className="col-md-2 col-form-label pr-0" htmlFor={name}>
            {label}
            <span className="text-danger" style={{ display: optional === true ? 'none' : '' }}>
              {' '}
              *
            </span>
          </label>
        )}
        <div className="col-md-10">
          <Input
            placeholder={placeholder}
            name={name}
            type={type}
            invalid={error?.hasError?.(name)}
            maxLength={maxSize}
            defaultValue={defaultValue}
            disabled={disabled}
            onChange={onChange}
            onBlur={onChange}
          />
          <span className="invalid-feedback">{error?.getErrorMessage?.(name)}</span>
        </div>
      </>
    </InputElementSection>
  );
};

export default InputElement;
