import { PlanData, SubscriptionType } from '../../../types/Subscription';

export type SubscriptionPlan = PlanData & {
  buttonLabel: string;
  color: 'base' | 'standard' | 'premium';
  containsItems: string[];
};

export const Plans: Partial<SubscriptionPlan>[] = [
  {
    subscriptionType: SubscriptionType.BASIC,
    buttonLabel: 'Rozpocznij',
    color: 'base',
    containsItems: [
      'Funkcje, które Cię zachwycą:',
      'Kompleksowy panel winnicy',
      'Przegląd aktualnie uprawianych winorośli',
      'Rejestr zbiorów i nabytych winogron',
      'Intuicyjny panel winiarni',
      'Rejestr zbiorników, beczek i kadzi i ich zawartości',
      'Zarządzanie własnymi składnikami',
      'Tworzenie win i kontrola nad produkcją',
      'Możliwość wyprodukowania 1 moszczu i wina na sezon winiarski',
      'Dodawanie składników i rejestrowanie procesów wina w zbiornikach'
    ]
  },
  {
    subscriptionType: SubscriptionType.NORMAL,
    buttonLabel: 'Rozpocznij',
    color: 'standard',
    containsItems: [
      'Wszystko to, co w wersji za darmo, oraz:',
      'Kompleksowy panel winiarni',
      'Pełna kontrola nad procesem produkcji – od zbiorów aż po gotowe wino.',
      'Nielimitowana liczba produkowanych moszczów i win',
      'Możliwość kupażu różnych moszczów i win',
      'Dostęp do historii tworzonych win oraz zbiorów',
      'Możliwość generowania raportów dotyczących zbiorów oraz produkowanych win',
      'Możliwość rejestrowania rodzajów wina w piwnicy (bez zarządzania)'
    ]
  },
  {
    subscriptionType: SubscriptionType.PREMIUM,
    buttonLabel: 'Rozpocznij',
    color: 'premium',
    containsItems: [
      'Wszystko to, co oferuje wersja PRO, oraz:',
      'Kompleksowy panel piwnicy',
      'Przechowywanie wina w butelkach i zbiornikach – monitorowanie zapasów.',
      'Przekazywanie wina z winiarni',
      'Rejestr banderolowania win',
      'Rozdysponowanie zapasami win – efektywne zarządzanie dostępnością i dystrybucją win.',
      'Możliwość generowania szczegółowych raportów z winnicy, winiarni oraz piwnicy.',
      'Generowanie raportów zawierających informacje przydatne do składania deklaracji do KOWR.'
    ]
  }
];
