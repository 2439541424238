export enum SubscriptionType {
  BASIC = 'BASIC',
  NORMAL = 'NORMAL',
  PREMIUM = 'PREMIUM'
}

export enum SubscriptionStatus {
  ACTIVATED = 'ACTIVATED',
  PAST_DUE = 'PAST_DUE',
  DISABLED = 'DISABLED',
  UPGRADED = 'UPGRADED',
  TRIAL = 'TRIAL'
}

export enum SubscriptionPeriod {
  MONTHLY = 'MONTHLY',
  ANNUAL = 'ANNUAL'
}

export type Subscription = {
  id?: number;
  type?: SubscriptionType;
  status?: SubscriptionStatus;
  period?: SubscriptionPeriod;
  validToDate?: string;
  created?: string;
};

export type PlanData = {
  id: number;
  name: string;
  description: string;
  extraInfo: string;
  subscriptionType: SubscriptionType;
  monthlyPrice: number;
  annualPrice: number;
  annualPricePerMonth: number;
};
