import { PlanItem } from '../../plans/PlanItem';
import React from 'react';

export const PickerPlanStepTwoTrialPlansItems = () => {
  return (
    <>
      <PlanItem
        title={'Bezpłatny miesięczny okres próbny bez konieczności kontynuowania'}
        include={true}
      />
      <PlanItem
        title={'Wybrany plan zostanie aktywowany tylko w przypadku jego opłacenia'}
        include={true}
      />
      <PlanItem
        title={'Nieopłacenie planu w trakcie okresu próbnego spowoduje włączenie planu darmowego'}
        include={true}
      />
      <PlanItem
        title={'Nie ryzykujesz naliczeniem opłaty po zakończeniu wersji próbnej'}
        include={true}
      />
    </>
  );
};
