import { useRef, useState } from 'react';

export const useToggleAndGoToRef = (
  defaultValue: boolean,
  shouldGoToRef = false,
  marginTop = 60
) => {
  const [value, setValue] = useState(defaultValue);
  const currentRef = useRef<HTMLElement | null>(null);

  const toggleValue = (newValue?: boolean) => {
    setValue((currentValue) => {
      const finalValue = newValue !== undefined ? newValue : !currentValue;

      setTimeout(() => {
        if (finalValue && shouldGoToRef && currentRef.current) {
          currentRef.current.scrollIntoView({ behavior: 'smooth' });

          const handleScroll = () => {
            const elementTop = currentRef.current?.getBoundingClientRect().top;
            if (elementTop < 1) {
              if (marginTop > 0) {
                window.scrollBy({ top: -marginTop, behavior: 'smooth' });
              }
              window.removeEventListener('scroll', handleScroll);
            }
          };
          window.addEventListener('scroll', handleScroll);
        }
      }, 200);
      return finalValue;
    });
  };

  return { value, toggleValue, currentRef };
};
