import React from 'react';

interface IProps {
  grapevineName: string;
  dateOfHarvest: string;
  weightOfGrapes: number;
  amountOfMust: number;
  amountOfWaste: number;
  onClick: () => void;
}

export const SummaryHarvestRow: React.FC<IProps> = ({
  grapevineName,
  dateOfHarvest,
  weightOfGrapes,
  amountOfMust,
  amountOfWaste,
  onClick
}) => {
  return (
    <tr className="odd parent" style={{ cursor: 'pointer' }} onClick={onClick}>
      <td className="text-bold">{grapevineName}</td>
      <td>{dateOfHarvest}</td>
      <td>{weightOfGrapes}</td>
      <td>{amountOfMust}</td>
      <td>{amountOfWaste}</td>
    </tr>
  );
};
