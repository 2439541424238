import React, { FC } from 'react';
import useWineService from '../../../../wine/hooks/useWineService';
import useWineProductionService from '../../../../wine_production/hooks/useWineProductionService';
import { useWineContext } from '../../../../wine/context/WineContext';
import { useWineProductionContext } from '../../../../wine_production/context/WineProductionContext';
import { AppliedToWineIngredientForm } from '../AppliedToWineIngrediendForm';
import { AppliedToWineProductionIngredientForm } from '../AppliedToWineProductionIngrediendForm';

export const AppliedIngredientForm: FC<{ entity: 'wine' | 'wine_production' }> = ({ entity }) => {
  const wineService = useWineService();
  const wineProductionService = useWineProductionService();
  const wineContext = useWineContext();
  const wineProductionContext = useWineProductionContext();

  if (entity === 'wine') {
    return (
      <AppliedToWineIngredientForm
        addIngredient={wineService.addIngredient}
        putIngredient={wineService.putIngredient}
        fieldName={'wine'}
        setResult={wineContext.setWineResult}
      />
    );
  }
  if (entity === 'wine_production') {
    return (
      <AppliedToWineProductionIngredientForm
        addIngredient={wineProductionService.addIngredient}
        putIngredient={wineProductionService.putIngredient}
        fieldName={'wine_production'}
        setResult={wineProductionContext.setWineProductionResult}
      />
    );
  } else {
    return <></>;
  }
};
