import React from 'react';
import BaseAdmin from '../../layouts/BaseAdmin';
import { CompaniesPage } from './components/companies/CompaniesPage';
import { Redirect, Route, Switch } from 'react-router-dom';
import { CompanyPage } from './components/companies/CompanyPage';
import { UsersPage } from './components/users/UsersPage';
import Popup from '../notifications/Popup';

export const AdminApp = () => {
  return (
    <BaseAdmin>
      <Switch>
        <Route exact path={'/admin_console'}>
          <Redirect to={'/admin_console/companies/active'} />
        </Route>
        <Route path={'/admin_console/companies/active'}>
          <CompaniesPage active={true} key={'active'} />
        </Route>
        <Route path={'/admin_console/companies/inactive'}>
          <CompaniesPage active={false} key={'inactive'} />
        </Route>
        <Route path={'/admin_console/company/:companyId?'}>
          <CompanyPage />
        </Route>
        <Route path={'/admin_console/users'}>
          <UsersPage />
        </Route>
      </Switch>
      <Popup />
    </BaseAdmin>
  );
};
