import React, { FC } from 'react';
import {
  DisposedAsTanksListBoxContainer,
  TankEntryListBoxProps
} from './DisposedAsTanksListBoxContainer';
import DisposedAsTanksListBoxPresentation from './DisposedAsTanksListBoxPresentation';

export const DisposedAsTanksListBox: FC<TankEntryListBoxProps> = (props) => {
  return (
    <DisposedAsTanksListBoxContainer
      {...props}
      render={(tankEntries, eventToShow, actions) => (
        <DisposedAsTanksListBoxPresentation
          tankEntries={tankEntries}
          elementToShow={eventToShow}
          actions={actions}
        />
      )}
    />
  );
};
