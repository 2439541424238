import React from 'react';
import { AccountActiveTab } from './AccountPage';

export const AccountMenu: React.FC<{
  setActiveTab: (tab: AccountActiveTab) => void;
  activeTab: AccountActiveTab;
}> = ({ setActiveTab, activeTab }) => {
  return (
    <div className="card">
      <div className="card-header bg-gray-lighter text-bold">Ustawienia osobiste</div>
      <div className="list-group">
        <div
          onClick={() => setActiveTab('3')}
          className={
            'list-group-item list-group-item-action ' + (activeTab === '3' ? 'active' : '')
          }
          style={{ cursor: 'pointer' }}
        >
          Plan
        </div>
        <div
          onClick={() => setActiveTab('1')}
          className={
            'list-group-item list-group-item-action ' + (activeTab === '1' ? 'active' : '')
          }
          style={{ cursor: 'pointer' }}
        >
          Profil
        </div>
        <div
          onClick={() => setActiveTab('2')}
          className={
            'list-group-item list-group-item-action ' + (activeTab === '2' ? 'active' : '')
          }
          style={{
            borderBottomLeftRadius: '10px',
            borderBottomRightRadius: '10px',
            cursor: 'pointer'
          }}
        >
          Konto
        </div>
      </div>
    </div>
  );
};
