import React, { useRef } from 'react';
import { usePopupContext } from '../../../notifications/context/PopupContext';
import { PickPlan } from '../../../subscription/components/picker/PickPlan';
import { SubscriptionType } from '../../../../types/Subscription';

export const GoToPlanPickerButton: React.FC<{
  subscriptionType: SubscriptionType;
  label: string;
  btnColor?: string;
}> = ({ subscriptionType, label, btnColor = '' }) => {
  const { showPopupComponent, closePopupComponent } = usePopupContext();
  const ref = useRef(
    <PickPlan
      planType={subscriptionType}
      closePicker={closePopupComponent}
      fromBlockingContent={true}
    />
  );

  function handleSelect() {
    showPopupComponent(ref.current);
  }

  return (
    <button className={`btn w-100 btn-lg ${btnColor}`} onClick={() => handleSelect()}>
      {label}
    </button>
  );
};
