import React, { useState } from 'react';
import { FromApiConverter } from '../../../../../../services/Converters';
import SimpleWineList from './SimpleWineList';
import useWineService from '../../hooks/useWineService';
import { useEntityPageReceiver } from '../../../../hooks/pagination/useEntityPageReceiver';
import { Wine, WinePresentation } from '../../types/Wine';
import { useWineContext } from '../../context/WineContext';
import { FormErrorMessage } from '../../../../../../components/notifications/FormErrorMessage';
import { useAuthContext } from '../../../../../platform/AuthContext';
import { AuthService } from '../../../../../platform/AuthService';
import { usePushHistory } from '../../../../../../hooks/usePushHistory';
import { StatusType } from '../../../../../../types/Service';
import { ResponseError } from '../../../../../error/ResponseError';
import { useConfirmation } from '../../../../../notifications/useConfirmation';
import { useTranslation } from 'react-i18next';

const WineList = (props) => {
  const service = useWineService();
  const { setError, error, setWineResult } = useWineContext();
  const [loadingRemove, setLoadingRemove] = useState(false);
  const { pushHistory } = usePushHistory();
  const showConfirmation = useConfirmation();
  const { t } = useTranslation();

  const {
    setPrincipal,
    subscriptionInfo: { accessInfo }
  } = useAuthContext();

  const {
    entities,
    currentPage,
    pagination,
    limit,
    loading,
    paginationActions,
    entityActions,
    reload
  } = useEntityPageReceiver<Wine, WinePresentation>(
    service,
    FromApiConverter.convertWineList,
    'wine',
    setError
  );

  const removeEntity = (entity: Wine) => {
    return showConfirmation({
      message: t('alerts.confirmation.REMOVE_ELEMENT'),
      actionLabel: 'Usuń',
      actionCallback: () => handleRemove(entity.id)
    });
  };

  const handleRemove = (id: number) => {
    setLoadingRemove(true);
    service
      .del(id)
      .then((response) => {
        setWineResult?.({ status: StatusType.loaded, payload: response });
        reload();
      })
      .then(() => {
        if (!accessInfo.hasNormalAccess) {
          AuthService.getUserInfo()
            .then((res) => setPrincipal(res))
            .catch(() => {
              pushHistory(`/mv/error`);
            });
        }
      })
      .catch((error) => {
        setError(error);
        setWineResult?.(new ResponseError(error));
      })
      .finally(() => setLoadingRemove(false));
  };

  const modifiedActions = {
    ...entityActions,
    remove: removeEntity
  };

  return (
    <SimpleWineList
      {...props}
      wines={entities}
      page={currentPage}
      pagination={pagination}
      limit={limit}
      loading={loading || loadingRemove}
      paginationActions={paginationActions}
      entityActions={modifiedActions}
    >
      <FormErrorMessage error={error} messageType={'details'} />
    </SimpleWineList>
  );
};
export default WineList;
