import React from 'react';
import { Card, CardBody, Table } from 'reactstrap';
import PageWrapper from '../../../../../../layouts/PageWrapper';
import { Trans, useTranslation } from 'react-i18next';
import ListActions from '../../../../../../components/common/ListActions';
import Pagination from '../../../../../../components/common/pagination/Pagination';
import { useParams } from 'react-router-dom';
import { EntityLiveStatus } from '../../../../../../components/common/enums/EntityLiveStatus';
import { usePushHistory } from '../../../../../../hooks/usePushHistory';
import { SectionWrapper } from '../../../../../../layouts/SectionWrapper';

const thead = [
  <th style={{ textAlign: 'center' }} key={3}>
    <Trans i18nKey="harvest.GRAPEVINE" />
  </th>,
  <th style={{ textAlign: 'center' }} key={9}>
    <Trans i18nKey="harvest.SOURCE" />
  </th>,
  <th style={{ textAlign: 'center' }} key={2}>
    <Trans i18nKey="harvest.DATE_OF_HARVEST" />
  </th>,
  <th style={{ textAlign: 'center' }} key={4}>
    <Trans i18nKey="harvest.WEIGHT_OF_GRAPES" />
  </th>,
  <th style={{ textAlign: 'center' }} key={5}>
    <Trans i18nKey="harvest.AMOUNT_OF_MUST" />
  </th>,
  <th style={{ textAlign: 'center' }} key={6}>
    <Trans i18nKey="harvest.AMOUNT_OF_WASTE" />
  </th>,
  <th style={{ textAlign: 'center' }} key={8}>
    <Trans i18nKey="common.ACTIONS" />
  </th>
];

const SimpleHarvestList = ({
  harvests,
  page,
  pagination,
  limit,
  loading,
  paginationActions: { changePage, onPrev, onNext },
  entityActions: { remove, proceed, info, archive, revertArchive },
  children
}) => {
  const { status } = useParams();
  const { t } = useTranslation();
  const { pushHistory } = usePushHistory();

  function pushToBoard() {
    pushHistory(`/mv/board/summary`);
  }

  const createTHead = () => {
    return (
      <thead>
        <tr>{thead.filter((t, index) => index < limit || index === thead.length - 1)}</tr>
      </thead>
    );
  };

  const buildRow = (harvest) => {
    const fields = [
      <td style={{ textAlign: 'center' }} key={3}>
        {harvest?.grapevineName || harvest.varietyName}
      </td>,
      <td style={{ textAlign: 'center' }} key={9}>
        {harvest?.fromOtherVineyard ? (
          <Trans i18nKey="harvest.SOURCE.OTHER" />
        ) : (
          <Trans i18nKey="harvest.SOURCE.OUR" />
        )}
      </td>,
      <td style={{ textAlign: 'center' }} key={2}>
        {harvest?.dateOfHarvest}
      </td>,
      <td style={{ textAlign: 'center' }} key={4}>
        {harvest?.weightOfGrapes || 0}
      </td>,
      <td style={{ textAlign: 'center' }} key={5}>
        {harvest?.amountOfMust || 0}
      </td>,
      <td style={{ textAlign: 'center' }} key={6}>
        {harvest?.amountOfWaste || 0}
      </td>,
      <td style={{ textAlign: 'center' }} key={8}>
        <ListActions
          entity={harvest}
          actions={{
            remove: remove,
            proceed: proceed,
            info: info,
            archive: archive,
            revertArchive: revertArchive
          }}
          status={status}
          otherEntityName={harvest.fromOtherVineyard ? 'grapes' : ''}
        />
      </td>
    ];
    return (
      <tr key={harvest.id}>
        {fields.filter((t, index) => index < limit || index === thead.length - 1)}
      </tr>
    );
  };

  return (
    <PageWrapper title={'harvest.TITLE'} onClick={pushToBoard} returnText={'home.summary.TITLE'}>
      <SectionWrapper loading={loading}>
        <Card>
          <CardBody>
            <h5 className="float-right">
              {status === EntityLiveStatus.ARCHIVED.toLowerCase()
                ? t('harvest.list.archived.TITLE')
                : t('harvest.list.created.TITLE')}
            </h5>
            <Table hover>
              {createTHead()}
              <tbody>
                {(harvests || []).map((harvest) => buildRow(harvest))}
                {harvests && harvests.length === 0 && (
                  <tr>
                    <td style={{ textAlign: 'center' }} colSpan={'100%'}>
                      <Trans i18nKey="common.NO_DATA" />
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
            {children}
            {pagination.totalPages > 1 && (
              <Pagination
                page={page}
                pagination={pagination}
                actions={{
                  changePage: changePage,
                  prev: onPrev,
                  next: onNext
                }}
              />
            )}
          </CardBody>
        </Card>
      </SectionWrapper>
    </PageWrapper>
  );
};

export default SimpleHarvestList;
