import React from 'react';
import 'loaders.css/loaders.css';
import 'spinkit/css/spinkit.css';

export const SectionWrapper: React.FC<{
  loading?: boolean;
  hideSpinner?: boolean;
  children: React.ReactNode;
}> = ({ loading, children, hideSpinner }) => {
  if (hideSpinner) {
    return <div className={loading ? 'whirl ringed none' : ''}>{children}</div>;
  }
  return <div className={loading ? 'whirl ringed' : ''}>{children}</div>;
};
