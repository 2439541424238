import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useResponsiveContext } from '../../../features/platform/ResponsiveContext';

export const SearchComponent = ({ search }: { search: (value: string) => void }) => {
  const { t } = useTranslation();
  const { isMobile } = useResponsiveContext();
  const currentRef = useRef(null);
  const marginTop = 60;
  const handleFocus = (e) => {
    const elementTop = currentRef.current?.getBoundingClientRect().top;
    e.preventDefault();
    if (!isMobile || elementTop < 60) return;
    setTimeout(() => {
      if (currentRef.current) {
        currentRef.current.scrollIntoView({ behavior: 'smooth' });

        const handleScroll = () => {
          const elementTop = currentRef.current?.getBoundingClientRect().top;
          if (elementTop < 1) {
            if (marginTop > 0) {
              window.scrollBy({ top: -marginTop, behavior: 'smooth' });
            }
            window.removeEventListener('scroll', handleScroll);
          }
        };
        window.addEventListener('scroll', handleScroll);
      }
    }, 100);
  };

  const preventDefault = (event) => {
    event.preventDefault();
  };

  return (
    <label>
      <input
        ref={currentRef}
        type="search"
        className="form-control form-control-sm rounded-element"
        placeholder={t('placeholder.search')}
        onChange={(e) => {
          preventDefault(e);
          search(e.target.value);
        }}
        onFocus={handleFocus}
      />
    </label>
  );
};
