import React from 'react';
import { Card, CardBody, Table } from 'reactstrap';
import CommonRow from '../../../../components/common/table/CommonRow';
import { useTranslation } from 'react-i18next';
import { Subscription } from '../../../../types/Subscription';

interface IProps {
  subscription: Subscription;
  title: string;
}

export const CompanySubscriptionCard: React.FC<IProps> = ({ subscription, title }) => {
  const { t } = useTranslation();

  return (
    <Card>
      <CardBody>
        <span className="text-muted float-left">{title}</span>
        <Table>
          <tbody>
            <CommonRow
              label={t('company.summary.subscriptionType')}
              value={t(`plans.subscription.type.${subscription.type}`)}
              valueColumnClassName={'text-right'}
            />
            <CommonRow
              label={t('company.summary.subscriptionPeriod')}
              value={t(`plans.subscription.period.${subscription.period}`)}
              valueColumnClassName={'text-right'}
            />
            <CommonRow
              label={t('company.summary.subscriptionStatus')}
              value={t(`plans.subscription.status.${subscription.status}`)}
              valueColumnClassName={'text-right'}
            />
            <CommonRow
              label={t('company.subscription.subscriptionCreated')}
              value={subscription.created}
              valueColumnClassName={'text-right'}
            />
            <CommonRow
              label={t('company.subscription.subscriptionValidToDate')}
              value={subscription.validToDate}
              valueColumnClassName={'text-right'}
            />
          </tbody>
        </Table>
      </CardBody>
    </Card>
  );
};
