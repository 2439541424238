import PageWrapper from '../../../../../../layouts/PageWrapper';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { WineEntryFormFields } from './WineEntryFormFields';
import { RouterParams } from '../../../../../../types/RouterParams';

export const AddWineEntryFormPresentation = ({
  loading,
  wineEntry,
  actions,
  error,
  numberOdWineEntriesInWine
}) => {
  const { t } = useTranslation();
  const { wineEntryId } = useParams<RouterParams>();

  return (
    <PageWrapper loading={loading} title={t('wine.info.EVENTS')} disabled={!wineEntryId}>
      <WineEntryFormFields
        wineEntry={wineEntry}
        actions={actions}
        error={error}
        numberOdWineEntriesInWine={numberOdWineEntriesInWine}
      />
    </PageWrapper>
  );
};
