import React from 'react';
import { CompanyInfoData } from '../../types';
import { useTranslation } from 'react-i18next';

interface IProps {
  company: CompanyInfoData;
  onClick: () => void;
  active: boolean;
  actions: { deactivateCompany: (id: number) => void; activateCompany: (id: number) => void };
}

export const CompanyRow: React.FC<IProps> = ({
  company,
  onClick,
  active,
  actions: { deactivateCompany, activateCompany }
}) => {
  const { t } = useTranslation();
  return (
    <tr className="odd parent">
      <td>{company.id}</td>
      <td className={'text-center'}>
        <button
          className={'btn btn-xs ' + (active ? 'btn-green' : 'btn-danger')}
          type="button"
          title={active ? 'Dezaktywuj' : 'Aktywuj'}
          onClick={() => (active ? deactivateCompany(company.id) : activateCompany(company.id))}
        >
          <span data-toggle="tooltip" title="">
            <span className={'fa fa-' + (active ? 'unlock' : 'lock')} />
          </span>
        </button>
      </td>
      <td>{company.name}</td>
      <td>
        {company.subscriptionType ? t(`plans.subscription.type.${company.subscriptionType}`) : ''}
      </td>
      <td>{company.created.split('T').join(' ')}</td>
      <td>{company.users?.length || 0}</td>
      <td>
        <div className="row">
          <button type="button" className="btn btn-xs btn-info ml-1" onClick={onClick}>
            <em className="fa fa-eye fa-fw" />
          </button>
        </div>
      </td>
    </tr>
  );
};
