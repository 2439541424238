import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { AuthContext } from '../../../platform/AuthContext';
import HarvestProvider from './context/HarvestContext';
import GrapesForm from './components/GrapesForm';
import NotFound from '../../../../pages/NotFound';

class GrapesRoute extends Component {
  static contextType = AuthContext;

  render() {
    const { principal } = this.context;
    return (
      <HarvestProvider>
        <Switch>
          <Route exact path="/mv/grapes/e/:harvestId?">
            <GrapesForm principal={principal} />
          </Route>
          <Route path="*" component={NotFound} />
        </Switch>
      </HarvestProvider>
    );
  }
}

export default GrapesRoute;
