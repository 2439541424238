import React, { FC } from 'react';
import PageWrapper from '../../../../../../layouts/PageWrapper';
import { Col, Row } from 'reactstrap';
import WineInfoBox, { WineInfo } from '../../../../common/WineInfoBox';
import PanelTab from '../../../../../../components/common/PanelTab/PanelTab';
import WineIngredientsListBox from './list-box/WineIngredientsListBox';
import { ApplyIngredient } from '../../../ingredient/components/apply/ApplyIngredient';
import IngredientInfoPanelTab from '../../../ingredient/components/IngredientInfoPanelTab';
import { useTranslation } from 'react-i18next';
import { useWineContext } from '../../context/WineContext';
import WineHistory from './history/WineHistory';
import { WineEventsListBox } from './list-box/WineEventsListBox';
import { EntityLiveStatus } from '../../../../../../components/common/enums/EntityLiveStatus';
import { WineInfoButtons } from './WineInfoContainer';
import useWineService from '../../hooks/useWineService';
import { ProductionEventForm } from '../../../production_event/components/ProductionEventForm';
import { DisposedWineEntriesListBox } from '../../../wine_production/components/DisposedWineEntriesListBox';
import { usePushHistory } from '../../../../../../hooks/usePushHistory';
import { ServiceError } from '../../../../../../types/Service';

const WineInfoPanel: FC<{
  buttons: WineInfoButtons;
  error?: ServiceError;
  updateTank: (selected: { value: number }) => void;
  showTankSelect: boolean;
  toggleTankSelect: () => void;
}> = ({ buttons, updateTank, showTankSelect, toggleTankSelect }) => {
  const { t } = useTranslation();
  const { wine, loading, error } = useWineContext();
  const service = useWineService();

  const { pushHistory } = usePushHistory();

  function pushToBoard() {
    pushHistory(`/mv/board/production`);
  }

  const wineInfo: Partial<WineInfo> = { ...wine, entity: 'wine' };

  return (
    <PageWrapper title={'wine.TITLE'} onClick={pushToBoard} returnText={'home.TITLE'}>
      {wine?.liveStatus !== EntityLiveStatus.ARCHIVED && wine?.id ? (
        <Row>
          <Col xl="4" lg="6">
            <WineInfoBox
              buttons={buttons}
              wine={wineInfo}
              loading={loading}
              error={error}
              updateTank={updateTank}
              showTankSelect={showTankSelect}
              toggleTankSelect={toggleTankSelect}
            />
            <DisposedWineEntriesListBox wineEntries={wine?.disposedAsWineEntry || []} />
          </Col>
          <Col xl="4" lg="6">
            <ProductionEventForm entity={'wine'} />
            <WineEventsListBox
              events={wine?.events || []}
              removeEvent={service.removeEvent}
              loading={loading}
              entityId={wine?.id}
            />
          </Col>
          <Col xl="4" lg="6">
            <PanelTab
              title={t('add_ingredient.ADD_WINE')}
              tab1Label={t('add_ingredient.ADD_WINE.FORM')}
              tab1={<ApplyIngredient entity={'wine'} />}
              tab2Label={t('add_ingredient.INGREDIENT_INFO')}
              tab2={<IngredientInfoPanelTab />}
            />
            <WineIngredientsListBox
              ingredients={wine?.ingredients || []}
              delIngredient={service.delIngredient}
              loading={loading}
              entityId={wine?.id}
            />
          </Col>
        </Row>
      ) : (
        <Row>
          <Col xl="4" lg="6">
            <WineInfoBox buttons={buttons} wine={wineInfo} loading={loading} error={error} />
            <DisposedWineEntriesListBox wineEntries={wine?.disposedAsWineEntry || []} />
          </Col>
          <Col xl="4" lg="6">
            <WineEventsListBox
              events={wine?.events || []}
              removeEvent={service.removeEvent}
              loading={loading}
              entityId={wine?.id}
              disabledActions
            />
          </Col>
          <Col xl="4" lg="6">
            <WineIngredientsListBox
              ingredients={wine?.ingredients || []}
              delIngredient={service.delIngredient}
              loading={loading}
              entityId={wine.id}
              disabledActions
            />
          </Col>
        </Row>
      )}
      <div className="bg-gray-lighter rounded-lg">
        <WineHistory history={wine?.history} loading={loading} />
      </div>
    </PageWrapper>
  );
};

export default WineInfoPanel;
