import React from 'react';
import { Link } from 'react-router-dom';

interface Props {
  label?: string;
  value?: number | null | string | string[] | React.ReactNode;
  onClick?: () => void;
  style?: React.CSSProperties;
  className?: string;
  valueColumnClassName?: string;
  path?: string;
}

const CommonRow: React.FC<Props> = ({
  label,
  value,
  onClick,
  style,
  className,
  valueColumnClassName,
  path
}) => {
  const renderColumns = () => {
    if (Array.isArray(value)) {
      return value.map((v, index) => (
        <td key={index} className={'text-nowrap'}>
          {v}
        </td>
      ));
    }
    if (path) {
      return (
        <td className={valueColumnClassName}>
          <Link to={{ pathname: path, state: { from: window.location.pathname } }}>{value}</Link>
        </td>
      );
    }
    return <td className={valueColumnClassName}>{value}</td>;
  };

  return (
    <tr onClick={onClick} style={style} className={className}>
      <td>
        <strong>{label}</strong>
      </td>
      {renderColumns()}
    </tr>
  );
};

export default CommonRow;
