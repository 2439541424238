import React from 'react';
import { Card, CardBody, Table } from 'reactstrap';
import PageWrapper from '../../../../../../layouts/PageWrapper';
import { useTranslation } from 'react-i18next';
import ListActions from '../../../../../../components/common/ListActions';
import Pagination from '../../../../../../components/common/pagination/Pagination';
import { useParams } from 'react-router-dom';
import { EntityLiveStatus } from '../../../../../../components/common/enums/EntityLiveStatus';
import { SectionWrapper } from '../../../../../../layouts/SectionWrapper';
import { usePushHistory } from '../../../../../../hooks/usePushHistory';

const SimpleIngredientList = ({
  ingredients,
  page,
  pagination,
  limit,
  loading,
  paginationActions: { changePage, onPrev, onNext },
  entityActions: { remove, proceed, archive, revertArchive },
  children
}) => {
  const { t } = useTranslation();
  const { pushHistory } = usePushHistory();

  function pushToBoard() {
    pushHistory(`/mv/board/ingredients`);
  }

  const { status } = useParams();

  const addTHead = function (label, hide) {
    !hide &&
      this.push(
        <th className="text-center" key={this.length}>
          {label}
        </th>
      );
  };

  const addField = function (label, hide) {
    !hide &&
      this.push(
        <td className="text-center text-muted" key={this.length}>
          {label}
        </td>
      );
  };

  const prepareTHead = () => {
    const thead = [];
    thead.add = addTHead;

    thead.add('Nazwa');
    thead.add('Rodzaj');
    thead.add('Informacje');
    thead.add('Akcje');
    return thead;
  };

  const createTHead = () => {
    const thead = prepareTHead();
    return (
      <thead>
        <tr>{thead.filter((t, index) => index < limit || index === thead.length - 1)}</tr>
      </thead>
    );
  };

  const buildRow = (ingredient, index) => {
    const { name, info: information, shortInfo, type } = ingredient;
    const fields = [];
    fields.add = addField;

    fields.add(name);
    fields.add(t(`ingredients.TYPE.${type}`));
    fields.add((shortInfo || information)?.substring(0, 120).split('.')[0] + ' ...');
    fields.add(
      <ListActions
        entity={ingredient}
        actions={{
          remove: remove,
          proceed: proceed,
          archive: archive,
          revertArchive: revertArchive
        }}
        status={status}
      />
    );
    return (
      <tr key={index}>
        {fields.filter((t, index) => index < limit || index === fields.length - 1)}
      </tr>
    );
  };

  return (
    <PageWrapper title={'ingredients.TITLE'} onClick={pushToBoard} returnText={'home.INGREDIENTS'}>
      <SectionWrapper loading={loading}>
        <Card>
          <CardBody>
            <h5 className="float-right">
              {status === EntityLiveStatus.ARCHIVED.toLowerCase()
                ? t('ingredients.list.archived.TITLE')
                : t('ingredients.list.created.TITLE')}
            </h5>
            <Table hover>
              {createTHead()}
              <tbody>
                {(ingredients || []).map((ingredient, index) => buildRow(ingredient, index))}
                {ingredients && ingredients.length === 0 && (
                  <tr>
                    <td style={{ textAlign: 'center' }} colSpan={'100%'}>
                      Brak danych
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
            {children}
            {pagination.totalPages > 1 && (
              <Pagination
                page={page}
                pagination={pagination}
                actions={{
                  changePage: changePage,
                  prev: onPrev,
                  next: onNext
                }}
              />
            )}
          </CardBody>
        </Card>
      </SectionWrapper>
    </PageWrapper>
  );
};

export default SimpleIngredientList;
