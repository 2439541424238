import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import log from 'loglevel';
import { PdfPageLayout } from '../../../../../types/enums';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../../../platform/AuthContext';
import { useResponsiveContext } from '../../../../platform/ResponsiveContext';
import usePlanPicker from '../../../../subscription/hooks/usePlanPicker';
import { SubscriptionType } from '../../../../../types/Subscription';
import { LockIconInfo } from '../../../../../components/common/lock/LockIconInfo';

export const WinesListButtonsBar: React.FC<{
  reportAvailable: boolean;
}> = ({ reportAvailable }) => {
  const { t } = useTranslation();
  const {
    subscriptionInfo: { accessInfo }
  } = useContext(AuthContext);
  const { isMobile, isSmallMobile } = useResponsiveContext();

  const { isValidSubscription } = usePlanPicker({
    subscriptionType: SubscriptionType.NORMAL,
    fromBlockingContent: true,
    blockingMessage: 'Przejdź na wyższy plan, aby pobrać ten raport',
    itemIndexToHighlight: 6
  });

  const { isValidSubscription: isValidSubscriptionForNumberOfWines } = usePlanPicker({
    subscriptionType: SubscriptionType.NORMAL,
    fromBlockingContent: true,
    blockingMessage: 'Przejdź na wyższy plan, aby móc tworzyć bez limitu',
    itemIndexToHighlight: 3
  });

  function getPdf(layout = PdfPageLayout.LANDSCAPE) {
    if (!isValidSubscription()) {
      return;
    }
    log.debug('GET_PDF');
    window.location.href = `/ajax/pdf/WINERY_SUMMARY?layout=${layout}`;
  }

  function createWine(e: React.MouseEvent) {
    if (!accessInfo.winesAllowed && !isValidSubscriptionForNumberOfWines()) {
      e.preventDefault();
    }
  }

  function createWineProduction(e: React.MouseEvent) {
    if (!isValidSubscriptionForNumberOfWines()) {
      e.preventDefault();
    }
  }

  return (
    <div className="mb-3">
      <div>
        <Link
          to={{
            pathname: '/mv/wine/e/0',
            state: { from: '/mv/board/production' }
          }}
          className="btn btn-sm btn-info"
          type="button"
          onClick={createWine}
        >
          <em className="mr-1 fas fa-plus" /> moszcz
          {!accessInfo.winesAllowed ? (
            <LockIconInfo
              subscriptionTypeNeeded={SubscriptionType.NORMAL}
              accessInfo={accessInfo}
              hideLabel={isMobile}
            />
          ) : null}
        </Link>
        <Link
          to={'/mv/wine/all'}
          className="btn btn-sm btn-secondary"
          type="button"
          title={t('button.show.list')}
        >
          <em className="fas fa-outdent" />
        </Link>
        <Link
          to={{
            pathname: '/mv/wine_production/e/0',
            state: { from: '/mv/board/production' }
          }}
          className="btn btn-sm btn-info ml-2"
          type="button"
          onClick={createWineProduction}
        >
          <em className="mr-1 fas fa-plus" /> wino
          <LockIconInfo
            subscriptionTypeNeeded={SubscriptionType.NORMAL}
            accessInfo={accessInfo}
            hideLabel={isMobile}
          />
        </Link>
        <Link
          to={'/mv/wine_production/all'}
          className="btn btn-sm btn-secondary"
          type="button"
          title={t('button.show.list')}
        >
          <em className="fas fa-outdent" />
        </Link>
        {reportAvailable ? (
          <button
            className="btn btn-sm btn-secondary ml-1 float-right"
            type="button"
            onClick={() => getPdf()}
            title={t('button.download.pdf')}
          >
            <em className="ml-auto fa fa-download fa-fw text-info"></em>{' '}
            {!isMobile ? <span className={''}>{t('button.download.report')}</span> : null}
            <LockIconInfo
              accessInfo={accessInfo}
              subscriptionTypeNeeded={SubscriptionType.NORMAL}
              hideLabel={isSmallMobile}
            />
          </button>
        ) : null}
      </div>
    </div>
  );
};
