import React, { FC } from 'react';
import {
  WineProductionIngredientsListBoxContainer,
  WineProductionIngredientsListBoxProps
} from './WineProductionIngredientsListBoxContainer';
import IngredientsListBox from '../../../../wine/components/WineInfo/list-box/shared/IngredientsListBox';

const WineProductionIngredientsListBox: FC<WineProductionIngredientsListBoxProps> = (props) => {
  return (
    <WineProductionIngredientsListBoxContainer
      {...props}
      render={(ingredients, ingredientToShow, actions) => (
        <IngredientsListBox
          ingredients={ingredients}
          ingredientToShow={ingredientToShow}
          actions={actions}
          entityName={'wine_production'}
          loading={props.loading}
          disabledActions={props?.disabledActions}
          entityId={props.entityId}
        />
      )}
    />
  );
};

export default WineProductionIngredientsListBox;
