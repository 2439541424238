import { useEffect, useRef, useState } from 'react';

export function useScroll() {
  const scrollRef = useRef(null);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);

  useEffect(() => {
    const current = scrollRef.current;
    const handleScroll = () => {
      if (current) {
        const currentScrollLeft = current.scrollLeft;
        const maxScrollLeft = current.scrollWidth - current.clientWidth;

        setCanScrollLeft(currentScrollLeft > 0);
        setCanScrollRight(currentScrollLeft < maxScrollLeft);
      }
    };

    if (current) {
      current.addEventListener('scroll', handleScroll);
      handleScroll(); // Initial check

      return () => {
        current.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  const scrollRight = () => {
    if (scrollRef.current && canScrollRight) {
      const currentScrollLeft = scrollRef.current.scrollLeft;
      const newScrollLeft = currentScrollLeft + 200; // Adjust the scrolling distance as needed
      scrollRef.current.scrollTo({
        left: newScrollLeft,
        behavior: 'smooth'
      });
    }
  };

  const scrollLeft = () => {
    if (scrollRef.current && canScrollLeft) {
      const currentScrollLeft = scrollRef.current.scrollLeft;
      const newScrollLeft = currentScrollLeft - 200; // Adjust the scrolling distance as needed
      scrollRef.current.scrollTo({
        left: newScrollLeft,
        behavior: 'smooth'
      });
    }
  };

  return { scrollRef, scrollLeft, scrollRight, canScrollLeft, canScrollRight };
}
