import { PlanItem } from '../../plans/PlanItem';
import React from 'react';

export const PickerPlanStepTwoPaidPlansItems = () => {
  return (
    <div className="py-2">
      <PlanItem
        title={
          'Ciesz się dostępem do jeszcze większej liczby funkcji, które pomogą Ci w zarządzaniu i rozwijaniu Twojej winnicy'
        }
        include={true}
      />
      <PlanItem
        title={
          'Plan zostanie aktywowany po opłaceniu, a o jego uruchomieniu poinformujemy Cię w osobnej wiadomości'
        }
        include={true}
      />
    </div>
  );
};
