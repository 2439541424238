import React from 'react';
import ReactDOM from 'react-dom';
import './assets/Popup.css';
import { usePopupContext } from './context/PopupContext';
import { useResponsiveContext } from '../platform/ResponsiveContext';

const Popup = () => {
  const { showPopup, component, buttons, closePopupComponent } = usePopupContext();
  const { isMobile } = useResponsiveContext();

  const portal = document.getElementById('portal');
  if (!portal) {
    throw new Error('Could not find portal element');
  }

  const customStyles = isMobile
    ? { right: -10, top: 0, zIndex: 1000 }
    : { right: -50, top: -25, zIndex: 1000 };

  if (!showPopup) return null;
  else
    return ReactDOM.createPortal(
      <div
        className="popup-container"
        style={{ alignItems: isMobile ? '' : 'center', zIndex: '1000000000' }}
      >
        <div className={'card bg-light ' + (isMobile ? 'popup-mobile' : 'popup')}>
          {!buttons ? (
            <div className={'position-absolute'} style={customStyles}>
              <button className={'btn'} onClick={() => closePopupComponent()}>
                <em className={'icon-close fa-2x text-gradient-blue-purple'} />
              </button>
            </div>
          ) : null}
          {component}
          {buttons ? <div className={'card-header text-center'}>{buttons}</div> : null}
        </div>
      </div>,
      portal
    );
};

export default Popup;
