import React from 'react';
import { HarvestsListButtonsBar } from './HarvestsListButtonsBar';
import { HarvestsListTable } from './HarvestsListTable';
import { HarvestPresentation } from '../../../../winery/components/harvest/types/Harvest';
import { useTranslation } from 'react-i18next';

export const HarvestsListCard: React.FC<{
  harvests: HarvestPresentation[];
  hideButtons?: boolean;
  loading: boolean;
}> = ({ harvests, hideButtons, loading }) => {
  const { t } = useTranslation();
  const ownHarvest = harvests.filter((harvest) => !harvest.fromOtherVineyard);
  const otherGrapes = harvests.filter((harvest) => harvest.fromOtherVineyard);
  return (
    <>
      {!hideButtons ? <HarvestsListButtonsBar reportAvailable={harvests.length > 0} /> : null}
      {(otherGrapes.length > 0 && ownHarvest.length > 0) || otherGrapes.length === 0 ? (
        <HarvestsListTable
          harvests={ownHarvest}
          title={t('vineyard.board.harvest.table.title')}
          loading={loading}
        />
      ) : null}
      {otherGrapes.length > 0 ? (
        <HarvestsListTable
          harvests={otherGrapes}
          title={t('vineyard.board.harvest.grapes.table.title')}
          loading={loading}
        />
      ) : null}
    </>
  );
};
