import { useEffect, useRef } from 'react';

const useClickOutside = (callback: () => void) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (wrapperRef?.current && !wrapperRef.current.contains(event.target as Node)) {
        callback();
      }
    };
    document.addEventListener('mousedown', (e) => handleClickOutside(e));
    return document.removeEventListener('mousedown', (e) => handleClickOutside(e));
  }, [callback]);

  return { wrapperRef };
};

export default useClickOutside;
