import { useEffect } from 'react';
import { ServiceError, StatusType } from '../../../../../types/Service';
import { ResponseError } from '../../../../error/ResponseError';
import { useParams } from 'react-router-dom';
import { ProductionEvent } from '../../wine/types/Wine';
import { WineEntry } from '../../wine/types/WineProduction';
import useWineProductionService from './useWineProductionService';
import { useWineProductionContext } from '../context/WineProductionContext';
import { useWineEntryContext } from '../components/AddWineEntryForm/WineEntryContext';

interface ReturnProps {
  wineEntry: WineEntry | Partial<WineEntry>;
  error: ServiceError | undefined;
  loading: boolean;
}

export const useGetWineEntry = (): ReturnProps => {
  const { wineEntry, result, setResult, loading } = useWineEntryContext();

  const { wineProduction } = useWineProductionContext();

  const { wineEntryId } = useParams();
  const service = useWineProductionService();

  useEffect(() => {
    if (result.status === StatusType.loading && wineEntryId) {
      service
        .getWineEntry(parseInt(wineEntryId, wineProduction?.id))
        .then((response) => setResult({ status: StatusType.loaded, payload: response }))
        .catch((response) => setResult(new ResponseError<ProductionEvent>(response)));
    } else if (!wineEntryId && result.status === StatusType.loading) {
      setResult({ status: StatusType.loaded });
    }
  });

  const error = result?.status === StatusType.error ? (result as ServiceError) : undefined;

  return { wineEntry, error, loading };
};
