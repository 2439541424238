import useCrudeApi from '../../../lib/useCrudeApi';

const useAccountService = () => {
  const { post: updateUser } = useCrudeApi<{ login: string; name: string }>(`/ajax/user`);
  const { post: updatePassword } = useCrudeApi<{ newPassword: string }>(`/auth/changePassword`);
  const { putBody: updateCompany } = useCrudeApi<{ name: string }>(`/ajax/company`);
  const { putBody: removeAccount } = useCrudeApi<{ name: string }>(`/auth/account/remove`);

  return {
    updateUser,
    updateCompany,
    updatePassword,
    removeAccount
  };
};

export default useAccountService;
