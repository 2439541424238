import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectOption } from '../../../types/SelectOption';
import { SubscriptionType } from '../../../types/Subscription';

export const useSubscriptionTypeReceiver = (value?: SubscriptionType | SelectOption | null) => {
  const { t } = useTranslation();
  const options: SelectOption[] = Object.keys(SubscriptionType)
    .filter((i) => typeof SubscriptionType[i] === 'string')
    .map((i) => ({ value: i, label: t(`plans.subscription.type.${i}`) }));
  const [selected, setSelected] = useState<SelectOption | string>('');

  useEffect(() => {
    if (value) {
      setSelected(value ? { value: value, label: t(`plans.subscription.type.${value}`) } : '');
      return;
    }
  }, [value, t]);

  return { options, selected, loading: false };
};
