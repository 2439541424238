import React from 'react';

export const PremiumPlanBody = () => {
  return (
    <div>
      <p>
        Wybierając plan <strong className={'text-gradient-blue-purple'}>Premium</strong>, zyskujesz
        pełen zestaw narzędzi, które umożliwią kompleksowe zarządzanie Twoją winnicą i winiarnią.
        Oprócz podstawowych funkcji, takich jak rejestrowanie zbiorów winogron, tworzenie nowych win
        oraz monitorowanie każdego etapu produkcji i składników, jakie oferuje plan Podstawowy,
        Premium otwiera przed Tobą znacznie więcej możliwości.
      </p>

      <p>
        Dzięki funkcjom z planu{' '}
        <strong className={'text-gradient-blue-purple'}>PRO dla winiarza</strong>, możesz tworzyć{' '}
        <strong className={'text-gradient-blue-green'}>nielimitowaną ilość win</strong>, śledzić
        pełną historię każdej partii produkcji, a także generować{' '}
        <strong className={'text-gradient-blue-green'}>raporty</strong>, które pomogą Ci lepiej
        zrozumieć i zoptymalizować procesy produkcyjne.
      </p>

      <p>
        Ale to nie wszystko! Plan <strong className={'text-gradient-blue-purple'}>Premium</strong>{' '}
        przenosi zarządzanie winnicą na zupełnie nowy poziom. Otrzymujesz dostęp do zaawansowanych
        funkcji, takich jak:
      </p>

      <ul>
        <li>
          <strong className={'text-gradient-blue-green'}>Zarządzanie piwnicą</strong> – przechowuj
          oraz monitoruj zapasy swoich win.
        </li>
        <li>
          <strong className={'text-gradient-blue-green'}>Rejestracja banderol</strong> – miej
          kontrolę nad naklejaniem i rozliczaniem banderol.
        </li>
        <li>
          <strong className={'text-gradient-blue-green'}>Zarządzanie dystrybucją</strong> –
          kontroluj sprzedaż, degustacje i konsumpcję wewnętrzną win.
        </li>
        <li>
          <strong className={'text-gradient-blue-green'}>Szczegółowe raporty</strong> – analizuj
          wyniki na głębszym poziomie, aby lepiej zarządzać zasobami i podejmować trafne decyzje
          biznesowe.
        </li>
      </ul>

      <p>
        Dzięki planowi Premium masz pełną kontrolę nad każdym aspektem produkcji wina – od zbiorów,
        przez tworzenie wina, aż po jego sprzedaż i przechowywanie. To wszystko, czego potrzebujesz,
        aby rozwijać swoją winnicę i zarządzać nią na najwyższym poziomie!
      </p>
    </div>
  );
};
