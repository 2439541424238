import React from 'react';
import { useProductionEventTypesReceiverForWineProduction } from '../../../wine/hooks/useProductionEventTypesReceiverForWineProduction';
import { SelectType } from '../../../../../../components/common/form-elements/SelectType';
import { SelectOptionProps } from '../../../../../../components/common/form-elements/SelectType/SelectTypeModel';
import { ProductionEventForWineType } from '../../../wine/types/Wine';
import { useProductionEventTypesReceiverForWine } from '../../../wine/hooks/useProductionEventTypesReceiverForWine';

export const SelectProductionEventType: React.FC<
  SelectOptionProps<ProductionEventForWineType> & { entity: 'wine' | 'wine_production' }
> = ({ value, onChange, name, label, optional, error, entity }) => {
  return (
    <SelectType
      onChange={onChange}
      name={name}
      receiver={
        entity === 'wine'
          ? useProductionEventTypesReceiverForWine
          : useProductionEventTypesReceiverForWineProduction
      }
      error={error}
      optional={optional}
      label={label}
      value={value}
    />
  );
};
