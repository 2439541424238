import React, { useEffect, useState } from 'react';
import { Input } from 'reactstrap';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { StringValueValidator } from '../services/Validators';
import { AuthService } from '../features/platform/AuthService';
import { WelcomeCard } from '../components/common/cards/WelcomeCard';
import { Button } from '../components/common/buttons/Button';

const Reset = () => {
  const { t } = useTranslation();

  const [password, setPassword] = useState('');
  const [passwordRepeat, setPasswordRepeat] = useState('');
  const [error, setError] = useState(false);
  const [user, setUser] = useState({ login: '' });
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const { token } = useParams();

  const onChangePassword = ({ target: { value } }) => {
    setPassword(value);
    setError(StringValueValidator.isNotBlank(passwordRepeat) && value !== passwordRepeat);
  };

  const onChangePasswordRepeat = ({ target: { value } }) => {
    setPasswordRepeat(value);
    setError(StringValueValidator.isNotBlank(password) && password !== value);
  };

  const onSubmit = () => {
    const abortController = new AbortController();
    setLoading(true);

    !error &&
      AuthService.reset(token, password, { signal: abortController.signal })
        .then(() => {
          setPassword('');
          setPasswordRepeat('');
          setError(false);
          setSuccess(true);
        })
        .catch((res) => console.log(res))
        .finally(() => setLoading(false));
  };

  useEffect(() => {
    const abortController = new AbortController();
    setLoading(true);
    AuthService.validate(token, { signal: abortController.signal })
      .then((res) => setUser(res))
      .finally(() => setLoading(false));
    return () => {
      abortController.abort();
    };
  }, [token]);

  const invalid = !loading && !user.login;

  return (
    <WelcomeCard>
      <p className="text-center py-2">{t('password.RESET_PASSWORD')}</p>
      <form name="formRecover" onSubmit={onSubmit}>
        {success && <p className="text-center py-2 text-success">{t('password.CHANGED')}</p>}
        {invalid && <p className="text-center py-2 text-danger">{t('password.token.invalid')}</p>}
        {!(success || invalid) && (
          <>
            <div className="form-group">
              <div className="input-group with-focus">
                <Input
                  type="password"
                  name="password"
                  className="border-right-0"
                  placeholder="Nowe hasło"
                  invalid={error}
                  disabled={success || invalid}
                  onChange={onChangePassword}
                  value={password}
                />
                <div className="input-group-append">
                  <span className="input-group-text text-muted bg-transparent border-left-0">
                    <em className="fa fa-lock"></em>
                  </span>
                </div>
                {error && (
                  <span className="invalid-feedback">{t('password.validation.equal')}</span>
                )}
              </div>
            </div>
            <div className="form-group">
              <div className="input-group with-focus">
                <Input
                  type="password"
                  name="passwordRepeat"
                  className="border-right-0"
                  placeholder="Powtórz hasło"
                  invalid={error}
                  disabled={success || invalid}
                  onChange={onChangePasswordRepeat}
                  value={passwordRepeat}
                />
                <div className="input-group-append">
                  <span className="input-group-text text-muted bg-transparent border-left-0">
                    <em className="fa fa-lock"></em>
                  </span>
                </div>
                {error && (
                  <span className="invalid-feedback">{t('password.validation.equal')}</span>
                )}
              </div>
            </div>
            <Button
              onClick={onSubmit}
              label={'Resetuj'}
              className={'btn btn-danger btn-block'}
              disabled={!(password && passwordRepeat) || error || invalid || loading}
              loading={loading}
            />
          </>
        )}
        <div className="d-flex">
          <div className="mt-1 ml-10 ml-auto">
            <Link to="/welcome/login" className="text-muted">
              <small>{t('password.back.to.login')}</small>
            </Link>
          </div>
        </div>
      </form>
    </WelcomeCard>
  );
};

export default Reset;
