import React from 'react';
import HarvestFormContainer from '../HarvestForm/HarvestFormContainer';
import SimpleGrapesForm from './SimpleGrapesForm';

const GrapesForm = () => {
  return (
    <HarvestFormContainer
      render={(
        updateGrapevineInHarvest,
        onSubmit,
        onSubmitGrapes,
        error,
        harvest,
        updateHarvest,
        updateDate,
        loading,
        onClickBack,
        updateHarvestCheckbox,
        handleUpdateSelect
      ) => (
        <SimpleGrapesForm
          onSubmit={onSubmitGrapes}
          error={error}
          harvest={harvest}
          updateHarvest={updateHarvest}
          updateDate={updateDate}
          loading={loading}
          onClickBack={onClickBack}
          updateHarvestCheckbox={updateHarvestCheckbox}
          handleUpdateSelect={handleUpdateSelect}
        />
      )}
    />
  );
};

export default GrapesForm;
