import { AccountHeaderInfo } from './AccountHeaderInfo';
import React, { useRef } from 'react';
import { useAuthContext } from '../../platform/AuthContext';
import { SubscriptionStatus, SubscriptionType } from '../../../types/Subscription';
import { InProgresPlanInfo } from './plan_infos/InProgresPlanInfo';
import { ActivatedPlanInfo } from './plan_infos/ActivatedPlanInfo';
import { TrialPlanInfo } from './plan_infos/TrialPlanInfo';
import { PastDuePlanInfo } from './plan_infos/PastDuePlanInfo';
import { UpgradePlanInfo } from './plan_infos/UpgradePlanInfo';
import { PremiumPlanBody } from './plan_infos/PremiumPlanBody';
import useSubscriptionService from '../../subscription/hooks/useSubscriptionService';
import { useTranslation } from 'react-i18next';
import { useConfirmation } from '../../notifications/useConfirmation';
import { NormalPlanBody } from './plan_infos/NormalPlanBody';
import { useGetPlans } from '../../subscription/hooks/useGetPlans';
import { SectionWrapper } from '../../../layouts/SectionWrapper';

export const PlanTab: React.FC<{ setLoading: (val: boolean) => void; loading: boolean }> = ({
  setLoading,
  loading
}) => {
  const {
    setPrincipal,
    subscriptionInfo: { activeSubscription: subscription, inProgressSubscription }
  } = useAuthContext();
  const subscriptionServiceRef = useRef(useSubscriptionService());
  const { t } = useTranslation();
  const { plans } = useGetPlans();

  const showConfirmation = useConfirmation();

  const rejectPlan = () => {
    showConfirmation({
      message: t('alerts.confirmation.REJECT_SUBSCRIPTION'),
      actionLabel: 'Zrezygnuj',
      actionCallback: () => rejectSubscription()
    });
  };

  function rejectSubscription() {
    setLoading(true);
    subscriptionServiceRef.current
      .userRejectSubscription()
      .then((response) => {
        setPrincipal(response);
        close?.();
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function getSubheaderByStatus(status: SubscriptionStatus) {
    const hasInProgressSubscription =
      inProgressSubscription && inProgressSubscription.type !== subscription?.type;
    switch (status) {
      case SubscriptionStatus.ACTIVATED:
        if (hasInProgressSubscription) {
          return <InProgresPlanInfo rejectPlan={rejectPlan} />;
        }
        return <ActivatedPlanInfo />;
      case SubscriptionStatus.TRIAL:
        return <TrialPlanInfo />;
      case SubscriptionStatus.DISABLED:
        return null;
      case SubscriptionStatus.PAST_DUE:
        return <PastDuePlanInfo rejectPlan={rejectPlan} />;
      case SubscriptionStatus.UPGRADED:
        return null;
      default:
        return null;
    }
  }

  function getBodyByStatus(type: SubscriptionType) {
    switch (type) {
      case SubscriptionType.BASIC:
        return (
          <UpgradePlanInfo
            plans={plans}
            subscriptionType={SubscriptionType.BASIC}
            hasInProgressSubscription={!!inProgressSubscription}
          />
        );
      case SubscriptionType.NORMAL:
        return <NormalPlanBody inProgressSubscription={inProgressSubscription} plans={plans} />;
      case SubscriptionType.PREMIUM:
        return <PremiumPlanBody />;
      default:
        return null;
    }
  }

  return (
    <SectionWrapper loading={loading}>
      <div className="card">
        <AccountHeaderInfo subscription={subscription} title={'Plan'} />
        <div className="my-3 px-3">
          {getSubheaderByStatus(subscription?.status || inProgressSubscription?.status)}
          {plans ? getBodyByStatus(subscription?.type || inProgressSubscription?.type) : null}
        </div>
      </div>
    </SectionWrapper>
  );
};
