import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import log from 'loglevel';
import { PdfPageLayout } from '../../../../../types/enums';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../../../platform/AuthContext';
import { useResponsiveContext } from '../../../../platform/ResponsiveContext';
import usePlanPicker from '../../../../subscription/hooks/usePlanPicker';
import { SubscriptionType } from '../../../../../types/Subscription';

export const VarietiesListButtonsBar: React.FC<{ reportAvailable: boolean }> = ({
  reportAvailable
}) => {
  const { t } = useTranslation();

  const {
    subscriptionInfo: { accessInfo }
  } = useContext(AuthContext);
  const { isMobile } = useResponsiveContext();
  const { isValidSubscription } = usePlanPicker({
    subscriptionType: SubscriptionType.NORMAL,
    fromBlockingContent: true,
    blockingMessage: 'Przejdź na wyższy plan, aby pobrać ten raport'
  });

  function getPdf(layout = PdfPageLayout.LANDSCAPE) {
    if (!isValidSubscription()) {
      return;
    }
    log.debug('GET_PDF');
    window.location.href = `/ajax/pdf/VINEYARD_SUMMARY?layout=${layout}`;
  }

  return (
    <div className="mb-3">
      <div>
        <Link
          to={{
            pathname: '/mv/parcel/e/0',
            state: { from: '/mv/board/summary' }
          }}
          className="btn btn-sm btn-info"
          type="button"
        >
          <em className="mr-1 fas fa-plus" /> działkę
        </Link>
        <Link
          to={'/mv/parcel/all'}
          className="btn btn-sm btn-secondary"
          type="button"
          title={t('button.show.list')}
        >
          <em className="fas fa-outdent" />
        </Link>
        <Link
          to={{
            pathname: '/mv/grapevine/e/0',
            state: { from: '/mv/board/summary' }
          }}
          className="btn btn-sm btn-info ml-2"
          type="button"
        >
          <em className="mr-1 fas fa-plus" /> winorośl
        </Link>
        <Link
          to={'/mv/grapevine/all'}
          className="btn btn-sm btn-secondary"
          type="button"
          title={t('button.show.list')}
        >
          <em className="fas fa-outdent" />
        </Link>
        {reportAvailable ? (
          <button
            className="btn btn-sm btn-secondary ml-1 float-right"
            type="button"
            onClick={() => getPdf()}
            title={t('button.download.pdf')}
          >
            <em className="ml-auto fa fa-download fa-fw text-info"></em>{' '}
            {!isMobile ? <span>{t('button.download.report')}</span> : null}
            {!accessInfo.hasNormalAccess ? <em className={'icon-lock ml-1'} /> : null}
            <sup className="text-yellow ml-1"> pro</sup>
          </button>
        ) : null}
      </div>
    </div>
  );
};
