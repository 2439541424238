import React, { useCallback, useContext, useMemo, useReducer } from 'react';
import { WineStorage } from '../types/WineStorage';
import { Service, ServiceError, StatusType } from '../../../../../types/Service';
import { defaultError } from '../../parcel/context/ParcelContext';
import { ResponseError } from '../../../../error/ResponseError';

interface WineStorageContextInterface {
  wineStorage: WineStorage;
  setWineStorage: (value: WineStorage) => void;
  updateWineStorage: (name, value) => void;
  wineStorages: WineStorage[];
  setWineStorages: (value: WineStorage[]) => void;
  result: Service<WineStorage>;
  setResult: (value: Service<WineStorage>) => void;
  setError: (value: ServiceError) => void;
  error: ServiceError;
  loading: boolean;
}

const defaultWineStorage: WineStorage = {
  id: undefined,
  name: '',
  wineColor: undefined,
  wineTaste: undefined,
  bottleEntries: [],
  tankEntries: [],
  bottleOutputs: [],
  tankOutputs: [],
  liveStatus: undefined,
  totalLiters: undefined,
  totalBottles: undefined,
  litersInBottles: undefined,
  totalTanks: undefined,
  litersInTanks: undefined,
  taxBandsPlacements: [],
  taxBandsTotalQuantity: undefined,
  taxBandsActualQuantity: undefined,
  maxLiters: undefined,
  maxBottles: undefined,
  maxLitersInBottles: undefined,
  maxTanks: undefined,
  maxLitersInTanks: undefined,
  varietalWine: false,
  favoriteWine: false,
  protectedNameOrGeoWine: false,
  fromUE: true,
  outsideUE: false,
  totalNotFinishedPlacementsOfTaxBands: undefined,
  taxBandsPlacementNeedAttention: false
};

const defaultState = {
  wineStorage: defaultWineStorage,
  setWineStorage: () => {},
  updateWineStorage: () => {},
  wineStorages: [],
  setWineStorages: () => {},
  result: { status: StatusType.loading },
  setResult: () => {},
  setError: () => {},
  error: defaultError,
  loading: false
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'reset':
      return defaultState;
    case 'resetWineStorage':
      return { ...state, wineStorage: defaultWineStorage };
    case 'resetWineStorages':
      return { ...state, wineStorages: [] };
    case 'wineStorage':
      return { ...state, wineStorage: { ...action.value } };
    case 'wineStorages':
      return { ...state, wineStorages: [...action.value] };
    case 'result':
      return { ...state, result: { ...action.value } };
    case 'error':
      return { ...state, error: action.value };

    default:
      return { ...state, wineStorage: { ...state.wineStorage, [action.type]: action.value } };
  }
};

const WineStorageContext = React.createContext({} as WineStorageContextInterface);
export const useWineStorageContext = (): WineStorageContextInterface => {
  return useContext(WineStorageContext);
};

const WineStorageProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, defaultState);

  const resetWineStorage = useCallback(() => {
    dispatch({ type: 'resetWineStorage', value: '' });
  }, []);

  const resetWineStorages = useCallback(() => {
    dispatch({ type: 'resetWineStorages', value: '' });
  }, []);

  const setWineStorage = useCallback((wineStorage) => {
    dispatch({ type: 'wineStorage', value: wineStorage });
  }, []);

  const setWineStorages = useCallback((wineStorages) => {
    dispatch({ type: 'wineStorages', value: wineStorages });
  }, []);

  const updateWineStorage = useCallback((name, value) => {
    dispatch({ type: name, value });
  }, []);

  const setResult = useCallback((result) => {
    dispatch({ type: 'result', value: result });
    if (result?.payload) {
      dispatch({ type: 'wineStorage', value: result.payload });
    }
  }, []);

  const setError = useCallback((error) => {
    dispatch({ type: 'error', value: new ResponseError(error) });
  }, []);

  const providerValue = useMemo(
    () => ({
      wineStorage: state.wineStorage,
      updateWineStorage,
      wineStorages: state.wineStorages,
      setWineStorage,
      setWineStorages,
      resetWineStorage,
      resetWineStorages,
      result: state.result,
      setResult,
      setError,
      error: state.error,
      loading: state.result.status === StatusType.loading
    }),
    [
      state.wineStorage,
      updateWineStorage,
      state.wineStorages,
      setWineStorage,
      setWineStorages,
      resetWineStorage,
      resetWineStorages,
      state.result,
      setResult,
      setError,
      state.error
    ]
  );

  return (
    <WineStorageContext.Provider value={providerValue}>{children}</WineStorageContext.Provider>
  );
};

export default WineStorageProvider;
