import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import IngredientList from './components/IngredientList';
import { IngredientForm } from './components/IngredientForm';
import IngredientProvider from './context/IngredientContext';
import NotFound from '../../../../pages/NotFound';

const IngredientApp = () => {
  return (
    <IngredientProvider>
      <Switch>
        <Route exact path="/mv/ingredient">
          <Redirect to="/mv/ingredient/all" />
        </Route>
        <Route exact path="/mv/ingredient/all" component={IngredientList} />
        <Route exact path="/mv/ingredient/:status?" component={IngredientList} />
        <Route exact path="/mv/ingredient/e/:ingredientId?">
          <IngredientForm />
        </Route>
        <Route path="/mv/ingredient/info/:ingredientId?"></Route>
        <Route path="*" component={NotFound} />
      </Switch>
    </IngredientProvider>
  );
};

export default IngredientApp;
