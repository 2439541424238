import { useEventHandlerActions } from '../../../../hooks/useEventHandlerActions';
import React, { useEffect } from 'react';
import { ServiceError, StatusType } from '../../../../../../types/Service';
import { useHistory, useParams } from 'react-router-dom';
import log from 'loglevel';
import { ResponseError } from '../../../../../error/ResponseError';
import { SelectOption } from '../../../../../../types/SelectOption';
import { WineStorage } from '../../types/WineStorage';
import { useWineStorageService } from '../../hooks/useWineStorageService';
import { useWineStorageContext } from '../../context/WineStorageContext';
import { RouterParams } from '../../../../../../types/RouterParams';

interface Props {
  updateWineStorage: () => void;
  loading: boolean;
  onClickBack: () => void;
  onSubmit: () => void;
  wineStorage: WineStorage;
  error: ServiceError;
  handleUpdateSelect: () => void;
}

export const withWineProductionFromServiceHOC =
  <Props,>(WrappedComponent: React.ComponentType<Props>) =>
  (props) => {
    const { wineStorage, updateWineStorage, setResult, result } = useWineStorageContext();

    const { onChange: handleUpdateWineProduction } = useEventHandlerActions(updateWineStorage);

    const { wineStorageId } = useParams<RouterParams>();
    const history = useHistory();

    const service = useWineStorageService();

    useEffect(() => {
      if (wineStorageId?.toString() === '0') {
        setResult({ status: StatusType.loaded });
        return;
      }
      wineStorageId &&
        service
          .get(parseInt(wineStorageId))
          .then((response) => {
            setResult({ status: StatusType.loaded, payload: response });
          })
          .catch((response) => setResult(new ResponseError(response) as ServiceError));

      return () => {
        updateWineStorage('reset', '');
      };
    }, [wineStorageId, updateWineStorage]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleUpdateSelect = (name: string, selected: SelectOption) => {
      updateWineStorage(name, selected.value);
    };

    const onSubmit = (e) => {
      e.preventDefault();
      log.debug('withWineProductionFromServiceHOC:onSubmit', e, wineStorage);
      setResult({ status: StatusType.loading });

      const action = () =>
        wineStorage?.id ? service.put(wineStorage.id, wineStorage) : service.post(wineStorage);
      action()
        .then((response) => {
          setResult(response);
          history?.push(history?.location?.state?.['from'] || `/mv/wine_storage/all`);
        })
        .catch((response) => setResult(new ResponseError(response) as ServiceError));
    };

    const onClickBack = () => {
      history?.push(history?.location?.state?.['from'] || `/mv/wine_storage/archived`);
    };

    const updateCheckbox = (name: string, value: boolean) => {
      updateWineStorage(name, value);
    };

    const error = result as ServiceError;

    const newProps = {
      ...props,
      updateWineStorage: handleUpdateWineProduction,
      loading: result.status === StatusType.loading,
      onClickBack,
      onSubmit,
      wineStorage,
      error,
      handleUpdateSelect,
      updateCheckbox
    };

    return <WrappedComponent {...newProps} />;
  };
