import React from 'react';
import WineProductionInfoPanel from './WineProductionInfoPanel';
import WineProductionInfoContainer from './WineProductionInfoContainer';

export const WineProductionInfo = () => {
  return (
    <WineProductionInfoContainer
      render={(buttons, updateTank, error, showTankSelect, toggleTankSelect) => (
        <WineProductionInfoPanel
          buttons={buttons}
          updateTank={updateTank}
          error={error}
          showTankSelect={showTankSelect}
          toggleTankSelect={toggleTankSelect}
        />
      )}
    />
  );
};
