import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../../../../../components/common/buttons/Button';
import CommonRow from '../../../../../../../components/common/table/CommonRow';
import usePagination from '../../../../../../../hooks/usePagination';
import { PaginationComponent } from '../../../../../../../components/PaginationComponent';
import { SectionWrapper } from '../../../../../../../layouts/SectionWrapper';

const SimpleTankEntryList = ({ tankEntries, loading, remove, info, children, removeDisabled }) => {
  const { t } = useTranslation();
  const pagination = usePagination(tankEntries || [], 10);

  function getThead() {
    return [`${t('tankEntry.tank.NUMBER')}`, `${t('common.ACTIONS')}`];
  }

  const buildRow = (tankEntry) => {
    const fields = [
      <td style={{ textAlign: 'center' }} key={1}>
        {tankEntry?.actualLiters} / {tankEntry?.liters}
      </td>,
      <td style={{ textAlign: 'center' }} key={3}>
        {tankEntry?.tank?.number}
      </td>,
      <td style={{ textAlign: 'right', minWidth: '110px' }} key={5}>
        {tankEntry?.wineProduction ? (
          <Button.Info onClick={() => info(tankEntry?.wineProduction)} />
        ) : null}
        <Button.Delete onClick={() => remove(tankEntry)} disabled={removeDisabled} />
      </td>
    ];
    return <tr key={tankEntry.id}>{fields}</tr>;
  };

  return (
    <SectionWrapper loading={loading} hideSpinner>
      <Card>
        <CardBody>
          <h5 className="m-0 float-right">{t('wineStorage.tank.entry.ADDED')}</h5>
          <small className="text-muted">
            {t('common.SUMMARY')}:{' '}
            {tankEntries?.map((entries) => entries.liters).reduce((total, item) => total + item, 0)}{' '}
            l.
          </small>
          {pagination.getCurrentResults()?.length ? (
            <table className={'table w-100 no-footer table-hover'}>
              <tbody>
                <CommonRow
                  label={`${t('tankEntry.LITERS')}`}
                  value={getThead()}
                  className={'text-center'}
                />
                {(pagination.getCurrentResults() || []).map((tankEntry) => buildRow(tankEntry))}
              </tbody>
            </table>
          ) : (
            <CardBody>
              <div className={'text-center'}>{t('common.NO_DATA')}</div>
            </CardBody>
          )}
          {children}
          <PaginationComponent pagination={pagination} />
        </CardBody>
      </Card>
    </SectionWrapper>
  );
};

export default SimpleTankEntryList;
