import React, { useEffect, useRef, useState } from 'react';
import PageWrapper from '../../../../layouts/PageWrapper';
import { VarietiesCard } from './varieties/VarietiesCard';
import { VineyardSummaryCard } from './VineyardSummaryCard';
import { VarietiesListCard } from './varieties/VarietiesListCard';
import useBoardService from '../../hooks/useBoardService';
import { VineyardSummaryData } from '../../../../types/dto';
import { HarvestsListCard } from './harvests/HarvestsListCard';
import { usePushHistory } from '../../../../hooks/usePushHistory';

export const VineyardSummaryPage = () => {
  const serviceRef = useRef(useBoardService());
  const [summary, setSummary] = useState<VineyardSummaryData>(null);
  const [loading, setLoading] = useState(true);
  const { pushHistory } = usePushHistory();

  useEffect(() => {
    if (serviceRef.current != null && loading) {
      serviceRef.current
        .getSummary()
        .then((response) => {
          setSummary(response);
        })
        .finally(() => setLoading(false));
    }
  }, [loading, setSummary]);

  return (
    <PageWrapper
      title={'home.summary.TITLE'}
      onClick={() => pushHistory('/mv')}
      returnText={'sidebar.nav.element.DASHBOARD'}
    >
      <div className="row">
        <div className="col-lg-12">
          <VarietiesCard
            grapevines={summary?.grapevines || []}
            totalPlants={summary?.totalPlants}
            vineyardArea={summary?.vineyardArea}
            loading={loading}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-4">
          <VineyardSummaryCard summary={summary} loading={loading} />
        </div>
        <div className="col-lg-8">
          <VarietiesListCard grapevines={summary?.grapevines || []} loading={loading} />
          <HarvestsListCard harvests={summary?.harvests || []} loading={loading} />
        </div>
      </div>
    </PageWrapper>
  );
};
