import React from 'react';

import Header from './Header';
import Footer from './Footer';
import { useResponsiveContext } from '../features/platform/ResponsiveContext';
import AdminSidebar from './AdminSidebar';
import Offsidebar from './Offsidebar';

const BaseAdmin = (props) => {
  const { isMobile } = useResponsiveContext();
  return (
    <div className="wrapper">
      <Header isMobile={isMobile} />
      <AdminSidebar />
      <Offsidebar />
      <section className="section-container">{props.children}</section>

      <Footer />
    </div>
  );
};

export default BaseAdmin;
