import React, { ChangeEvent, FC } from 'react';
import { Button, Card, CardBody } from 'reactstrap';
import { WineProduction } from '../../../wine/types/WineProduction';
import { ServiceError } from '../../../../../../types/Service';
import { useTranslation } from 'react-i18next';
import PageWrapper from '../../../../../../layouts/PageWrapper';
import InputElement from '../../../../../../components/common/InputElement';
import { EntityLiveStatus } from '../../../../../../components/common/enums/EntityLiveStatus';
import SelectWineColor from '../../../../common/select/SelectWineColor';
import SelectWineTaste from '../../../../common/select/SelectWineTaste';
import { InputDate } from '../../../../../../components/common/form-elements/InputDate';
import { Checkbox } from '../../../../../../components/common/form-elements/Checkbox';
import { FormErrorMessage } from '../../../../../../components/notifications/FormErrorMessage';
import { usePushHistory } from '../../../../../../hooks/usePushHistory';
import { SectionWrapper } from '../../../../../../layouts/SectionWrapper';

interface Props {
  onSubmit: (e: React.MouseEvent<HTMLButtonElement>) => void;
  wineProduction: WineProduction;
  updateWineProduction: (e: ChangeEvent<HTMLInputElement>) => void;
  handleUpdateSelect: () => void;
  loading: boolean;
  onClickBack: () => void;
  error: ServiceError;
  updateDate: (date: Date | string) => void;
  updateCheckbox: (name: string, value: boolean) => void;
}

export const WineProductionFormPresentation: FC<Props> = ({
  loading,
  onClickBack,
  onSubmit,
  wineProduction,
  updateWineProduction,
  error,
  handleUpdateSelect,
  updateDate,
  updateCheckbox
}) => {
  const { t } = useTranslation();
  const { pushHistory } = usePushHistory();

  function pushToBoard() {
    pushHistory(`/mv/board/production`);
  }

  return (
    <PageWrapper title={'wineProduction.TITLE'} onClick={pushToBoard} returnText={'home.TITLE'}>
      <SectionWrapper loading={loading}>
        <Card>
          <CardBody>
            <div className={'pb-5'}>
              <h5 className="float-right">
                {wineProduction?.id
                  ? t('sidebar.nav.element.EDIT')
                  : t('sidebar.nav.element.ADD_NEW_WINE_PRODUCTION')}
              </h5>
            </div>
            <InputElement
              label={t('wine.NAME')}
              type={'text'}
              name={'name'}
              onChange={updateWineProduction}
              defaultValue={wineProduction?.name}
              error={error}
              disabled={wineProduction?.liveStatus === EntityLiveStatus.ARCHIVED}
            />
            <SelectWineColor
              value={wineProduction?.wineColor}
              name={'wineColor'}
              label={t('wine.COLOR')}
              onChange={handleUpdateSelect}
              error={error}
              disabled={wineProduction?.liveStatus === EntityLiveStatus.ARCHIVED}
            />
            <SelectWineTaste
              value={wineProduction?.wineTaste}
              name={'wineTaste'}
              label={t('wine.TASTE')}
              onChange={handleUpdateSelect}
              error={error}
              disabled={wineProduction?.liveStatus === EntityLiveStatus.ARCHIVED}
            />
            <InputDate
              label={t('wineProduction.DATE')}
              extraInfo={t('wineProduction.DATE.extraInfo')}
              name={'startDate'}
              onChange={updateDate}
              defaultValue={wineProduction?.startDate}
              error={error}
              disabled={wineProduction?.liveStatus === EntityLiveStatus.ARCHIVED}
              optional
            />
            <Checkbox
              name={'varietalWine'}
              label={t('wine.VARIETAL_WINE')}
              value={!!wineProduction.varietalWine}
              disabled={!!wineProduction.protectedNameOrGeoWine}
              onChange={() => updateCheckbox('varietalWine', !wineProduction.varietalWine)}
            />
            <Checkbox
              name={'protectedNameOrGeoWine'}
              label={t('wine.PROTECTED_NAME_OR_GEO_WINE')}
              value={!!wineProduction.protectedNameOrGeoWine}
              disabled={!!wineProduction.varietalWine}
              onChange={() =>
                updateCheckbox('protectedNameOrGeoWine', !wineProduction.protectedNameOrGeoWine)
              }
            />
            <div>
              <small className="text-muted">{t('wineProduction.bottom.message1')}</small>
            </div>
            <div>
              <small className="text-muted">{t('wineProduction.bottom.message2')}</small>
            </div>
            <FormErrorMessage error={error} messageType={'details'} />
            <div className="text-center bg-secondary">
              {wineProduction?.liveStatus === EntityLiveStatus.ARCHIVED ? (
                <Button color="info" className="btn-square" onClick={onClickBack}>
                  {t('common.BACK')}
                </Button>
              ) : (
                <Button color="info" className="btn-square" onClick={onSubmit}>
                  {wineProduction?.id ? t('common.SAVE') : t('common.ADD')}
                </Button>
              )}
            </div>
          </CardBody>
        </Card>
      </SectionWrapper>
    </PageWrapper>
  );
};
