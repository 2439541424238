import React from 'react';
import { UserInfoData } from '../../types';
import { useParams } from 'react-router-dom';
import { RouterParams } from '../../../../types/RouterParams';

interface IProps {
  user: UserInfoData;
  onClick: () => void;
  actions?: { deactivateUser: (id: number) => void; activateUser: (id: number) => void };
}

export const UserRow: React.FC<IProps> = ({ user, onClick, actions }) => {
  const { companyId } = useParams<RouterParams>();

  return (
    <tr className="odd parent">
      <td>{user.id}</td>
      <td className={'text-center'}>
        {companyId ? (
          <button
            className={'btn btn-xs ' + (user.active ? 'btn-green' : 'btn-danger')}
            type="button"
            title={user.active ? 'Dezaktywuj' : 'Aktywuj'}
            onClick={() =>
              user.active ? actions?.deactivateUser(user.id) : actions?.activateUser(user.id)
            }
          >
            <span data-toggle="tooltip" title="">
              <span className={'fa fa-' + (user.active ? 'unlock' : 'lock')} />
            </span>
          </button>
        ) : (
          <div
            className={`flex circle circle-lg }`}
            style={{ backgroundColor: user.active ? 'green' : 'red' }}
          />
        )}
      </td>
      <td>{user.name}</td>
      {companyId ? null : <td>{user.companyName}</td>}
      <td>{user.lastLogin?.split('T')?.join(' ')}</td>
      <td>{user.roles?.join(', ')}</td>
      <td>{user.login}</td>
      <td>{user.created.split('T').join(' ')}</td>
      {companyId ? null : (
        <td>
          <button type="button" className="btn btn-xs btn-info ml-1" onClick={onClick}>
            <em className="fa fa-eye fa-fw" />
          </button>
        </td>
      )}
    </tr>
  );
};
