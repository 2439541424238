import React, { Component } from 'react';

class Footer extends Component {
  render() {
    const year = new Date().getFullYear();
    return (
      <footer className="footer-container">
        <span>
          {' '}
          <img src={'img/logo-dark.png'} style={{ height: '2rem' }} alt="Mistrz wina" />
          &copy; {year}
        </span>
      </footer>
    );
  }
}

export default Footer;
