import React from 'react';
import { SubscriptionType } from '../../../types/Subscription';
import { AccessInfo } from '../../../features/admin/types';
import { useResponsiveContext } from '../../../features/platform/ResponsiveContext';

export const LockIconInfo: React.FC<{
  subscriptionTypeNeeded: SubscriptionType;
  accessInfo: AccessInfo;
  hideLabel?: boolean;
}> = ({ subscriptionTypeNeeded, accessInfo, hideLabel }) => {
  const { isMedium } = useResponsiveContext();

  function hasAccess() {
    if (subscriptionTypeNeeded === SubscriptionType.PREMIUM) return accessInfo.hasPremiumAccess;
    if (subscriptionTypeNeeded === SubscriptionType.NORMAL) return accessInfo.hasNormalAccess;
    return true;
  }

  function getLabel() {
    if (subscriptionTypeNeeded === SubscriptionType.PREMIUM) return isMedium ? 'prem' : 'premium';
    if (subscriptionTypeNeeded === SubscriptionType.NORMAL) return 'pro';
    return '';
  }

  return (
    <>
      {!hasAccess() ? <em className={'icon-lock ml-1'} /> : null}
      {!hideLabel ? <sup className="text-yellow ml-1"> {getLabel()}</sup> : null}
    </>
  );
};
