import React from 'react';
import ProgressBar from '../../../../components/common/charts/ProgressBar';
import { Card, CardBody, Table } from 'reactstrap';
import CommonRow from '../../../../components/common/table/CommonRow';
import { VineyardSummaryData } from '../../../../types/dto';
import { useTranslation } from 'react-i18next';
import { SectionWrapper } from '../../../../layouts/SectionWrapper';

export const VineyardSummaryCard: React.FC<{ summary: VineyardSummaryData; loading: boolean }> = ({
  summary,
  loading
}) => {
  const { t } = useTranslation();

  return (
    <SectionWrapper loading={loading} hideSpinner>
      <Card>
        <CardBody>
          <ProgressBar
            percent={summary ? Math.floor((summary?.vineyardArea / summary?.totalArea) * 100) : 0}
            color={'green'}
            title={t('vineyard.board.summary.table.area.percent')}
          />

          <small className="text-muted float-right">
            {t('vineyard.board.summary.table.title')}
          </small>
          <Table>
            <tbody>
              <CommonRow
                label={t('vineyard.board.summary.table.area.total')}
                value={summary ? `${summary?.totalArea} ha` : ''}
                valueColumnClassName={'text-right'}
              />
              <CommonRow
                label={t('vineyard.board.summary.table.area.vineyard')}
                value={summary ? `${summary?.vineyardArea} ha` : ''}
                valueColumnClassName={'text-right'}
              />
              <CommonRow
                label={t('vineyard.board.summary.table.area.white')}
                value={summary ? `${summary?.whiteVarietyArea} ha` : ''}
                valueColumnClassName={'text-right'}
              />
              <CommonRow
                label={t('vineyard.board.summary.table.area.red')}
                value={summary ? `${summary?.redVarietyArea} ha` : ''}
                valueColumnClassName={'text-right'}
              />
              <CommonRow
                label={t('vineyard.board.summary.table.varieties')}
                value={summary ? summary?.totalVarieties : ''}
                valueColumnClassName={'text-right'}
              />
              <CommonRow
                label={t('vineyard.board.summary.table.plants')}
                value={summary ? summary?.totalPlants : ''}
                valueColumnClassName={'text-right'}
              />
              <CommonRow
                label={t('vineyard.board.summary.table.first')}
                value={summary ? summary?.firstPlant : ''}
                valueColumnClassName={'text-right'}
              />
              <CommonRow
                label={t('vineyard.board.summary.table.last')}
                value={summary ? summary?.lastPlant : ''}
                valueColumnClassName={'text-right'}
              />
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </SectionWrapper>
  );
};
