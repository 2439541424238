import React, { useRef } from 'react';
import { SubscriptionStatus, SubscriptionType } from '../../../../types/Subscription';
import { useTranslation } from 'react-i18next';
import { PickPlan } from '../picker/PickPlan';
import { usePopupContext } from '../../../notifications/context/PopupContext';
import { usePushHistory } from '../../../../hooks/usePushHistory';
import { useAuthContext } from '../../../platform/AuthContext';

export type PlanSectionType = {
  title: string;
  description: string;
  extraInfo: string;
  type: SubscriptionType;
  annualPricePerMonth?: number;
  buttonLabel: string;
  color: 'base' | 'standard' | 'premium';
};

export const PlanSection: React.FC<
  PlanSectionType & {
    children: React.ReactNode;
  }
> = ({
  children,
  title,
  description,
  extraInfo,
  type,
  buttonLabel,
  color,
  annualPricePerMonth
}) => {
  const {
    subscriptionInfo: { activeSubscription, inProgressSubscription, accessInfo }
  } = useAuthContext();

  const trialAllowed = accessInfo?.trialAllowed || (!accessInfo && type !== SubscriptionType.BASIC);

  const { t } = useTranslation();
  const { showPopupComponent, closePopupComponent } = usePopupContext();
  const ref = useRef(<PickPlan planType={type} closePicker={closePopupComponent} />);
  const { pushHistory } = usePushHistory();

  function getColors() {
    const isButtonDisabled = buttonDisabled();
    if (color === 'base') return ['bg-secondary', 'btn-secondary'];
    if (color === 'standard')
      return ['bg-secondary', isButtonDisabled ? 'btn-secondary' : 'btn-info bg-info'];
    return ['', isButtonDisabled ? 'bg-secondary' : 'btn-info bg-info'];
  }

  function buttonDisabled() {
    if (!activeSubscription) return false;
    if (accessInfo.hasPremiumAccess) return true;
    if (accessInfo.hasNormalAccess && type !== SubscriptionType.PREMIUM) return true;
    return (
      !accessInfo.hasPremiumAccess && !accessInfo.hasNormalAccess && type === SubscriptionType.BASIC
    );
  }

  function getLabel() {
    if (activeSubscription?.type === type) return t('plans.button.label.active');
    if (buttonDisabled()) return t('plans.button.label.cannotChoose');
    if (inProgressSubscription && activeSubscription?.status !== SubscriptionStatus.TRIAL)
      return t('plans.button.label.continueCurrent');
    if (inProgressSubscription && activeSubscription?.status === SubscriptionStatus.TRIAL) {
      return t('plans.button.label.continueTrial');
    }
    return trialAllowed && type !== SubscriptionType.BASIC
      ? `${buttonLabel} ${t('plans.button.label.trial')}`
      : buttonLabel;
  }

  function handleSelect() {
    if (inProgressSubscription && activeSubscription?.status !== SubscriptionStatus.TRIAL) {
      pushHistory('/mv/account');
      return;
    }
    showPopupComponent(ref.current);
  }

  const style = { borderTopLeftRadius: '10px', borderTopRightRadius: '10px' };

  const amountSplit = annualPricePerMonth.toString().split('.');
  return (
    <div className="card" style={{ minWidth: '280px', maxWidth: '310px' }}>
      <div
        className={`card-body text-center ${getColors()?.[0]}`}
        style={color === 'premium' ? { ...style, backgroundColor: '#ffd700' } : style}
      >
        <div className="text-bold">{title}</div>
        <h3 className="my-3">
          {annualPricePerMonth > 0 ? (
            <>
              <sup>OD ~</sup>
              <span className="text-lg">{amountSplit[0]}</span>
              <sup>
                {/*<sup>{amountSplit[1] && amountSplit[1] !== '0' ? amountSplit[1] : ''}</sup>*/}
                <span className={'ml-2'}>PLN</span>
              </sup>
              <span>/mies.</span>{' '}
            </>
          ) : (
            <span className="text-lg">Za darmo</span>
          )}
        </h3>
        <div className={'text-left py-2'}>{description}</div>
        <div className={'text-left py-2 mb-3 text-gradient-blue-purple'}>{extraInfo}</div>
        <div className="text-center">
          <button
            className={`btn w-100 btn-lg ${
              inProgressSubscription &&
              activeSubscription?.status !== SubscriptionStatus.TRIAL &&
              !buttonDisabled()
                ? 'btn-warning'
                : getColors()?.[1]
            }`}
            onClick={() => handleSelect()}
            disabled={buttonDisabled()}
          >
            {getLabel()}
          </button>
        </div>
      </div>
      <div className="card-body">{children}</div>
    </div>
  );
};
