import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { CustomInput, Input } from 'reactstrap';

import FormValidator from '../components/forms/FormValidator.js';
import { WelcomeCard } from '../components/common/cards/WelcomeCard';
import { AuthContext } from '../features/platform/AuthContext';
import { AuthService } from '../features/platform/AuthService';
import { SectionWrapper } from '../layouts/SectionWrapper';

class Register extends Component {
  static contextType = AuthContext;

  state = {
    formRegister: {
      email: '',
      companyName: '',
      username: '',
      terms: false
    },
    errors: {
      login: ''
    },
    success: false,
    loading: false
  };

  /**
   * Validate input using onChange event
   * @param  {String} formName The name of the form in the state object
   * @return {Function} a function used for the event
   */
  validateOnChange = (event) => {
    const input = event.target;
    const form = input.form;
    const value = input.type === 'checkbox' ? input.checked : input.value;

    const result = FormValidator.validate(input);

    this.setState({
      [form.name]: {
        ...this.state[form.name],
        [input.name]: value,
        errors: {
          ...this.state[form.name].errors,
          [input.name]: result
        }
      }
    });
  };

  onSubmit = (e) => {
    const form = e.target;
    const inputs = [...form.elements].filter((i) => ['INPUT', 'SELECT'].includes(i.nodeName));
    const {
      formRegister: { email, username, companyName }
    } = this.state;
    const { errors, hasError } = FormValidator.bulkValidate(inputs);
    e.preventDefault();

    this.setState({
      [form.name]: {
        ...this.state[form.name],
        errors
      },
      errors: {
        login: ''
      }
    });
    if (!hasError) {
      this.setState({
        loading: true
      });

      AuthService.register(email, username, companyName)
        .then(this.handleRegister)
        .catch((res) => {
          this.setState({
            ...this.state,
            errors: {
              login: res.errors?.login
            }
          });
        })
        .finally(() => {
          this.setState(() => ({
            loading: false
          }));
        });
    }
  };

  handleRegister = (response) => {
    console.log('RegisterForm::handleRegister', response);

    response &&
      this.setState({
        formRegister: {
          email: '',
          companyName: '',
          username: '',
          terms: false
        },
        success: true
      });
  };

  /* Simplify error check */
  hasError = (formName, inputName, method) => {
    return this.state[formName]?.errors?.[inputName]?.[method];
  };

  render() {
    return (
      <WelcomeCard>
        <SectionWrapper loading={this.state.loading}>
          <p className="text-center py-2">ZAREJESTRUJ SIĘ BY UZYSKAĆ DOSTĘP</p>
          <form className="mb-3" name="formRegister" onSubmit={this.onSubmit}>
            {this.state.success ? (
              <p className="text-center pt-3 pb-0 text-success">
                Udało się! Za chwilę otrzymasz e-mail z możliwością ustawienia hasła.
              </p>
            ) : (
              <>
                <div className="form-group">
                  <label className="text-muted" htmlFor="id-username">
                    Nazwa użytkownika
                  </label>
                  <div className="input-group with-focus">
                    <Input
                      type="text"
                      id="id-username"
                      name="username"
                      className="border-right-0"
                      placeholder="Wprowadź nazwę"
                      invalid={this.hasError('formRegister', 'username', 'required')}
                      onChange={this.validateOnChange}
                      data-validate='["required"]'
                      value={this.state.formRegister.username}
                    />
                    <div className="input-group-append">
                      <span className="input-group-text text-muted bg-transparent border-left-0">
                        <em className="fa fa-user"></em>
                      </span>
                    </div>
                    <span className="invalid-feedback">Pole wymagane</span>
                  </div>
                </div>
                <div className="form-group">
                  <label className="text-muted" htmlFor="email">
                    Adres e-mail
                  </label>
                  <div className="input-group with-focus">
                    <Input
                      type="email"
                      name="email"
                      className="border-right-0"
                      placeholder="Wprowadź email"
                      invalid={
                        this.hasError('formRegister', 'email', 'required') ||
                        this.hasError('formRegister', 'email', 'email') ||
                        this.state.errors.login?.length
                      }
                      onChange={this.validateOnChange}
                      data-validate='["required", "email"]'
                      value={this.state.formRegister.email}
                    />
                    <div className="input-group-append">
                      <span className="input-group-text text-muted bg-transparent border-left-0">
                        <em className="fa fa-envelope"></em>
                      </span>
                    </div>
                    {this.hasError('formRegister', 'email', 'required') && (
                      <span className="invalid-feedback">Pole wymagane</span>
                    )}
                    {this.hasError('formRegister', 'email', 'email') && (
                      <span className="invalid-feedback">Podaj prawidłowy email</span>
                    )}
                    {this.state.errors.login?.length ? (
                      <span className="invalid-feedback">Email zajęty</span>
                    ) : null}
                  </div>
                </div>
                <div className="form-group">
                  <label className="text-muted" htmlFor="id-companyName">
                    Nazwa winnicy
                  </label>
                  <div className="input-group with-focus">
                    <Input
                      type="text"
                      id="id-companyName"
                      name="companyName"
                      className="border-right-0"
                      placeholder="Wprowadź nazwę"
                      invalid={this.hasError('formRegister', 'companyName', 'required')}
                      onChange={this.validateOnChange}
                      data-validate='["required"]'
                      value={this.state.formRegister.companyName}
                    />
                    <div className="input-group-append">
                      <span className="input-group-text text-muted bg-transparent border-left-0">
                        <em className="fa fa-image"></em>
                      </span>
                    </div>
                    <span className="invalid-feedback">Pole wymagane</span>
                  </div>
                </div>
                <CustomInput
                  type="checkbox"
                  id="terms"
                  name="terms"
                  label="Zgadzam się z regulaminem"
                  invalid={this.hasError('formRegister', 'terms', 'required')}
                  onChange={this.validateOnChange}
                  data-validate='["required"]'
                  checked={this.state.formRegister.terms}
                >
                  <span className="invalid-feedback">To pole jest wymagane</span>
                </CustomInput>
                <button
                  className="text-sm float-left mb-3 btn btn-link ml-2 pt-0"
                  style={{ cursor: 'pointer' }}
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.href = 'http://mistrzwina.pl/regulamin.html#tresc';
                  }}
                >
                  przeczytaj regulamin
                </button>
                <button
                  className="btn btn-block btn-primary mt-3"
                  type="submit"
                  disabled={this.state.loading}
                >
                  Stwórz konto
                </button>
              </>
            )}
          </form>
          <p className="pt-3 text-center">Masz już konto?</p>
          <NavLink to="/welcome/login" className="btn btn-block btn-secondary">
            Zaloguj
          </NavLink>
        </SectionWrapper>
      </WelcomeCard>
    );
  }
}

export default Register;
