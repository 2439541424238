import React from 'react';
import { SubscriptionType } from '../../../../types/Subscription';
import { useTranslation } from 'react-i18next';
import { PaymentInfo } from './PaymentInfo';
import { useAuthContext } from '../../../platform/AuthContext';

export const ActivatedPlanInfo = () => {
  const { t } = useTranslation();
  const {
    subscriptionInfo: { paymentInfo, activeSubscription, inProgressSubscription }
  } = useAuthContext();

  return (
    <div>
      {activeSubscription.type === SubscriptionType.PREMIUM && !inProgressSubscription ? (
        <div className={'h4 pb-1'}>
          Witaj w gronie <span className="text-gradient-blue-purple">PREMIUM :)</span>
        </div>
      ) : null}

      {inProgressSubscription ? (
        <div className={'h4 pb-1'}>
          Twój plan{' '}
          <span className="text-gradient-blue-purple">
            {t(`plans.subscription.type.${inProgressSubscription.type}`)}
          </span>{' '}
          dobiega końca !
          <div className="text-gradient-blue-orange text-sm">
            Opłać plan przed końcem ważności aktualnego planu, aby zachować ciągłość.
          </div>
        </div>
      ) : null}

      {inProgressSubscription ? (
        <div>
          <PaymentInfo
            price={paymentInfo?.priceToPay?.toString() || '0'}
            title={paymentInfo.paymentTitle}
          />
        </div>
      ) : null}
    </div>
  );
};
