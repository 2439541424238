import React, { FC } from 'react';
import {
  BottleEntryListBoxProps,
  DisposedAsBottlesListBoxContainer
} from './DisposedAsBottlesListBoxContainer';
import DisposedAsBottlesListBoxPresentation from './DisposedAsBottlesListBoxPresentation';

export const DisposedAsBottlesListBox: FC<BottleEntryListBoxProps> = (props) => {
  return (
    <DisposedAsBottlesListBoxContainer
      {...props}
      render={(bottleEntries, eventToShow, actions) => (
        <DisposedAsBottlesListBoxPresentation
          bottleEntries={bottleEntries}
          elementToShow={eventToShow}
          actions={actions}
        />
      )}
    />
  );
};
