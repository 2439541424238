import React from 'react';
import { withRouter } from 'react-router-dom';
import GrapevineFormContainer from './GrapevineFormContainer';
import { SimpleGrapevineForm } from './SimpleGrapevineForm';

const GrapevineForm = (props) => {
  return (
    <GrapevineFormContainer
      {...props}
      render={(
        updateParcelsInGrapevine,
        onSubmit,
        error,
        grapevine,
        updateGrapevine,
        handleUpdateSelect,
        loading,
        onClickBack,
        updateDate
      ) => (
        <SimpleGrapevineForm
          updateParcelsInGrapevine={updateParcelsInGrapevine}
          onSubmit={onSubmit}
          error={error}
          grapevine={grapevine}
          handleUpdateSelect={handleUpdateSelect}
          updateGrapevine={updateGrapevine}
          loading={loading}
          onClickBack={onClickBack}
          updateDate={updateDate}
        />
      )}
    />
  );
};

export default withRouter(GrapevineForm);
