import React, { FC } from 'react';
import PageWrapper from '../../../../../../layouts/PageWrapper';
import { EventToWineProps } from './withProductionEventToWineServiceHOC';
import { ProductionEventFormFields } from './ProductionEventFormFields';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { RouterParams } from '../../../../../../types/RouterParams';

export const AddEventToWinePresentation: FC<EventToWineProps & { loading: boolean }> = ({
  loading,
  event,
  actions,
  error,
  entity
}) => {
  const { t } = useTranslation();
  const { productionEventId } = useParams<RouterParams>();

  return (
    <PageWrapper title={t('wine.info.EVENTS')} disabled={!productionEventId}>
      <ProductionEventFormFields
        event={event}
        actions={actions}
        error={error}
        entity={entity}
        loading={loading}
      />
    </PageWrapper>
  );
};
