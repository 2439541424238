import { useEffect, useState } from 'react';
import { SelectOption } from '../../../../../types/SelectOption';
import { useTranslation } from 'react-i18next';
import { WineStorageEntryType } from '../types/WineStorage';

export const useWineStorageEntryEntityTypeReceiver = (
  value?: WineStorageEntryType | SelectOption | null
) => {
  const { t } = useTranslation();
  const options: SelectOption[] = Object.keys(WineStorageEntryType).map((i) => ({
    value: i,
    label: t(`wineStorage.entry.TYPE.${i}`)
  }));
  const [selected, setSelected] = useState<SelectOption | string>('');

  useEffect(() => {
    if (value) {
      setSelected(value ? { value: value, label: t(`wineStorage.entry.TYPE.${value}`) } : '');
      return;
    }
  }, [value, t]);

  return { options, selected, loading: false };
};
