import React, { ChangeEvent, FC } from 'react';
import { Button, Card, CardBody } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import PageWrapper from '../../../../../../layouts/PageWrapper';
import InputElement from '../../../../../../components/common/InputElement';
import SelectParcels from './SelectParcels';
import { ServiceError } from '../../../../../../types/Service';
import { Grapevine } from '../../types/Grapevine';
import { FormErrorMessage } from '../../../../../../components/notifications/FormErrorMessage';
import { EntityLiveStatus } from '../../../../../../components/common/enums/EntityLiveStatus';
import SelectGrapeColor from '../../../../common/select/SelectGrapeColor';
import { InputDate } from '../../../../../../components/common/form-elements/InputDate';
import { usePushHistory } from '../../../../../../hooks/usePushHistory';
import { SectionWrapper } from '../../../../../../layouts/SectionWrapper';

interface Props {
  onSubmit: (e: React.MouseEvent<HTMLButtonElement>) => void;
  updateParcelsInGrapevine: () => void;
  error: ServiceError;
  grapevine: Grapevine;
  updateGrapevine: (e: ChangeEvent<HTMLInputElement>) => void;
  handleUpdateSelect: () => void;
  loading: boolean;
  updateDate: (date: Date | string) => void;
  onClickBack: () => void;
}

export const SimpleGrapevineForm: FC<Props> = ({
  updateParcelsInGrapevine,
  onSubmit,
  error,
  grapevine,
  updateGrapevine,
  handleUpdateSelect,
  loading,
  onClickBack,
  updateDate
}) => {
  const { t } = useTranslation();
  const { pushHistory } = usePushHistory();

  function pushToBoard() {
    pushHistory(`/mv/board/summary`);
  }

  return (
    <PageWrapper title={'grapevine.TITLE'} onClick={pushToBoard} returnText={'home.summary.TITLE'}>
      <SectionWrapper loading={loading}>
        <Card>
          <CardBody>
            <div className={'pb-5'}>
              <h5 className="float-right">
                {grapevine?.id ? t('sidebar.nav.element.EDIT') : t('sidebar.nav.element.ADD_NEW')}
              </h5>
            </div>
            <InputElement
              label={t('grapevine.NAME')}
              type={'text'}
              name={'name'}
              maxSize={'100'}
              onChange={updateGrapevine}
              defaultValue={grapevine?.name}
              error={error}
              disabled={grapevine?.liveStatus === EntityLiveStatus.ARCHIVED}
            />
            <InputElement
              label={t('grapevine.NUMBER_OF_PLANTS')}
              type={'number'}
              name={'numberOfPlants'}
              maxSize={'100'}
              onChange={updateGrapevine}
              defaultValue={grapevine?.numberOfPlants}
              error={error}
              disabled={grapevine?.liveStatus === EntityLiveStatus.ARCHIVED}
            />
            <InputElement
              label={t('grapevine.AREA')}
              type={'number'}
              name={'area'}
              maxSize={'100'}
              onChange={updateGrapevine}
              defaultValue={grapevine?.area}
              error={error}
              disabled={grapevine?.liveStatus === EntityLiveStatus.ARCHIVED}
            />
            <InputDate
              label={t('grapevine.YEAR_OF_PLANTING')}
              name={'yearOfPlanting'}
              onChange={updateDate}
              defaultValue={grapevine?.yearOfPlanting}
              error={error}
              disabled={grapevine?.liveStatus === EntityLiveStatus.ARCHIVED}
              optional
            />
            <SelectGrapeColor
              value={grapevine?.grapeColor}
              name={'grapeColor'}
              label={t('grapevine.GRAPE_COLOR')}
              onChange={handleUpdateSelect}
              error={error}
              disabled={grapevine?.liveStatus === EntityLiveStatus.ARCHIVED}
            />
            <SelectParcels
              value={grapevine?.parcels || []}
              name={'parcels'}
              label={t('grapevine.PARCELS')}
              onChange={updateParcelsInGrapevine}
              error={error}
              disabled={grapevine?.liveStatus === EntityLiveStatus.ARCHIVED}
            />
            <FormErrorMessage error={error} messageType={'details'} />
            <div className="text-center bg-secondary">
              {grapevine?.liveStatus === EntityLiveStatus.ARCHIVED ? (
                <Button color="info" className="btn-square" onClick={onClickBack}>
                  {t('common.BACK')}
                </Button>
              ) : (
                <Button color="info" className="btn-square" onClick={onSubmit}>
                  {grapevine?.id ? t('common.SAVE') : t('common.ADD')}
                </Button>
              )}
            </div>
          </CardBody>
        </Card>
      </SectionWrapper>
    </PageWrapper>
  );
};
