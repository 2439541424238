import React, { FC } from 'react';
import {
  WineIngredientsListBoxContainer,
  WineIngredientsListBoxProps
} from './WineIngredientsListBoxContainer';
import IngredientsListBox from '../shared/IngredientsListBox';

const WineIngredientsListBox: FC<WineIngredientsListBoxProps> = (props) => {
  return (
    <WineIngredientsListBoxContainer
      {...props}
      render={(ingredients, ingredientToShow, actions) => (
        <IngredientsListBox
          ingredients={ingredients}
          ingredientToShow={ingredientToShow}
          actions={actions}
          entityName={'wine'}
          loading={props.loading}
          disabledActions={props?.disabledActions}
          entityId={props.entityId}
        />
      )}
    />
  );
};

export default WineIngredientsListBox;
