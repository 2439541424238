export enum IngredientType {
  YEAST = 'YEAST',
  NUTRIENT = 'NUTRIENT',
  ENZYME = 'ENZYME',
  TANNIN = 'TANNIN',
  BACTERIA = 'BACTERIA',
  DEACIDIFICATION = 'DEACIDIFICATION',
  CLARIFICATION = 'CLARIFICATION',
  SUGARING = 'SUGARING',
  ALCOHOL_INCREASING = 'ALCOHOL_INCREASING',
  OTHER = 'OTHER'
}
