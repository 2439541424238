import { useEffect, useState } from 'react';
import { SelectOption } from '../../../../../types/SelectOption';
import { useTranslation } from 'react-i18next';
import { Tank } from '../../tank/types/Tank';

const useStorageTanksReceiver = (tanks: Tank[], value?) => {
  const { t } = useTranslation();
  const [options, setOptions] = useState<SelectOption[]>([]);
  const [selected, setSelected] = useState<SelectOption | string>('');

  useEffect(() => {
    if (value) {
      setSelected(
        value?.id
          ? {
              value: value.id,
              label: value.label ? value.label : value.number + ' - ' + value.capacity + ' l.'
            }
          : ''
      );
    }
    setOptions(
      tanks?.map((tank) => ({
        value: tank.id,
        label:
          t(`tank.TYPE.${tank.type}`) + ` (${tank.number}) - ${tank.liters}/${tank.capacity} l.`
      }))
    );
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  return { options, selected };
};

export default useStorageTanksReceiver;
