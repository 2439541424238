import { SubscriptionPeriod } from '../../../../../types/Subscription';
import React from 'react';
import { SubscriptionPlan } from '../../../data/Plans';

export const PickerPaymentResultForBasic: React.FC<{
  plan: SubscriptionPlan;
  trialAllowed: boolean;
  period: SubscriptionPeriod;
}> = ({ plan, trialAllowed, period }) => {
  return trialAllowed ? (
    <>
      <div className="mt-2 float-left">Do zapłaty przy ewentualnym pozostaniu:</div>
      <div className="mt-2 float-right">
        {period === SubscriptionPeriod.ANNUAL ? plan.annualPrice : plan.monthlyPrice} zł
      </div>
      <div className="float-left text-bold">Do zapłaty dziś:</div>
      <div className="float-right mb-4">0 zł</div>
    </>
  ) : (
    <>
      <div className="float-left text-bold mt-2">Do zapłaty:</div>
      <div className="float-right mb-4 mt-2">
        {' '}
        {period === SubscriptionPeriod.ANNUAL ? plan.annualPrice : plan.monthlyPrice} zł
      </div>
    </>
  );
};
