import React, { FC } from 'react';
import { AddEventToWinePresentation } from '../common/AddEventToWinePresentation';
import {
  EventToWineServiceProps,
  withProductionEventToWineServiceHOC
} from '../common/withProductionEventToWineServiceHOC';
import { Wine } from '../../../wine/types/Wine';

export const ProductionEventToWineForm: FC<EventToWineServiceProps<Wine>> = (props) => {
  const ProductionEventFormWithEventToWineService = withProductionEventToWineServiceHOC(
    AddEventToWinePresentation
  );

  return <ProductionEventFormWithEventToWineService {...props} />;
};
