import React, { useEffect, useRef, useState } from 'react';
import PageWrapper from '../../../../layouts/PageWrapper';
import useBoardService from '../../hooks/useBoardService';
import { WinesInTanksCard } from './wines/WinesInTanksCard';
import { WinerySummaryCard } from './WinerySummaryCard';
import { WinesListCard } from './wines/WinesListCard';
import { WinerySummaryData } from '../../../../types/dto';
import { usePushHistory } from '../../../../hooks/usePushHistory';

export const WineryPage = () => {
  const serviceRef = useRef(useBoardService());
  const [summary, setSummary] = useState<WinerySummaryData>(null);
  const [loading, setLoading] = useState(true);
  const { pushHistory } = usePushHistory();

  useEffect(() => {
    if (serviceRef.current != null && loading) {
      serviceRef.current
        .getProduction()
        .then((response) => {
          setLoading(false);
          setSummary(response);
        })
        .catch(() => setLoading(false));
    }
  }, [loading, setSummary]);

  return (
    <PageWrapper
      title={'home.TITLE'}
      onClick={() => pushHistory('/mv')}
      returnText={'sidebar.nav.element.DASHBOARD'}
    >
      <div className={'row'}>
        <div className="col-lg-12">
          <WinesInTanksCard wines={summary?.winesInTanks || []} loading={loading} />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-4">
          <WinerySummaryCard summary={summary} loading={loading} />
        </div>
        <div className="col-lg-8">
          <WinesListCard wines={summary?.winesInSeason || []} loading={loading} />
        </div>
      </div>
    </PageWrapper>
  );
};
