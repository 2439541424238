import React from 'react';
import { Route, Switch } from 'react-router-dom';
import WineProvider from '../winery/components/wine/context/WineContext';
import { AccountPage } from './components/AccountPage';
import NotFound from '../../pages/NotFound';

export const AccountApp = () => {
  return (
    <WineProvider>
      <Switch>
        <Route exact path="/mv/account" component={AccountPage} />
        <Route path="*" component={NotFound} />
      </Switch>
    </WineProvider>
  );
};
