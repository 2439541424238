import React from 'react';
import { PaymentInfo } from './PaymentInfo';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../platform/AuthContext';

export const TrialPlanInfo = () => {
  const { t } = useTranslation();
  const {
    subscriptionInfo: { paymentInfo, inProgressSubscription }
  } = useAuthContext();

  return (
    <div className="px-2">
      <div className={'h4 pb-1'}>
        Wybrałeś nowy plan{' '}
        <span className="text-gradient-blue-purple">
          {t(`plans.subscription.type.${inProgressSubscription?.type}`)}
        </span>{' '}
        w wersji próbnej!
      </div>
      <div>
        <p>
          Jeśli zdecydujesz się z nami pozostać, opłać plan przed końcem trwania okresu próbnego.
        </p>
        <p>
          Pamiętaj, że opłacając plan w trakcie trwania okresu próbnego, nie spowoduje utraty dni,
          które zostały do końca trwania tego okresu. Okres nowej subskrypcji zostanie dodany na
          koniec okresu próbnego.
        </p>
        <p>W celu opłacenia planu postępuj zgodnie z informacjami zawartymi w wiadomości e-mail.</p>
        <p>
          Możesz także skorzystać z opcji przelewu bankowego, korzystając z danych podanych poniżej.
        </p>
      </div>

      <div className={'pb-3'}>
        <PaymentInfo
          price={paymentInfo?.priceToPay?.toString() || '0'}
          title={paymentInfo.paymentTitle}
        />
      </div>
    </div>
  );
};
